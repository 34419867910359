import styled from 'styled-components'
import {device} from "../../../contexts/ThemeContext/themes";
import {AiOutlineSearch} from "react-icons/ai";


export const PoolSeachButtonStyled = styled.section`
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
    padding: 0.5rem 1rem;
    border: 1px solid #415269;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 25rem;

  &:placeholder {
    border: 1px solid #415269;
  }
  
  @media ${device.mobileS} {
      display: none;
  }
  
  @media ${device.laptop} {
      display: flex;
  }
`

export const AiOutLineSearchStyled = styled(AiOutlineSearch)`
  background-color: #D9D9D9; 
  color: #999999; 
  border-radius: 50%; 
  padding: 0 0.2rem; 
  height: 1em; 
  width: 1.2em;
`