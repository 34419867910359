import React, { useContext, useState } from 'react';
import useCollapse from 'react-collapsed';
import {
  TBodyExpanded,
  TColumn1,
  TRow,
  TWrapRow,
  WrappedRow,
  CircleButton,
  SymbolContainer,
  TColumn1WithColor,
  TBodyExpandedDiv,
  TBodyExpandedDivBorder,
  SwapIconImageStyled,
  SwapIconImageStyledRelative,
  LogoTokenCustom,
  LogoTokenCustomRelative
} from "./styles";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { IconOutlineSwap, LiquidityButton, LiquidityFarmIcon, NewIcons } from "../../atoms";
import { useNavigate } from "react-router-dom";
import { ConfigProviderContext } from "../../../contexts/ConfigContext";
import { numberWithCommas } from '../../../commons';

export const LiquidityItem = ({ firstIcon, firstSymbol, firstLiquidity, secondIcon, secondSymbol, secondLiquidity, liquidity, perLiquidity, fullExpanded = false, children }: any) => {
  const [isExpanded, setExpanded] = useState(fullExpanded);
  const navigate = useNavigate()
  const {
    onSelectFirstToken,
    onSelectSecondToken,
    tokens
  } = useContext(ConfigProviderContext)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const handleOnClick = () => {
    setExpanded(!isExpanded);
  }

  const selectLiquidity = () => {
    onSelectFirstToken(tokens[firstSymbol.replace('WCSPR', 'CSPR')])
    onSelectSecondToken(tokens[secondSymbol.replace('WCSPR', 'CSPR')])
  }

  const goTo = (path) => {
    navigate({ pathname: path, search: `token0=${firstSymbol}&token1=${secondSymbol}` })
  }

  return (
    <TWrapRow className="collapsible">
      <TRow {...getToggleProps({ onClick: handleOnClick })}>
        <TColumn1>
          <TColumn1>
            {firstIcon ? (
              <SwapIconImageStyled src={firstIcon} width="30" height="30" />
            ) : (
              <LogoTokenCustom>
                <span>{firstSymbol}</span>
              </LogoTokenCustom>
            )}
            {secondIcon ? (
              <SwapIconImageStyledRelative
                src={secondIcon}
                width="30"
                height="30"
              />
            ) : (
              <LogoTokenCustomRelative>
                <span>{secondSymbol}</span>
              </LogoTokenCustomRelative>
            )}
          </TColumn1>
          <SymbolContainer>
            {firstSymbol}-{secondSymbol}
          </SymbolContainer>
        </TColumn1>
        <TColumn1WithColor>
          {isExpanded ? <TiArrowSortedUp color='#fff'/> : <TiArrowSortedDown color='#fff'/>}
        </TColumn1WithColor>
      </TRow>
      <TBodyExpanded {...getCollapseProps()}>
        <TBodyExpandedDiv>
          <TBodyExpandedDivBorder />
        </TBodyExpandedDiv>
        <WrappedRow>
          <TColumn1>Pooled ({firstSymbol})</TColumn1>
          <TColumn1WithColor>
            {
            Number(firstLiquidity) > 1 ?
              numberWithCommas(Number(firstLiquidity).toFixed(2))
              : Number(firstLiquidity).toFixed(6)
            }{" "}
            {firstSymbol}
          </TColumn1WithColor>
        </WrappedRow>
        <WrappedRow>
          <TColumn1>Pooled ({secondSymbol})</TColumn1>
          <TColumn1WithColor>
            {
              Number(secondLiquidity) > 1 ?
                numberWithCommas(Number(secondLiquidity).toFixed(2))
                : Number(secondLiquidity).toFixed(6)
            }{" "}
            {secondSymbol}
          </TColumn1WithColor>
        </WrappedRow>
        <WrappedRow>
          <TColumn1>Your liquidity</TColumn1>
          <TColumn1WithColor>
            {Number(liquidity) > 1 ?
              numberWithCommas(Number(liquidity).toFixed(2))
              : Number(liquidity).toFixed(6)
            } LP
          </TColumn1WithColor>
        </WrappedRow>
        {/* <WrappedRow>
          <TColumn1>
            Your share
          </TColumn1>
          <TColumn1WithColor>
            {perLiquidity} %
          </TColumn1WithColor>
        </WrappedRow> */}
        <WrappedRow>
          <TColumn1>
            <TRow>
              <LiquidityButton
                content={"Add Liquidity"}
                handler={selectLiquidity}
              />
            </TRow>
          </TColumn1>
          <TColumn1>
            <TRow>
              <CircleButton
                onClick={() => {
                  goTo("/swap");
                }}
              >
                <IconOutlineSwap size="1.3rem" />
              </CircleButton>
              {children}
              {false && (
                <CircleButton onClick={() => {}}>
                  <LiquidityFarmIcon />
                </CircleButton>
              )}
            </TRow>
          </TColumn1>
        </WrappedRow>
      </TBodyExpanded>
    </TWrapRow>
  );
}
