import React from "react";
import styled from 'styled-components/macro'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../../../assets/image/FooterLogo.png'
import Discord from '../../../assets/image/Discord.png'
import Twitter from '../../../assets/image/Twitter.png'
import Telegram from '../../../assets/image/Telegram.png'
import M from '../../../assets/image/M.png'

export const FooterMain = styled.footer`
  width: 100%;
  background-color: #9A0D2F;
  display: flex;
  justify-content: center;
`

export const FooterWrapper = styled.div`
  width: 100%;
  margin: 1.5rem 0.5rem;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (min-width: 992px) {
    width: 80%;
  }
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
`

export const Copyright = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #FFFFFF;
`

export const FooterLogo = styled.img`
  margin-top: 3rem;

  media (min-width: 1200px) {
    margin-top: 8rem;
  }
`

export const FooterTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-top: 3rem;

  @media (min-width: 1200px) {
    margin-top: 0rem;
  }
`

export const FooterList = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #F5F5F5;
  margin-top: 1rem;
`

export const FooterCD = () => {
    return (
        <FooterMain>
          <FooterWrapper>
            <Copyright>© 2023 #PUNKDEX</Copyright>
          </FooterWrapper>
        </FooterMain>
    )
}