//import "module-alias/register";
//import dotenv from "dotenv";
//dotenv.config();

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "@fontsource/epilogue";
import { Home, Swap, Pools, Tokens } from "./components/pages";
import { NewLiquidity } from "./components/pages/Liquidity";
import { BigContext } from "./contexts";
import { MainSpaceCC, NavBarCD } from "./components/atoms";
import { ReactComponent as WordMarkIcon } from "./assets/newIcons/casperswap-wordmark.svg";
import NewLayout from "./layout/NewLayout";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
