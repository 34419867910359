import { toast } from "react-toastify";
export const ToastDimiss = (id?: any) => {
  toast.dismiss(id ? id : null);
};
export const Toast = (text) => {
  const id = toast.success(text, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  return id;
};
export const ToastError = (text) => {
  const id = toast.error(text, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  return id;
};
export const ToastLoading = (text) => {
  const id = toast.loading(text, { autoClose: 15000 });
  return id;
};
export const ToastPromise = async (
  promise: Promise<any>,
  object: { pending?; error?; success? }
) => {
  await toast.promise(promise, object, {position: "bottom-right"});
};
