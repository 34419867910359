import BigNumber from "bignumber.js";
import {
  convertBigNumberToUIString,
  convertUIStringToBigNumber,
} from "../../commons";
import { TOKENS } from "../TokenReducers";

export type PairData = {
  checked: boolean;
  name: string;
  orderedName?: string;
  contractHash: string;
  packageHash: string;
  balance: string;
  reserve0: string;
  reserve1: string;
  totalReserve0: string;
  totalReserve1: string;
  allowance: string;
  token0Symbol: string;
  token1Symbol: string;
  token0Icon?: string;
  token1Icon?: string;
  liquidity?: string;
  volume7d?: string;
  volume1d?: string;
  totalSupply?: string;
  token0Price?: string;
  token1Price?: string;
  liquidityUSD?: string;
  totalLiquidityUSD?: string;
  contract0?: string;
  contract1?: string;
  token0Name?: string;
  token1Name?: string;
  decimals: number;
  token0Decimals: number;
  token1Decimals: number;
  contractHash0: string;
  contractHash1: string;
};

export type PairState = Record<string, PairData>;

const RAW_PAIRS: PairState = {};

export const PAIRS: PairState = {};

Object.values(RAW_PAIRS).map((p) => {
  try {
    const pair = Object.assign({}, p);

    pair.token0Name = TOKENS[p.token0Symbol].name;
    pair.token1Name = TOKENS[p.token1Symbol].name;
    pair.token0Icon = TOKENS[p.token0Symbol].logoURI;
    pair.token1Icon = TOKENS[p.token1Symbol].logoURI;
    pair.contract0 = TOKENS[p.token0Symbol].packageHash;
    pair.contract1 = TOKENS[p.token1Symbol].packageHash;

    // sort pair by alphanumeric order
    if (pair.contract0.localeCompare(pair.contract1) > 0) {
      const nameTemp = pair.token0Name;
      pair.token0Name = pair.token1Name;
      pair.token1Name = nameTemp;

      const iconTemp = pair.token0Icon;
      pair.token0Icon = pair.token1Icon;
      pair.token1Icon = iconTemp;

      const contractTemp = pair.contract0;
      pair.contract0 = pair.contract1;
      pair.contract1 = contractTemp;

      const symbolTemp = pair.token0Symbol;
      pair.token0Symbol = pair.token1Symbol;
      pair.token1Symbol = symbolTemp;
    }

    pair.orderedName = `${pair.token0Symbol}-${pair.token1Symbol}`;

    PAIRS[p.name] = pair;
  } catch {}
});

export const initialPairsState: PairState = PAIRS;

export enum PairActions {
  ADD_BALANCE_TO_PAIR = "ADD_BALANCE_TO_PAIR",
  ADD_ALLOWANCE_TO_PAIR = "ADD_ALLOWANCE_TO_PAIR",
  LOAD_PAIR = "LOAD_PAIR",
  LOAD_PAIR_USD = "LOAD_PAIR_USD",
  CHANGE_PRIORITY = "CHANGE_PRIORITY",
  ADD_PAIRS = "ADD_PAIRS",
  //LOAD_USER_PAIR = 'LOAD_USER_PAIR',
}

export type PairActionBalancePayload = {
  name: string;
  balance: string;
};

export type PairActionAllowancePayload = {
  name: string;
  allowance: string;
};

export type PairActionLoadPairPayLoad = {
  name: string;
  volume7d: string;
  volume1d: string;
  totalReserve0: string;
  totalReserve1: string;
  totalSupply: string;
  totalLiquidityUSD: string;
};

export type PairActionLoadPairUSDPayLoad = {
  name: string;
  token0Price: string;
  token1Price: string;
};

export type PairActionChangePriorityPayLoad = {
  name: string;
  checked: boolean;
};

export type PairActionLoadUserPairPayLoad = {
  name: string;
  reserve0: string;
  reserve1: string;
  liquidityUSD: string;
};

export type PairActionAddPairsPayLoad = {
  rawPairs: [PairData];
};

export type PairAction =
  | {
      type: PairActions.ADD_BALANCE_TO_PAIR;
      payload: PairActionBalancePayload;
    }
  | {
      type: PairActions.ADD_ALLOWANCE_TO_PAIR;
      payload: PairActionAllowancePayload;
    }
  | {
      type: PairActions.LOAD_PAIR;
      payload: PairActionLoadPairPayLoad;
    }
  | {
      type: PairActions.LOAD_PAIR_USD;
      payload: PairActionLoadPairUSDPayLoad;
    }
  | {
      type: PairActions.ADD_PAIRS;
      payload: PairActionAddPairsPayLoad;
    }
  | {
      type: PairActions.CHANGE_PRIORITY;
      payload: PairActionChangePriorityPayLoad;
    }; /* | {
  type: PairActions.LOAD_USER_PAIR,
  payload: PairActionLoadUserPairPayLoad,
}*/

export function PairsReducer(state: PairState, action: PairAction): PairState {
  switch (action.type) {
    case PairActions.ADD_PAIRS: {
      const rawPairs = action.payload.rawPairs;
      const newPairs: PairState = {};
      // console.log('rawPairs', rawPairs)
      rawPairs &&
        rawPairs.map((p) => {
          try {
            const pair = Object.assign({}, p);

            pair.token0Name = p.token0Name;
            pair.token0Decimals = p.token0Decimals;
            pair.token1Name = p.token1Name;
            pair.token1Decimals = p.token1Decimals;
            pair.contractHash0 = p.contractHash0;
            pair.contractHash1 = p.contractHash1;
            pair.token0Icon = TOKENS[p.token0Symbol]?.logoURI;
            pair.token1Icon = TOKENS[p.token1Symbol]?.logoURI;
            pair.contract0 = p.contract0;
            pair.contract1 = p.contract1;
            pair.totalReserve0 = convertBigNumberToUIString(
              new BigNumber(p.totalReserve0),
              p.token0Decimals
            );
            pair.totalReserve1 = convertBigNumberToUIString(
              new BigNumber(p.totalReserve1),
              p.token1Decimals
            );
            pair.reserve0 = p.reserve0 ?? "0";
            pair.reserve1 = p.reserve1 ?? "0";
            pair.totalSupply = convertBigNumberToUIString(
              new BigNumber(p.totalSupply),
              18
            );

            pair.orderedName = `${pair.contract0}-${pair.contract1}`;
            newPairs[p.name] = pair;
          } catch {}
        });

      return {
        ...state,
        ...newPairs,
      };
    }
    case PairActions.ADD_BALANCE_TO_PAIR: {
      const oldState = state[`${action.payload.name}`];
      if (!oldState) {
        throw new Error(`pair ${action.payload.name} does not exist`);
      }
      // console.log("PairsReducer", action.payload, oldState);
      const balance = convertUIStringToBigNumber(action.payload.balance, 18);
      const totalSupply = convertUIStringToBigNumber(oldState.totalSupply, 18);
      const totalReserve0 = convertUIStringToBigNumber(
        oldState.totalReserve0,
        oldState.token0Decimals
      );
      const totalReserve1 = convertUIStringToBigNumber(
        oldState.totalReserve1,
        oldState.token1Decimals
      );
      const reserve0 = convertBigNumberToUIString(
        totalReserve0.times(balance.div(totalSupply)),
        oldState.token0Decimals
      );
      const reserve1 = convertBigNumberToUIString(
        totalReserve1.times(balance.div(totalSupply)),
        oldState.token1Decimals
      );

      return {
        ...state,
        [`${action.payload.name}`]: {
          ...oldState,
          balance: convertBigNumberToUIString(balance, 18),
          reserve0,
          reserve1,
        },
      };
    }
    case PairActions.ADD_ALLOWANCE_TO_PAIR:
      return {
        ...state,
        [`${action.payload.name}`]: {
          ...state[`${action.payload.name}`],
          allowance: action.payload.allowance,
        },
      };
    case PairActions.LOAD_PAIR: {
      const oldState = state[`${action.payload.name}`];

      const balance = convertUIStringToBigNumber(oldState.balance, 18);
      const totalSupply = convertUIStringToBigNumber(
        action.payload.totalSupply,
        18
      );
      const totalReserve0 = convertUIStringToBigNumber(
        action.payload.totalReserve0,
        oldState.token0Decimals
      );
      const totalReserve1 = convertUIStringToBigNumber(
        action.payload.totalReserve1,
        oldState.token1Decimals
      );
      const reserve0 = convertBigNumberToUIString(
        totalReserve0.times(balance.div(totalSupply)),
        oldState.token0Decimals
      );

      const reserve1 = convertBigNumberToUIString(
        totalReserve1.times(balance.div(totalSupply)),
        oldState.token1Decimals
      );
      // console.log(
      //   "reserve0, reserv1",
      //   oldState,
      //   action.payload,
      //   reserve0,
      //   reserve1,
      //   oldState.packageHash
      // );

      return {
        ...state,
        [`${action.payload.name}`]: {
          ...oldState,
          volume7d: action.payload.volume7d,
          volume1d: action.payload.volume1d,
          reserve0,
          reserve1,
          totalLiquidityUSD: action.payload.totalLiquidityUSD,
          totalReserve0: action.payload.totalReserve0,
          totalReserve1: action.payload.totalReserve1,
          totalSupply: action.payload.totalSupply,
        },
      };
    }
    case PairActions.LOAD_PAIR_USD: {
      const oldState = state[`${action.payload.name}`];

      const liquidityUSD = new BigNumber(
        convertUIStringToBigNumber(oldState.reserve0, oldState.token0Decimals)
      )
        .times(action.payload.token0Price)
        .plus(
          new BigNumber(
            convertUIStringToBigNumber(
              oldState.reserve1,
              oldState.token1Decimals
            )
          ).times(action.payload.token1Price)
        )
        .div(10 ** 9)
        .toString();
      // console.log('action.payload', action.payload, oldState.totalReserve0, oldState.totalReserve1)

      return {
        ...state,
        [`${action.payload.name}`]: {
          ...oldState,
          liquidityUSD,
          token0Price: action.payload.token0Price,
          token1Price: action.payload.token1Price,
        },
      };
    }
    case PairActions.CHANGE_PRIORITY: {
      const oldState = state[`${action.payload.name}`];

      return {
        ...state,
        [`${action.payload.name}`]: {
          ...oldState,
          checked: action.payload.checked,
        },
      };
    }
    /* case PairActions.LOAD_USER_PAIR:
      return {
        ...state,
        [`${action.payload.name}`]: {
          ...state[`${action.payload.name}`],
          reserve0: action.payload.reserve0,
          reserve1: action.payload.reserve1,
          liquidityUSD: action.payload.liquidityUSD,
        },
      } */
    /*case "GET_PAIRS":
      return { ...state };*/
  }
}
