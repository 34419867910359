import styled from 'styled-components'


export const PoolSeachButtonStyled = styled.section`
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    padding: 0 1rem;
    border: 1px solid #4592AE;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap:.5rem;

`