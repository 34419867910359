import React from "react";
import styled from "styled-components";
export const BalanceInput = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #fff;
  &:active {
    border: none;
  }
`;
export const BalanceInputNSM = ({ min, onChange, value, name, id, type }) => {
  return (
    <BalanceInput
      min={min}
      onChange={onChange}
      type={type}
      name={name}
      id={id}
      value={value}
      // inputmode={"decimal"}
      // pattern={pattern}
    />
  );
};
