import BigNumber from "bignumber.js";
import { Logger } from "./log";
import { Token } from "../api";

export const log = new Logger();

export const ONE_BILLION_E = 9;

export const convertBigNumberToUIString = (
  amount: BigNumber,
  decimals = 9
): string => {
  // console.log(
  //   "amount.div(new BigNumber(10).pow(decimals))",
  //   amount.div(new BigNumber(10).pow(decimals)).toString()
  // );
  return amount.div(new BigNumber(10).pow(decimals)).toFixed(decimals);
};
export const convertUIStringToBigNumber = (
  amount: BigNumber.Value,
  decimals = 9
): BigNumber => new BigNumber(amount).times(new BigNumber(10).pow(decimals));
export const convertAllFormatsToUIFixedString = (
  amount: BigNumber.Value,
  fixed = 6
): string => new BigNumber(amount).toFixed(fixed);

export function numberWithCommas(x) {
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

export const convertBigNumberToString = (
  amount: BigNumber,
  fixed = 9
): string =>
  amount
    .div(10 ** ONE_BILLION_E)
    .toFixed(fixed)
    .toString();

export function formatString1(string, number) {
  if (string) {
    const newString = string.slice(0, number);
    if (
      newString[newString.length - 1] == "." ||
      newString[newString.length - 1] == ","
    ) {
      const a = newString.slice(0, -1);
      return a;
    }
    return newString;
  }
  return string;
}

const FRACTIONAL_DIGITS = 5;

export const formatString = (amount, float = 9) => {
  if (parseFloat(amount) === 0) {
    return amount;
  }
  if (parseFloat(amount) < 0.00001) {
    return `< ${
      !FRACTIONAL_DIGITS
        ? "0"
        : `0.${"0".repeat((FRACTIONAL_DIGITS || 1) - 1)}1`
    }`;
  }

  return formatString1(amount, float);
};
/**
 *
 * @param ms
 * @returns
 */
export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatNaN = (value: string | number) => {
  const parsedValue = typeof value === "string" ? parseFloat(value) : value;
  return isNaN(parsedValue) ? 0 : parsedValue;
 // return value;
};

export function toObject(arr: Token[]) {
  const rv = {};
  for (let i = 0; i < arr.length; ++i)
    rv[i] = arr[i];
  arr.forEach((t) => {
    rv[t.symbol] = t
  })
  return rv;
}

export const addTransactions = (_content: string, _transactionHash: string, _isSuccess: boolean, _account: string) => {
  const getTransactionFromStorage = JSON.parse(localStorage.getItem(`${_account}-transactions`))

  if (getTransactionFromStorage) {
    let transactionsSlice = [...getTransactionFromStorage]
    console.log("before check", getTransactionFromStorage)
    if (getTransactionFromStorage.length > 4) {
      console.log('length over 5')
      transactionsSlice = transactionsSlice.slice(0, 4)
    }
    console.log('after check', transactionsSlice)
    const transactions = [...transactionsSlice];
    transactions.unshift({content: _content, transactionHash: _transactionHash, isSuccess: _isSuccess})
      localStorage.setItem(
        `${_account}-transactions`,
        JSON.stringify(transactions)
      );
  } else {
    const transactions = []
    transactions.push({content: _content, transactionHash: _transactionHash, isSuccess: _isSuccess})
    localStorage.setItem(
      `${_account}-transactions`,
      JSON.stringify(transactions)
    );
  }

}

export { createRecipientAddress } from "./keys";
