import styled from 'styled-components'
import {device} from "../../../contexts/ThemeContext/themes";

export const TWrapCardRow = styled.div`
    background-color: #0F1723;
    padding: 1rem 1.5rem;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
`

export const TThirdRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem 0rem;
    background-color: transparent;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    border: 0;
    text-align: left;
`

export const TWrapRow = styled.div`
    padding: 1rem 2rem;
    background-color: #0F1723;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    cursor: pointer;
    
    @media ${device.laptop} {
      display: block;
    }
`

export const WrappedRow = styled.div`
    display: flex;
    width: 100%;
    padding: .5rem 0 0;
    
    @media ${device.mobileS} {
      flex-direction: column;
    }
    
    @media ${device.laptop} {
      flex-direction: row;
    }
`

export const TFirstRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export const TLeftRow = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 8px;
`

export const TitleStyled = styled.div`
    color: #fff;
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 18px;
`

export const ValueStyled = styled.div`
    color: #fff;
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 18px;
`

export const TColumn6 = styled.div`
    flex: 6;
`

export const TColumn3 = styled.div`
    flex: 3;
    color: #fff;
    font-size: 1rem;
`

export const TColumn1 = styled.div`
    flex: 1;
`

export const TClickableColumn1 = styled.div`
    cursor: pointer;
    flex: 1;
    color: #fff;
`

export const TColumn2andHalf = styled.div`
    @media ${device.tablet} {
      flex: 0;
    }
    
    @media ${device.laptopL} {
      flex: 2.5;
    }
`

export const PairTitleColumn = styled.div`
  flex: 6; 
  align-self: center;
  text-align: center;
  color: #fff;
  font-size: 1rem;
`

export const IconColumn1 = styled.div`
  align-self: center; 
  display: flex;
  flex: 1;
`

export const TitleBodyRow = styled.div`
  
  font-family: "Montserrat";
  color: #415269;
  
  @media ${device.mobileS} {
    text-align: left;
  }
  
  @media ${device.laptop} {
    font-size: 12px; 
    line-height: 14px; 
    
    text-align: center;
    flex: 3;
  }
  
  @media ${device.laptopL} {
    font-size: 16px; 
    line-height: 32px; 
  }
`

export const NormalBodyRow = styled.div`
  flex: 3;
  font-family: "Montserrat";
  color: #415269;
  
  @media ${device.mobileS} {
    text-align: left;
    font-size: 16px; 
    line-height: 32px; 
  }
  
  @media ${device.laptop} {
    font-size: 12px; 
    line-height: 14px;
    text-align: center;  
  }
  
  @media ${device.laptopL} {
    text-align: center; 
    font-size: 16px; 
    line-height: 32px; 
  }
`

export const TSecondRow = styled.div`
    display: flex;
    justify-items: left;
`

export const AddLiquidityButton = styled.button<any>`
    color: ${props => props.enabled ? '#3483FF' : '#415269'};
    background: #3483FF1A;
    border: none;
    align-items: center;
    
    letter-spacing: 0.02em;
    font-family: 'Montserrat';
    display: flex; 
    border-radius: 8px; 
    padding: 0.25rem 1.375rem; 
    height: 2.5rem; 
    justify-content: center; 
    
    &:hover{
        cursor: pointer;
    }
    
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 18px;
    }
    
    @media ${device.laptop} {
        font-size: 12px;
        line-height: 14px;
        padding: .25rem .375rem; 
    }
    
    @media ${device.laptopL} {
        font-size: 16px;
        line-height: 32px;
        padding: 1rem 1.54rem; 
    }
`

export const RemoveLiquidityButton = styled.button<any>`
    color: ${props => props.enabled ? '#9A0D2F' : '#415269'};
    background: #9A0D2F33;
    border: none;
    align-items: center;
    
    letter-spacing: 0.02em;
    font-family: 'Montserrat';
    display: flex; 
    border-radius: 8px; 
    padding: 0.25rem 1.375rem; 
    height: 2.5rem; 
    justify-content: center; 
    
    &:hover{
        cursor: pointer;
    }
    
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 18px;
    }
    
    @media ${device.laptop} {
        font-size: 12px;
        line-height: 14px;
        padding: .25rem .375rem; 
    }
    
    @media ${device.laptopL} {
        font-size: 16px;
        line-height: 32px;
        padding: 1rem 1.54rem; 
    }
`

export const TBodyColumn6 = styled.div`
    flex: 3;
    padding: 0.3rem 0 0;
`

export const TButtonColumn3 = styled.div`
  flex: 3;
  padding: 0.25rem 0 0;
`

export const TBodyColumn3 = styled.div`
  flex: 3;
  padding: 0.3rem 0 0;
`

export const TBodyExpanded = styled.div`
    display: flex;
`
export const LogoTokenCustom = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;

  span {
    font-size: 12px;
    color: #fff;
  }

`
export const LogoTokenCustomRelative = styled.div`
  position: relative;
  left: -16px;
  width: 45px;
  height: 45px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;

  span {
    font-size: 12px;
    color: #fff;
  }

`