import React from "react";
import styled from "styled-components";
const NewTokenDetailItems4Styled = styled.section`
  justify-self: center;
  align-items: center;
  display: flex;
`;
const Down = () => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.97168 1L6.20532 6L11.439 1" stroke="#AEAEAE"></path>
  </svg>
);
export const NewTokenDetailItems4NSM = ({ children }) => {
  return (
    <NewTokenDetailItems4Styled>
      <Down />
    </NewTokenDetailItems4Styled>
  );
};
