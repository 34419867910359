import styled from 'styled-components';

export const CollapsingContainerStyled = styled.div`
  background-color: #0F1723;
  width: 100%;
  padding: 0 5% 5% 5%;
  border: 1px solid #2A3340;
  grid-template-rows: auto auto;
  margin-bottom: 1rem;
`;
export const CollapsingHeader = styled.section`
    padding: 0;
    color: ${(props) => props.theme.secondBackgroundColor};
    cursor: pointer;
`;
export const CollapsingBody = styled.div`
  padding: 6px;
`;
export const CollapsingRow = styled.div`
  display: flex;
  padding-top: 12px;
  font-size: 14px;
`;
export const CollapsingColumnLeft = styled.div`
  flex: 1;
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #fff;
`;
export const CollapsingColumnRight = styled.div`
  flex: 1;
  text-align: right;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #fff;
`;
