import React from "react";
import SwitchSwapIcon from "../../../assets/newIcons/swap.svg";
import styled from "styled-components";
const SwitchSwap = ({ onClick }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{width: '2rem', height: '2rem', backgroundColor: '#1B232E', borderRadius: '99px'}}
    >
      <IconStyled
        src={SwitchSwapIcon}
        onClick={() => {
          onClick();
        }}
      />
    </div>
  );
};

const IconStyled = styled.img`
  cursor: pointer;
  width: 1.15rem;
  height: 1.15rem;
`;

export default SwitchSwap;
