import React from "react";
import { ButtonConnectionOver } from "../../organisms";
import { ConnectButtonContainerCC } from "../ConnectButtonContainerCC";
import { NavBarCC } from "../NavBarCC";
import { WordMarkContainerStyledCC } from "../WordMarkContainerStyledCC";
import { ReactComponent as NotificationR } from "../../../assets/newIcons/notificationActive.svg";
import { ReactComponent as Notification } from "../../../assets/newIcons/notificationDesactive.svg";
import { NewIcons } from "../NewIcons";
import { GroupIconButtons } from "../../molecules/GroupIconsButtons";
import { NotificationList } from "../../molecules/NotificationList";
import { INotification } from "../../molecules/NotificationList";
import { IconButton } from "../IconButton";
import { HiOutlineSearch } from 'react-icons/hi'
import styled from "styled-components";
import { device } from "../../../contexts/ThemeContext/themes";
import { Link, NavLink } from "react-router-dom";
import Logo from '../../../assets/dex-logo.svg'
export interface NavBarCDProps {
  isConnected: boolean;
  onConnect: (name: string) => void;
  onDisconnect: () => void;
  accountHashString: string;
  WordMarkIcon: React.ReactElement;
}

const WordMarkContainerStyled = styled.div`
  // position: absolute;
  // display: block;
  // left: 50%;
  // top: 0%;
  // transform: translate(-50%, 10px);

  & svg > path {
    fill: ${(props) => props.theme.NewPurpleColor};
  }

  // @media ${device.mobileS} {
  //   position: relative;
  // }
`;
const ConnectButtonContainerStyled = styled.div`
  min-width: 160px;

  & button {
    width: 100%;
  }

  @media ${device.mobileS} {
    min-width: 60px;
    top: 40px;
  }
  @media ${device.mobileL} {
    min-width: 60px;
    top: 40px;
  }
  @media ${device.tablet} {
    min-width: auto;
    top: 0;
  }
`;
const NavBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  gap: 2rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
  }

  @media (min-width: 1200px) {
    padding: 1rem 5.5rem;
  }
  @media (min-width: 1400px) {
    padding: 1rem 7rem;
  }
  @media (min-width: 1920px) {
    padding: 1rem 19.5rem;
  }
  @media (min-width: 2560px) {
    padding: 1rem 39rem;
  }
`
const NavBarMainStyled = styled.nav`
  height: 72px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #0F1723;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (min-width: 992px) {
    width: 80%;
  }
`;
const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: #AD203E;
`
const TitleWrapper = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  height: 100%;
  // padding: 0 3rem 0 1.75rem;
  cusror: pointer;

  // @media (min-width: 1200px) {
  //   padding: 0 3rem 0 5.5rem;
  // }
  // @media (min-width: 1400px) {
  //   padding: 0 3rem 0 7rem;
  // }
  // @media (min-width: 1920px) {
  //   padding: 0 3rem 0 19.5rem;
  // }
  // @media (min-width: 2560px) {
  //   padding: 0 3rem 0 39rem;
  // }
`
const NavBarContent = styled.div`
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  // padding-left: 5rem;
  // flex-grow: 1;

  // @media (min-width: 992px) {
  //   padding-right: 5rem;
  // }
  // @media (min-width: 1400px) {
  //   padding-right: 10rem;
  // }
  // @media (min-width: 1920px) {
  //   padding-right: 15rem;
  // }
  // @media (min-width: 2560px) {
  //   padding-right: 20rem;
  // }
`
const DivStyled2 = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;
const PunkText = styled.a`
  color: #4592AE;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-decoration: none;
`
const TextStyled = styled.span<any>`
  opacity: ${(props) => (props.collapse ? "0" : "1")};
  transition: all 200ms ease;
  font-size: 1rem;
  letter-spacing: 0.02em;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 19.5px;
  padding: 0.45rem 0;
  padding-right: 2rem;
  a {
    color: #fff;
    text-decoration: none;
  }

  &:last-child {
    border-right: none;
  }
`;
const DivInput = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
  width: 22rem;
  border: 2px solid #4592AE;
  border-radius: 8px;
  background-color: #fff;
  padding: 7px;

  input {
    width: 95%;
    border: none;
    color: #4592AE;

    ::placeholder {
      color: #4592AE;
    }

    &:focus {
      outline: none;
    }
  }

  @media (min-width: 1024px) {
    width: 30rem;
  }
  
`
const LinkStyled = styled.a`
  color: #000;
  font-weight: bold;
  text-decoration: none;
`

export const NavBarCD = ({
  isConnected,
  onConnect,
  onDisconnect,
  accountHashString,
  WordMarkIcon,
}: NavBarCDProps) => (
  <div style={{width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#0F1723'}}>
    <NavBarMainStyled>
      <TitleWrapper>
        <TextStyled>
          <NavLink
              to="/"
              style={{fontSize: '1.5rem', color: '#fff', fontWeight: '600'}}
          >
            PUNKDEX
          </NavLink>
        </TextStyled>
      </TitleWrapper>
      <NavBarContent>
        <DivStyled2>
          <TextStyled>
            <NavLink
              to="/swap"
              style={({ isActive }) => ({
                color: isActive ? '#9A0D2F' : '#fff',
                borderBottom: isActive && '1px solid #9A0D2F',
                padding: '1.5rem 1.25rem',
                background: isActive && 'linear-gradient(180deg, rgba(154, 13, 47, 0) 0%, rgba(154, 13, 47, 0.2) 100%)'
              })
              }
            >
              Swap
            </NavLink>
          </TextStyled>
          <TextStyled>
            <NavLink
              to="/liquidity"
              style={({ isActive }) => ({
                color: isActive ? '#9A0D2F' : '#fff',
                borderBottom: isActive && '1px solid #9A0D2F',
                padding: '1.5rem 1.25rem',
                background: isActive && 'linear-gradient(180deg, rgba(154, 13, 47, 0) 0%, rgba(154, 13, 47, 0.2) 100%)'
              })
              }
            >
              Liquidity
            </NavLink>
          </TextStyled>
          <TextStyled>
            <NavLink
              to="/pools"
              style={({ isActive }) => ({
                color: isActive ? '#9A0D2F' : '#fff',
                borderBottom: isActive && '1px solid #9A0D2F',
                padding: '1.5rem 1.25rem',
                background: isActive && 'linear-gradient(180deg, rgba(154, 13, 47, 0) 0%, rgba(154, 13, 47, 0.2) 100%)'
              })
              }
            >
              Pools
            </NavLink>
          </TextStyled>
          <TextStyled>
            <NavLink
              to="/factory"
              style={({ isActive }) => ({
                color: isActive ? '#9A0D2F' : '#fff',
                borderBottom: isActive && '1px solid #9A0D2F',
                padding: '1.5rem 1.25rem',
                background: isActive && 'linear-gradient(180deg, rgba(154, 13, 47, 0) 0%, rgba(154, 13, 47, 0.2) 100%)'
              })
              }
            >
              Token Factory
            </NavLink>
          </TextStyled>
          <TextStyled>
            <a href="https://app.paradiso.io" target="_blank" style={{ color: '#fff' }}>
              Bridge
            </a>
          </TextStyled>
        </DivStyled2>
      </NavBarContent>
      <ConnectButtonContainerStyled>
          <GroupIconButtons>
            <ButtonConnectionOver
              isConnected={isConnected}
              onConnect={onConnect}
              onDisconnect={onDisconnect}
              accountHashString={accountHashString}
            />
          </GroupIconButtons>
        </ConnectButtonContainerStyled>
    </NavBarMainStyled>
  </div>
);
