import styled from 'styled-components'

export const ButtonStyle = styled.button<any>`
  color: ${props => props.enabled ? '#fff' : '#415269'};
  background-color: ${props => props.enabled ? '#9A0D2F' : '#1B232E'};
  padding: 1.7vh 1.7em;
  border: none;
  width: 391px;
  height: 50px;
  font-family: 'Montserrat';
  font-size: 16px;
  display: grid;
  place-items: center;
  &:hover {
    cursor: pointer;
  }
  &:active {
    color: ${props => props.enabled ? '#fff' : '#415269'};
    background-color: ${props => props.enabled ? '#9A0D2F' : '#1B232E'};
  }`
