import Torus from "@toruslabs/casper-embed";
import store from "store2";
import { log } from "../utils";

import { CasperServiceByJsonRPC, CLPublicKey, DeployUtil } from "casper-js-sdk";

import { Wallet } from "./Wallet";

import { WalletName, Network } from "./types";
import { NODE_ADDRESS } from "../../constant";

export const CASPER_WALLET_PUBKEY_KEY = "cw-pubk";



/**
 * Casper Wallet
 */
export class CasperWallet implements Wallet {
  private _connectPromise?: Promise<string>;
  private _publicKey?: CLPublicKey;
  private _isConnected = false;
  //@ts-ignore
  private Signer = window.CasperWalletProvider;

  constructor(private _network: Network) {
    const pubKeyHex = store.get(CASPER_WALLET_PUBKEY_KEY);

    if (pubKeyHex) {
      try {
        this._publicKey = CLPublicKey.fromHex(pubKeyHex);
        //@ts-ignore
        this.Signer = window.CasperWalletProvider;
      } catch (e) {
        log.warn(
          `Casper Wallet - constructor warning, could not decode cached hex: ${pubKeyHex}`
        );
      }
    }
  }

  // is the wallet connected?
  get isConnected(): boolean {
    return this._isConnected;
  }

  // what network is the wallet connected to?
  get network(): Network {
    return this._network;
  }
  get signer(): any {
    return this.Signer;
  }
  // (getter) name for identifying the wallet
  get name(): WalletName {
    return WalletName.TORUS;
  }

  // (getter) public key for connected wallet
  get publicKey(): CLPublicKey | undefined {
    return this._publicKey;
  }

  // (getter) account hash string for UI
  get publicKeyHex(): string {
    return this._publicKey?.toHex() ?? "";
  }

  // (getter) account hash for connected wallet
  get accountHash(): Uint8Array | undefined {
    return this._publicKey?.toAccountHash();
  }

  // (getter) account hash string for UI
  get accountHashString(): string {
    return this._publicKey?.toAccountHashStr() ?? "";
  }

  /**
   * Async try and connect to the current wallet
   *
   * @returns the the public key on success or throw error
   */
  async connect(): Promise<string> {
    if (this.isConnected) {
      return;
    }

    // If connecting just return the connect promise
    if (this._connectPromise) {
      return this._connectPromise;
    }

    try {
      //@ts-ignore
      const provider = this.Signer();
      await provider.requestConnection();
      const account = await provider.getActivePublicKey();
      // store key
      store.set(CASPER_WALLET_PUBKEY_KEY, account);

      // set the key
      this._publicKey = CLPublicKey.fromHex(account);

      // we are connected
      this._isConnected = true;
    } catch (err) {
      log.error(`Casper Wallet - connect error: ${err}`);

      // rethrow error
      throw err;
    }
  }

  /**
   * Async try and read the active key
   *
   * @returns the the public key hex on success or throw error
   */
  async getActiveKey(): Promise<string> {
    return this.publicKeyHex;
  }

  /**
   * Async try and disconnect from the current wallet
   *
   * @returns a promise for pass/fail
   */
  async disconnect(): Promise<void> {
    if (!this.isConnected) {
      return;
    }

    try {
      //@ts-ignore
      const provider = this.Signer();
      await provider.disconnectFromSite();
    } catch (err) {
      log.error(
        `Casper Wallet - disconnect error, probably disconnecting from a disconnected signer: ${err}`
      );

      // rethrow error
      throw err;
    }
  }

  /**
   * Sign a deploy
   *
   * @params deploy Deploy to sign
   *
   * @returns a signed deploy
   */
  async sign(deploy: DeployUtil.Deploy): Promise<DeployUtil.Deploy> {
    try {
      // Convert the deploy to a raw json
      
      // console.log("deployJSON", await this.getActiveKey());
      const deployJSON = DeployUtil.deployToJson(deploy);
      // Sign the deploy with the signer
      //@ts-ignore
      // const provider = this.Signer();
      // console.log('provider', provider)
      const signedDeployJSON = await this.Signer().sign(
        JSON.stringify(deployJSON),
        this.publicKeyHex.toLowerCase()
      );

      return DeployUtil.setSignature(
        deploy,
        signedDeployJSON.signature,
        CLPublicKey.fromHex(this.publicKeyHex)
      );
      // return DeployUtil.deployFromJson(signedDeployJSON).unwrap();
    } catch (err) {
      log.error(`Casper Wallet - signAndDeploy error: ${err}`);
      throw err;
    }
  }

  /**
   * Deploy a signed deploy
   *
   * @params deploy Signed deploy to deploy
   *
   * @returns a deploy hash
   */
  async deploy(signedDeploy: DeployUtil.Deploy): Promise<string> {
    try {
      const casperService = new CasperServiceByJsonRPC(NODE_ADDRESS);
      return (await casperService.deploy(signedDeploy)).deploy_hash;
    } catch (err) {
      log.error(`Casper Wallet - signAndDeploy error: ${err}`);
      throw err;
    }
  }
}
