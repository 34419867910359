import styled from 'styled-components';

export const SlippageContainer = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const SlippageColumnLeft = styled.section`
  text-align: start;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #fff;
`;
export const SlippageColumnRight = styled.section`
  text-align: end;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #fff;
`;

export const Input = styled.input`
  background: transparent;
  color: #fff;
  width: 2.5rem;
  border: solid 0.5px #fff;
  padding: 3px;
  border-radius: 2px;
`;
export const Span = styled.span`
  position: absolute;
  right: 0.5vw;
  top: 3px;
`;
