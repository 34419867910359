import styled from 'styled-components'

export const PoolModulesStyled = styled.section`
    margin:10px;
    padding:20px 30px;
    border: 1px solid #4592AE;
    border-radius: 10px;
    background-color: #fff;
    color: #4592AE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    gap:1rem;
`