import React from "react";
import {
  HRefLink,
  OverlayPopup,
  PopupBottom,
  PopupClose,
  PopupContainer,
  PopupContent,
  PopupTitle,
  WalletContainer,
  DisclaimerContent,
  PopoverButtonStyled,
  WalletSelectionImageStyled,
  ButtonText,
  TFlex1,
} from "./styles";
import { ReactComponent as Close } from "../../../assets/newIcons/x.svg";
import { WalletSelectionDiv } from "../WalletSelectionDiv";
import casperSignerWallet from "../../../assets/walletIcons/casper.svg";
import torusWallet from "../../../assets/walletIcons/torus.svg";

import casperDashWallet from "../../../assets/walletIcons/casper-dash.png";
import casperWallet from "../../../assets/walletIcons/casper-wallet.png";

import { WalletName } from "../../../commons";
import { NewIcons } from "../NewIcons";
import { useTheme } from "styled-components";
import { LightThemeInterface } from "../../../contexts/ThemeContext/themes";
import { CiWallet } from "react-icons/ci";
import { Modal } from "react-bootstrap";

export interface ConnectionPopup {
  isConnected: boolean;
  isOpened: boolean;
  title: string;
  onClose: () => void;
  onConnect: (name: WalletName) => void;
  onToggle: () => void;
  showButton?: boolean;
}

export const ConnectionPopup = ({
  isConnected,
  title,
  onClose,
  onConnect,
  isOpened,
  onToggle,
  showButton,
}: ConnectionPopup) => {
  const theme = useTheme() as LightThemeInterface;
  const closeHandler = () => {
    onClose();
  };

  return (
    <>
      {!isConnected && (
        <>
          {showButton !== false && (
            <PopoverButtonStyled onClick={onToggle}>
              CONNECT A WALLET
            </PopoverButtonStyled>
          )}
          <Modal
            show={isOpened} onHide={() => closeHandler()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <PopupContainer>
              <PopupTitle>
                {title}
                <PopupClose onClick={closeHandler}>
                  <NewIcons
                    Icon={Close}
                    size={24}
                    style={{ fill: '#fff' }}
                  />
                </PopupClose>
              </PopupTitle>
              <PopupContent>
                <DisclaimerContent>
                  By connecting your wallet, you acknowledge that you have read,
                  understand and accept the terms in the Disclaimer
                </DisclaimerContent>
                <WalletContainer>
                  <WalletSelectionDiv
                    isSelected={false}
                    onClick={() => onConnect(WalletName.CASPER_SIGNER)}
                  >
                    <WalletSelectionImageStyled
                      src={casperSignerWallet}
                      alt=""
                    />
                    <ButtonText>Signer Wallet</ButtonText>
                  </WalletSelectionDiv>
                  <WalletSelectionDiv
                    isSelected={false}
                    onClick={() => onConnect(WalletName.CASPER_DASH)}
                  >
                    <WalletSelectionImageStyled src={casperDashWallet} alt="" />
                    <ButtonText>Dash Wallet</ButtonText>
                  </WalletSelectionDiv>

                  <WalletSelectionDiv
                    isSelected={false}
                    onClick={() => onConnect(WalletName.CASPER_WALLET)}
                  >
                    <WalletSelectionImageStyled src={casperWallet} alt="" />
                    <ButtonText>Casper Wallet</ButtonText>
                  </WalletSelectionDiv>

                  <WalletSelectionDiv
                    isSelected={false}
                    onClick={() => onConnect(WalletName.TORUS)}
                  >
                    <WalletSelectionImageStyled src={torusWallet} alt="" />
                    <ButtonText>Torus Wallet</ButtonText>
                  </WalletSelectionDiv>
                </WalletContainer>
                {
                  //<CollapsingPopup>show uninstall wallets <HiChevronDown /></CollapsingPopup>
                }
              </PopupContent>
            </PopupContainer>
          </Modal>
        </>
      )}
    </>
  );
};
