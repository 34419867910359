import styled from 'styled-components'

export const ButtonStyle = styled.button`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #9A0D2F;
  padding: 10px 0;
  border: none;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1rem;
  &:hover{
    cursor: pointer;
    background-color: #9A0D2F;
  }
  &:active{
    background-color: #9A0D2F;
  }
  &:disabled{
    cursor: not-allowed;
    background: #1B232E;
    color: #415269;
  }
`
