import React, { createContext, ReactNode, useContext, useReducer } from "react";

import {
  addTransactions,
  calculateSwapDetails,
  convertUIStringToBigNumber,
  signAndDeploySwap,
  sleep,
  SwapDetails,
  Token,
} from "../../commons";
import { DEADLINE, NotificationType, SUPPORTED_NETWORKS } from "../../constant";
import {
  apiClient,
  casperClient,
  ConfigProviderContext,
} from "../ConfigContext";
import BigNumber from "bignumber.js";
import { notificationStore } from "../../store/store";
import { ERROR_BLOCKCHAIN } from "../../constant/errors";
import { toast } from "react-toastify";
import {
  Toast,
  ToastDimiss,
  ToastError,
  ToastLoading,
  ToastPromise,
} from "../../constant/toast";
import { log } from "console";
import { PairState } from "../../reducers/PairsReducer";

export interface SwapContext {
  onConfirmSwapConfig: (
    amountA: number | string,
    amountB: number | string,
    slippage: number,
    gasFee: number,
    pairState: PairState,
    tokenState: any
  ) => Promise<boolean>;
  getSwapDetails: (
    tokenA: Token,
    tokenB: Token,
    reserve0: BigNumber.Value,
    reserve1: BigNumber.Value,
    inputValue: BigNumber.Value,
    token: Token,
    slippage: number,
    fee: number
  ) => Promise<SwapDetails>;
}

export const SwapProviderContext = createContext<SwapContext>({} as any);

export const SwapContext = ({ children }: { children: ReactNode }) => {
  const {
    firstTokenSelected,
    secondTokenSelected,
    refreshAll,
    configState,
    setConfirmModal,
    setLinkExplorer,
    setProgressModal,
  } = useContext(ConfigProviderContext);
  const { updateNotification } = notificationStore();

  async function onConfirmSwapConfig(
    amountA: number | string,
    amountB: number | string,
    slippage: number,
    gasFee: number,
    pairState: PairState,
    tokenState: any
  ): Promise<boolean> {
    // updateNotification({
    //   type: NotificationType.Loading,
    //   title: "Swapping.",
    //   subtitle: "",
    //   show: true,
    //   chargerBar: false,
    // });

    try {
      let deployHash, deployResult;
      try {
        console.log(
          "Come here 1",
          amountA,
          amountB,
          firstTokenSelected,
          secondTokenSelected,
          convertUIStringToBigNumber(amountA),
          convertUIStringToBigNumber(amountB)
        );
        [deployHash, deployResult] = await signAndDeploySwap(
          apiClient,
          casperClient,
          configState.wallet,
          DEADLINE,
          convertUIStringToBigNumber(amountA, firstTokenSelected.decimals),
          convertUIStringToBigNumber(amountB, secondTokenSelected.decimals),
          firstTokenSelected,
          secondTokenSelected,
          slippage / 100,
          configState.mainPurse,
          gasFee,
          pairState,
          tokenState
        );
      } catch (data) {
        ToastDimiss();
        ToastError(
          ERROR_BLOCKCHAIN[`${data}`]
            ? ERROR_BLOCKCHAIN[`${data}`].message
            : `${data}`
        );
        return;
      }

      // setProgressModal(true);
      setLinkExplorer(
        SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`
      );
      // await ToastPromise(casperClient.waitForDeployExecution(deployHash), {
      //   pending: "Wait for deploying transaction",
      //   success: "Check your transaction in wallet button",
      //   error: {
      //     render({ data }) {
      //       return ERROR_BLOCKCHAIN[`${data}`]
      //         ? ERROR_BLOCKCHAIN[`${data}`].message
      //         : `${data}`;
      //     },
      //   },
      // });
      toast.loading(
        <div style={{cursor: 'pointer'}} onClick={() => window.open(SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`, '_blank')}>
          Wait for deploying transaction
        </div>,
        {
          position: 'bottom-right',
          autoClose: 60000
        }
      )
      try {
        const [_deploy, _raw] = await casperClient.waitForDeployExecution(deployHash)
        if (_deploy) {
          ToastDimiss()
          sleep(500)
          Toast(
            <div style={{cursor: 'pointer'}} onClick={() => window.open(SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`, '_blank')}>
              <p color="#fff">View transaction in explorer</p>
            </div>
          )
          addTransactions(
            `Swap ${amountA} ${firstTokenSelected.symbol} to ${amountB} ${secondTokenSelected.symbol}`,
            deployHash,
            true,
            configState.wallet.publicKeyHex
          )
        }
      } catch (data) {
        ToastDimiss()
        ToastError(
          ERROR_BLOCKCHAIN[`${data}`]
            ? ERROR_BLOCKCHAIN[`${data}`].message
            : `${data}`
        );
        addTransactions(
          `Swap ${amountA} ${firstTokenSelected.symbol} to ${amountB} ${secondTokenSelected.symbol}`,
          deployHash,
          false,
          configState.wallet.publicKeyHex
        )
        console.log('swap error', data)
      }
      // setProgressModal(false);
      // setConfirmModal(true);
      await refreshAll();
      return true;
    } catch (err) {
      setProgressModal(false);
      console.log("onConfirmSwapConfig");
      return false;
    }
  }

  /***
   * it returns tokensToTransfer, priceImpact, minTokenBToTransfer, exchangeRateA and exchangeRateB that belong to the swap detail
   * @param tokenA first token
   * @param tokenB second token
   * @param reserve0 first token reserve in pair
   * @param reserve1 second token reserve in pair
   * @param inputValue input tokens
   * @param token input token types matching one of tokenA or tokenB
   * @param slippage decimal slippage
   * @param fee decimal fee
   *
   * @return SwapDetails
   */
  async function getSwapDetails(
    tokenA: Token,
    tokenB: Token,
    reserve0: BigNumber.Value,
    reserve1: BigNumber.Value,
    inputValue: BigNumber.Value,
    token: Token,
    slippage = 0.005,
    fee = 0.003
  ): Promise<SwapDetails> {
    return calculateSwapDetails(
      apiClient,
      tokenA,
      tokenB,
      reserve0,
      reserve1,
      inputValue,
      token,
      slippage,
      fee
    );
  }

  return (
    <SwapProviderContext.Provider
      value={{
        onConfirmSwapConfig,
        getSwapDetails,
      }}
    >
      {children}
    </SwapProviderContext.Provider>
  );
};
