import React from "react";
import { ToastContainer } from "react-toastify";
import NewLayout from "../../../layout/NewLayout";
import { ContainerNSM } from "../../atoms/ContainerNSM";
import { ModuleSwapNSM } from "../../atoms/ModuleSwapNSM";
import SwapNewModule from "../../organisms/SwapModule";

export const Swap = () => {
  return (

      <ContainerNSM>
        {/* <ToastContainer position="top-center" autoClose={3000} /> */}
        <ModuleSwapNSM>
          <SwapNewModule />
        </ModuleSwapNSM>
      </ContainerNSM>
  )
}
