import React from 'react'
import styled from 'styled-components'
const ModuleSwapStyled = styled.div`
  padding: 5rem 1rem 12rem;
  width: 100%;

  @media (min-width: 768px) {
    width: 90%
  }

  @media (min-width: 992px) {
    width: 80%;
  }
`

export const ModuleSwapNSM = ({children}) => {
  return (
    <ModuleSwapStyled>{children}</ModuleSwapStyled>
  )
}
