import styled from 'styled-components'
import {device, deviceMax} from "../../../contexts/ThemeContext/themes";

export const WrappedPool = styled.div`
    padding: 5rem 0rem;
`

export const WrappedHeaderPool = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    grid-row: 1;
    font-family: 'MyriadPro';
    flex: none;
    
    @media ${device.mobileS} {
        width: auto;
        padding: 0 1rem;
    }
    
    
    @media ${device.laptop} {
        grid-column: 2/11;
        align-self: start;
        padding: 1rem 8.5rem;
    }
`

export const HeaderPool = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #4592AE;
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    gap: 8px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
`
export const HeaderPoolText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #4592AE;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.02em;
    gap: 8px;
    margin-top: 3rem;
    
    flex: none;
    order: 0;
    flex-grow: 0;
`

export const WrappedPoolTitle = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    
    @media ${device.mobileS} {
        flex-direction: column;
        width: 100%;
    }
    
    @media ${device.laptop} {
        flex-direction: row;
        width: auto;
    }
`

export const TitleBoxWrapper = styled.div`
    @media ${device.mobileS} {
        width: 100%;
    }
    
    @media ${device.laptop} {
        width: auto;
    }
`

export const TitleBox = styled.div`
    background-color: #4592AE;
    padding: 10px;
    border-radius: 12px;
`
export const Column6 = styled.div`
    flex: 6;
`
export const Column1 = styled.div`
    flex: 1.2;
`

interface PoolButton {
  enabled: boolean,
}

export const CreatePoolButton = styled.button<PoolButton>`
    color: ${props => props.enabled ? props.theme.secondBackgroundColor : props.theme.NewGrayColor};
    background-color: ${props => props.enabled ? props.theme.thirdBackgroundColor : props.theme.NewGreyColor};
    border-radius: 10px;
    border:none;
    justify-content: center;
    align-items: center;
    display: grid;
    height: 3.625rem;
    padding: 1rem 1.56rem;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
    font-family: 'MyriadPro';
    
    &:hover{
        cursor: pointer;
    }
    &:active{
        color: ${props => props.enabled ? props.theme.thirdBackgroundColor : props.theme.NewGrayColor};
        background-color: ${props => props.enabled ? props.theme.secondBackgroundColor : props.theme.NewGreyColor};
    }
`