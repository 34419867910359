import { useState } from "react";
import { NotificationType } from "../../constant";
import { notificationStore } from "../../store/store";

const isCSPRValid = () => {
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { updateNotification, dismissNotification } = notificationStore();

  const showNotification = () => {
    // updateNotification({
    //   type: NotificationType.Error,
    //   title: 'Insufficent Gas Reserved',
    //   subtitle: '',
    //   show: true,
    //   chargerBar: false,
    // });
    // console.log("Insufficent Gas Reserved");
  };

  const handleValidate = (
    currentValue: number,
    balance: number,
    gasFee: number,
    symbol: string,
    csprBalance: string
  ) => {
    // console.log("handleValidate: currentValue", currentValue);
    // console.log("balance", balance);
    // console.log("gasFee", gasFee);
    if (symbol !== "CSPR") {
      if (
        Number(currentValue) > balance ||
        Number(gasFee) > Number(csprBalance)
      ) {
        setDisableButton(true);
        return showNotification();
      }
    } else {
      if (Number(currentValue) + Number(gasFee) > balance) {
        setDisableButton(true);
        return showNotification();
      }
    }
    setDisableButton(false);
    dismissNotification();
  };

  return {
    disableButton,
    setDisableButton,
    handleValidate,
    showNotification,
    dismissNotification,
  };
};

export default isCSPRValid;
