import React from 'react'
import styled from 'styled-components'

const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerNSM = ({children}) => {
  return (
    <ContainerStyled>{children}</ContainerStyled>
  )
}
