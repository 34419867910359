import styled from 'styled-components'

export const InputStyled = styled.input`
    box-sizing: border-box;
    background-color: transparent;
    /*color: ${props => props.theme.PrimaryColor};*/
    color: #fff;
    border-style:none;
    font-family: 'Montserrat'; 
    font-size: 14px;
    line-height: 32px;
    width:100%;
    &::placeholder{
        color: #415269;
        background-color: transparent;
    }
    &:focus{
        outline: none;
    }
`