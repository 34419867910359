import React, {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useState,
} from "react";
import {
  addTransactions,
  calculateLiquidityDetails,
  convertUIStringToBigNumber,
  LiquidityDetails,
  signAndDeployAddLiquidity,
  signAndDeployRemoveLiquidity,
  sleep,
  Token,
} from "../../commons";
import { DEADLINE, NotificationType, SUPPORTED_NETWORKS } from "../../constant";
import {
  apiClient,
  casperClient,
  ConfigProviderContext,
} from "../ConfigContext";
import BigNumber from "bignumber.js";
import { notificationStore } from "../../store/store";
import { ERROR_BLOCKCHAIN } from "../../constant/errors";
import {
  Toast,
  ToastDimiss,
  ToastError,
  ToastLoading,
  ToastPromise,
} from "../../constant/toast";
import { toast } from "react-toastify";

export interface LiquidityContext {
  onAddLiquidity: (
    amountA: number | string,
    amountB: number | string,
    slippage: number,
    gasFee: number
  ) => Promise<boolean>;
  onRemoveLiquidity: (
    liquidity: number | string,
    tokenA: Token,
    tokenB: Token,
    amountA: number | string,
    amountB: number | string,
    slippage: number,
    gasFee: number
  ) => Promise<boolean>;
  getLiquidityDetails: (
    tokenA: Token,
    tokenB: Token,
    reserve0: BigNumber.Value,
    reserve1: BigNumber.Value,
    inputValue: BigNumber.Value,
    token: Token,
    slippage: number,
    fee: number
  ) => Promise<LiquidityDetails>;
  isRemovingPopupOpen?: boolean;
  setRemovingPopup?: any;
}

export const LiquidityProviderContext = createContext<LiquidityContext>(
  {} as any
);

export const LiquidityContext = ({ children }: { children: ReactNode }) => {
  const {
    firstTokenSelected,
    secondTokenSelected,
    refreshAll,
    configState,
    setConfirmModal,
    setLinkExplorer,
    setProgressModal,
  } = useContext(ConfigProviderContext);

  const [isRemovingPopupOpen, setRemovingPopup] = useState(false);
  const { updateNotification, dismissNotification } = notificationStore();

  async function onAddLiquidity(
    amountA: number | string,
    amountB: number | string,
    slippage: number,
    gasFee: number
  ): Promise<boolean> {
    try {
      let deployHash, deployResult;
      try {
        [deployHash, deployResult] = await signAndDeployAddLiquidity(
          apiClient,
          casperClient,
          configState.wallet,
          DEADLINE,
          convertUIStringToBigNumber(amountA, firstTokenSelected.decimals),
          convertUIStringToBigNumber(amountB, secondTokenSelected.decimals),
          firstTokenSelected,
          secondTokenSelected,
          slippage / 100,
          configState.mainPurse,
          gasFee
        );
      } catch (data) {
        ToastDimiss();
        ToastError(
          ERROR_BLOCKCHAIN[`${data}`]
            ? ERROR_BLOCKCHAIN[`${data}`].message
            : `${data}`
        );
        return;
      }
      // setProgressModal(true);
      setLinkExplorer(
        SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`
      );

      // await ToastPromise(casperClient.waitForDeployExecution(deployHash), {
      //   pending: "Wait for deploying transaction",
      //   success: "Check your transaction in wallet button",
      //   error: {
      //     render({ data }) {
      //       return ERROR_BLOCKCHAIN[`${data}`]
      //         ? ERROR_BLOCKCHAIN[`${data}`].message
      //         : `${data}`;
      //     },
      //   },
      // });
      toast.loading(
        <div style={{cursor: 'pointer'}} onClick={() => window.open(SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`, '_blank')}>
          Wait for deploying transaction
        </div>,
        {
          position: 'bottom-right',
          autoClose: 60000
        }
      )

      try {
        const [_deploy, _raw] = await casperClient.waitForDeployExecution(
          deployHash
        );
        
        if (_deploy) {
          ToastDimiss()
          sleep(500)
          Toast(
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`,
                  "_blank"
                )
              }
            >
              <p color="#fff">View transaction in explorer</p>
            </div>
          );
          addTransactions(
            `Add ${amountA} ${firstTokenSelected.symbol} to ${firstTokenSelected.symbol}-${secondTokenSelected.symbol}`,
            deployHash,
            true,
            configState.wallet.publicKeyHex
          )
        }
      } catch (error) {
        ToastDimiss()
        ToastError(
          ERROR_BLOCKCHAIN[`${error}`]
            ? ERROR_BLOCKCHAIN[`${error}`].message
            : `${error}`
        );
        addTransactions(
          `Add ${amountA} ${firstTokenSelected.symbol} to ${firstTokenSelected.symbol}-${secondTokenSelected.symbol}`,
          deployHash,
          false,
          configState.wallet.publicKeyHex
        )
        console.log("add liquidity error", error);
      }
      // setProgressModal(false);
      // setConfirmModal(true);
      await refreshAll();
      return true;
    } catch (err) {
      setProgressModal(false);
      await refreshAll();
      console.log("onAddLiquidity");
      return false;
    }
  }

  async function onRemoveLiquidity(
    liquidity: number | string,
    tokenA: Token,
    tokenB: Token,
    amountA: number | string,
    amountB: number | string,
    slippage: number,
    gasFee: number
  ): Promise<boolean> {
    try {
      let deployHash, deployResult;
      try {
        [deployHash, deployResult] = await signAndDeployRemoveLiquidity(
          apiClient,
          casperClient,
          configState.wallet,
          DEADLINE,
          convertUIStringToBigNumber(liquidity, 18),
          convertUIStringToBigNumber(amountA, tokenA.decimals),
          convertUIStringToBigNumber(amountB, tokenB.decimals),
          tokenA,
          tokenB,
          slippage / 100,
          configState.mainPurse,
          gasFee
        );
      } catch (data) {
        ToastDimiss();
        ToastError(
          ERROR_BLOCKCHAIN[`${data}`]
            ? ERROR_BLOCKCHAIN[`${data}`].message
            : `${data}`
        );
        return;
      }
      //setProgressModal(true);
      setLinkExplorer(
        SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`
      );
      // await ToastPromise(casperClient.waitForDeployExecution(deployHash), {
      //   pending: "Wait for deploying transaction",
      //   error: {
      //     render({ data }) {
      //       return ERROR_BLOCKCHAIN[`${data}`]
      //         ? ERROR_BLOCKCHAIN[`${data}`].message
      //         : `${data}`;
      //     },
      //   },
      // });
      toast.loading(
        <div style={{cursor: 'pointer'}} onClick={() => window.open(SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`, '_blank')}>
          Wait for deploying transaction
        </div>,
        {
          position: 'bottom-right',
          autoClose: 60000
        }
      )

      try {
        const [_deploy, _raw] = await casperClient.waitForDeployExecution(
          deployHash
        );

        if (_deploy) {
          ToastDimiss()
          sleep(500)
          Toast(
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`,
                  "_blank"
                )
              }
            >
              <p color="#fff">View transaction in explorer</p>
            </div>
          );
          addTransactions(
            `Remove ${amountA} ${firstTokenSelected.symbol} on ${firstTokenSelected.symbol}-${secondTokenSelected.symbol}`,
            deployHash,
            true,
            configState.wallet.publicKeyHex
          )
        }
      } catch (error) {
        addTransactions(
          `Remove ${amountA} ${firstTokenSelected.symbol} on ${firstTokenSelected.symbol}-${secondTokenSelected.symbol}`,
          deployHash,
          false,
          configState.wallet.publicKeyHex
        )
        ToastDimiss()
        ToastError(
          ERROR_BLOCKCHAIN[`${error}`]
            ? ERROR_BLOCKCHAIN[`${error}`].message
            : `${error}`
        );
        console.log("remove liquidty error", error);
      }
      //setProgressModal(false);
      //setConfirmModal(true);
      await sleep(15000);
      await refreshAll();
      return true;
    } catch (err) {
      //setProgressModal(false);
      await refreshAll();
      console.log("onRemoveLiquidity");
      return false;
    }
  }

  async function getLiquidityDetails(
    tokenA: Token,
    tokenB: Token,
    reserve0: BigNumber.Value,
    reserve1: BigNumber.Value,
    inputValue: BigNumber.Value,
    token: Token,
    slippage = 0.005,
    fee = 0.003
  ): Promise<LiquidityDetails> {
    return calculateLiquidityDetails(
      apiClient,
      tokenA,
      tokenB,
      reserve0,
      reserve1,
      inputValue,
      token,
      slippage,
      fee
    );
  }

  return (
    <LiquidityProviderContext.Provider
      value={{
        onAddLiquidity,
        onRemoveLiquidity,
        getLiquidityDetails,
        isRemovingPopupOpen,
        setRemovingPopup,
      }}
    >
      {children}
    </LiquidityProviderContext.Provider>
  );
};
