import csprIcon from "../../assets/swapIcons/casperIcon.png";
import wcsprIcon from "../../assets/swapIcons/wrappedCasperIcon.png";
import cstIcon from "../../assets/swapIcons/casperswap.png";
import wethIcon from "../../assets/swapIcons/wethIcon.svg";
import wbtcIcon from "../../assets/swapIcons/btc.png";
import usdtIcon from "../../assets/swapIcons/tether.png";
import usdcIcon from "../../assets/swapIcons/usdc.png";

import { Token } from "../../commons/api";

export interface TokenState {
  tokens: Record<string, Token>;
  firstTokenSelected: string;
  secondTokenSelected: string;
}

export const RAW_TOKENS_TESTNET: Record<string, Token> = {
  CSPR: {
    name: "Casper",
    chainId: 1,
    symbol: "CSPR",
    symbolPair: "WCSPR",
    decimals: 9,
    contractHash: "",
    packageHash: "",
    logoURI: csprIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  // CST: {
  //   name: "CasperSwap",
  //   chainId: 1,
  //   symbol: "CST",
  //   symbolPair: "CST",
  //   decimals: 9,
  //   contractHash:
  //     "hash-aff3a1beb6239317e84d898cd04e10aa3368f646009188ea596514b6bb1a34c9",
  //   packageHash:
  //     "hash-995947f349c23a1812f6c7702e75eb95afabdb5f389f150e4ddb91c9de6225f0",
  //   logoURI: cstIcon,
  //   amount: "0.0000",
  //   allowance: "0.0000",
  //   priceUSD: "0.00",
  // },
  WETH: {
    name: "Wrapped Ether",
    chainId: 1,
    symbol: "WETH",
    symbolPair: "WETH",
    decimals: 18,
    contractHash:
      "hash-543de187f11751c67e0a2cc9a588b62fece5569de518125aaa7b1b77730d1413",
    packageHash:
      "hash-062cb2697a0deaf0315cfcb3864c9ec31a7ad0d1703fc7d344e9e95d01ec5005",
    logoURI: wethIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  WCSPR: {
    name: "Wrapped Casper",
    chainId: 1,
    symbol: "WCSPR",
    symbolPair: "WCSPR",
    decimals: 9,
    contractHash:
      "hash-785aa60c219869f1770d1aa44cebfaf3a8fc0004551677b67d1441bdc57470c1",
    packageHash:
      "hash-0885c63f5f25ec5b6f3b57338fae5849aea5f1a2c96fc61411f2bfc5e432de5a",
    logoURI: wcsprIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  USDT: {
    name: "Tether",
    chainId: 1,
    symbol: "USDT",
    symbolPair: "USDT",
    decimals: 6,
    contractHash:
      "hash-4070b386365e892b2a79905715a3cd8fce329655742d69b63c04a50102ce8b61",
    packageHash:
      "hash-03371b2d87723ac61f2ea52f9a423d0bb769ce6757279962aefa08e614653dc4",
    logoURI: usdtIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  USDC: {
    name: "USD Coin",
    chainId: 1,
    symbol: "USDC",
    symbolPair: "USDC",
    decimals: 18,
    contractHash:
      "hash-1f9f2b16e273ccf48d6588b3b3d8c3da83753c6852225fade032933058ae8c94",
    packageHash:
      "hash-05a1f506c60060a05136a41f67a071a370be71f17bdfb2f4e089b9a6ea745758",
    logoURI: usdcIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
};

export const RAW_TOKENS_MAINNET: Record<string, Token> = {
  CSPR: {
    name: "Casper",
    chainId: 1,
    symbol: "CSPR",
    symbolPair: "WCSPR",
    decimals: 9,
    contractHash: "",
    packageHash: "",
    logoURI: csprIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  // CST: {
  //   name: "CasperSwap",
  //   chainId: 1,
  //   symbol: "CST",
  //   symbolPair: "CST",
  //   decimals: 9,
  //   contractHash:
  //     "hash-aff3a1beb6239317e84d898cd04e10aa3368f646009188ea596514b6bb1a34c9",
  //   packageHash:
  //     "hash-995947f349c23a1812f6c7702e75eb95afabdb5f389f150e4ddb91c9de6225f0",
  //   logoURI: cstIcon,
  //   amount: "0.0000",
  //   allowance: "0.0000",
  //   priceUSD: "0.00",
  // },
  WETH: {
    name: "Wrapped Ether",
    chainId: 1,
    symbol: "WETH",
    symbolPair: "WETH",
    decimals: 9,
    contractHash:
      "hash-82a894a18840f988dd87b3121a52ed951bb16236ff772572b9531f93a51aa7f7",
    packageHash:
      "hash-28eed3da2b123334c7913d84c4aea0ed426fd268d29410cb12c6bc8a453183f6",
    logoURI: wethIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  WCSPR: {
    name: "Wrapped Casper",
    chainId: 1,
    symbol: "WCSPR",
    symbolPair: "WCSPR",
    decimals: 9,
    contractHash:
      "hash-785aa60c219869f1770d1aa44cebfaf3a8fc0004551677b67d1441bdc57470c1",
    packageHash:
      "hash-0885c63f5f25ec5b6f3b57338fae5849aea5f1a2c96fc61411f2bfc5e432de5a",
    logoURI: wcsprIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  USDT: {
    name: "Tether",
    chainId: 1,
    symbol: "USDT",
    symbolPair: "USDT",
    decimals: 6,
    contractHash:
      "hash-4070b386365e892b2a79905715a3cd8fce329655742d69b63c04a50102ce8b61",
    packageHash:
      "hash-03371b2d87723ac61f2ea52f9a423d0bb769ce6757279962aefa08e614653dc4",
    logoURI: usdtIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
  USDC: {
    name: "USD Coin",
    chainId: 1,
    symbol: "USDC",
    symbolPair: "USDC",
    decimals: 18,
    contractHash:
      "hash-1f9f2b16e273ccf48d6588b3b3d8c3da83753c6852225fade032933058ae8c94",
    packageHash:
      "hash-05a1f506c60060a05136a41f67a071a370be71f17bdfb2f4e089b9a6ea745758",
    logoURI: usdcIcon,
    amount: "0.0000",
    allowance: "0.0000",
    priceUSD: "0.00",
  },
};

export const TOKENS: Record<string, Token> = {};

const isTestnet = process.env.REACT_APP_IS_TESTNET

export const RAW_TOKENS = isTestnet ? RAW_TOKENS_TESTNET : RAW_TOKENS_MAINNET

Object.values(RAW_TOKENS).map((t) => {
  const token = Object.assign({}, t);
  TOKENS[t.symbol] = token;
});
console.log("TOKENS", TOKENS);
export const initialTokenState: TokenState = {
  tokens: TOKENS,
  firstTokenSelected: "CSPR",
  secondTokenSelected: "WETH",
};

export enum TokenActions {
  UPDATE_TOKENS = "UPDATE_TOKENS",
  SELECT_FIRST_TOKEN = "SELECT_FIRST_TOKEN",
  SELECT_SECOND_TOKEN = "SELECT_SECOND_TOKEN",
  LOAD_BALANCE = "LOAD_BALANCE",
  LOAD_ALLOWANCE = "LOAD_ALLOWANCE",
  LOAD_PRICE_USD = "LOAD_PRICE_USD",
  SWITCH_TOKENS = "SWITCH_TOKENS",
}

export type TokenAction =
  | {
      type: TokenActions.UPDATE_TOKENS;
      payload: {
        tokens: Record<string, Token>;
      };
    }
  | {
      type: TokenActions.SELECT_FIRST_TOKEN;
      payload: string;
    }
  | {
      type: TokenActions.SELECT_SECOND_TOKEN;
      payload: string;
    }
  | {
      type: TokenActions.SELECT_SECOND_TOKEN;
      payload: string;
    }
  | {
      type: TokenActions.LOAD_BALANCE;
      payload: {
        name: string;
        amount: string;
      };
    }
  | {
      type: TokenActions.LOAD_ALLOWANCE;
      payload: {
        name: string;
        allowance: string;
      };
    }
  | {
      type: TokenActions.LOAD_PRICE_USD;
      payload: {
        name: string;
        priceUSD: string;
      };
    }
  | {
      type: TokenActions.SWITCH_TOKENS;
    };

export function TokenReducer(state: TokenState, action: TokenAction) {
  switch (action.type) {
    case TokenActions.UPDATE_TOKENS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...action.payload.tokens,
        },
      };
    case TokenActions.SELECT_FIRST_TOKEN:
      return { ...state, firstTokenSelected: action.payload };
    case TokenActions.SELECT_SECOND_TOKEN:
      return { ...state, secondTokenSelected: action.payload };
    case TokenActions.LOAD_BALANCE:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          [action.payload.name]: {
            ...state.tokens[action.payload.name],
            amount: action.payload.amount,
          },
        },
      };
    case TokenActions.LOAD_ALLOWANCE:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          [action.payload.name]: {
            ...state.tokens[action.payload.name],
            allowance: action.payload.allowance,
          },
        },
      };
    case TokenActions.LOAD_PRICE_USD:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          [action.payload.name]: {
            ...state.tokens[action.payload.name],
            priceUSD: action.payload.priceUSD,
          },
        },
      };
    case TokenActions.SWITCH_TOKENS:
      return {
        ...state,
        firstTokenSelected: state.secondTokenSelected,
        secondTokenSelected: state.firstTokenSelected,
      };
    default:
      return state;
  }
}
