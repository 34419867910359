import React from 'react'
import styled from 'styled-components'
const SearchStyle = styled.div`
  border: 1px solid #2A3340;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SearchFM = ({children}) => {
  return (
    <SearchStyle>{children}</SearchStyle>
  )
}
