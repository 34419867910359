import React from "react";
import styled from "styled-components";
const SwapDetailsStyled = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.NewPurpleColor};
  flex-direction: row;
  justify-content: space-between;
  display: flex;
`;
export const SwapDetailsNSM = ({ children }) => {
  return <SwapDetailsStyled>{children}</SwapDetailsStyled>;
};
