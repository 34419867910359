import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Tab, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styled from "styled-components/macro";

import {
  apiClient,
  casperClient,
  ConfigProviderContext,
} from "../../../contexts/ConfigContext";
import {
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  RuntimeArgs,
} from "casper-js-sdk";
import axios from "axios";
import {
  ToastPromise,
  ToastDimiss,
  ToastLoading,
  ToastError,
  Toast,
} from "../../../constant/toast";

import { usUS } from "../../../i18n";
import BigNumber from "bignumber.js";
import { ERROR_BLOCKCHAIN } from "../../../constant/errors";
import { parseUnits } from "ethers";
import { SUPPORTED_NETWORKS } from "../../../constant";
import { toast } from "react-toastify";
import { addTransactions, sleep } from "../../../commons";

import Select from "react-select";
import { stringifyForDisplay } from "@apollo/client/utilities";

const StyledContainer = styled(Container)`
  width: 100%;
  padding: 0 1.5rem;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (min-width: 992px) {
    width: 80%;
  }
`;
const SectionWrapper = styled.div`
  background-color: #0f1723;
  padding: 1rem;
  height: 50rem;
`;
const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #4592ae;
  text-align: center;
  margin-bottom: 3rem;
`;
const LabelInput = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #415269;
`;
const InputStyled = styled.input`
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid #2a3340;
  color: #fff;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #2a3340;
  }
`;
const AddButton = styled.button`
  width: 100%;
  background-color: #9a0d2f;
  padding: 1rem 0px;
  color: #fff;
  margin-top: 2rem;
  border: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
`;
const SectionInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SectionInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export default function TokenFactory() {
  const router = useNavigate();
  const { configState, isConnected, onConnectWallet } = useContext(
    ConfigProviderContext
  );

  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [decimals, setDecimals] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [feeRecipient, setFeeRecipient] = useState("");
  const [feeBurn, setFeeBurn] = useState(100);
  const [feeMarketing, setFeeMarketing] = useState(100);
  const [feeLiquidity, setFeeLiquidity] = useState(300);
  const [liquidityTrigger, setLiquidityTrigger] = useState(0);
  const [factory, setFactory] = useState("");
  const [dexType, setDexType] = useState(0);
  const [wcspr, setWcspr] = useState("");
  const [initialAdmin, setInitialAdmin] = useState("");


  async function onConnect() {
    onConnectWallet();
  }

  const onAddCustomToken = async () => {
    const contractName = `${configState.wallet.publicKey
      .toHex()
      .substring(0, 10)}-${tokenSymbol}`;

    const response = await axios.get(`/cep18.wasm`, {
      responseType: "arraybuffer",
    });
    let deployHash;
    const _totalSupply = parseUnits(totalSupply.toString(), decimals);
    const _liquidityTrigger = parseUnits(liquidityTrigger.toString(), decimals);

    try {
      const respondDeploy = await casperClient.signAndDeployWasm(
        configState.wallet,
        response.data,
        RuntimeArgs.fromMap({
          contract_name: CLValueBuilder.string(contractName),
          name: CLValueBuilder.string(tokenName),
          symbol: CLValueBuilder.string(tokenSymbol),
          decimals: CLValueBuilder.u8(decimals),
          total_supply: CLValueBuilder.u256(_totalSupply.toString()),
          fee_recipient: CLValueBuilder.key(CLPublicKey.fromHex(feeRecipient)),
          fee_burn: CLValueBuilder.u32(feeBurn),
          fee_marketing: CLValueBuilder.u32(feeMarketing),
          fee_liquidity: CLValueBuilder.u32(feeLiquidity),
          liquidity_trigger: CLValueBuilder.u256(_liquidityTrigger.toString()),
          factory: CLValueBuilder.key(
            new CLByteArray(Uint8Array.from(Buffer.from(factory)))
          ),
          dex_type: CLValueBuilder.u8(dexType),
          wcspr: CLValueBuilder.key(
            new CLByteArray(Uint8Array.from(Buffer.from(wcspr)))
          ),
          initial_admin: CLValueBuilder.key(CLPublicKey.fromHex(initialAdmin)),

          minter_list: CLValueBuilder.list([configState.wallet.publicKey]),
          admin_list: CLValueBuilder.list([configState.wallet.publicKey]),
          enable_mint_burn: CLValueBuilder.u8(1),
          events_mode: CLValueBuilder.u8(1),
        }),
        new BigNumber(270 * 10 ** 9)
      );
      deployHash = respondDeploy[0];
      console.log("respondDeploy: ", respondDeploy);
    } catch (data) {
      ToastDimiss();
      ToastError(
        ERROR_BLOCKCHAIN[`${data}`]
          ? ERROR_BLOCKCHAIN[`${data}`].message
          : `${data}`
      );
      return;
    }

    ToastDimiss();
    toast.loading(
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`,
            "_blank"
          )
        }
      >
        Wait for deploying transaction
      </div>,
      {
        position: "bottom-right",
        autoClose: 60000,
      }
    );
    try {
      const [_deploy, _raw] = await casperClient.waitForDeployExecution(
        deployHash
      );
      if (_deploy) {
        ToastDimiss();
        sleep(500);
        Toast(
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`,
                "_blank"
              )
            }
          >
            <p color="#fff">View transaction in explorer</p>
          </div>
        );
        addTransactions(
          `Mint ${totalSupply} ${tokenSymbol}  `,
          deployHash,
          true,
          configState.wallet.publicKeyHex
        );
      }
    } catch (error) {
      addTransactions(
        `Mint ${totalSupply} ${tokenSymbol} `,
        deployHash,
        false,
        configState.wallet.publicKeyHex
      );

      ToastDimiss();
      ToastError(
        ERROR_BLOCKCHAIN[`${error}`]
          ? ERROR_BLOCKCHAIN[`${error}`].message
          : `${error}`
      );
      console.log("Mint error", error);
    }
  };

  function Address(value: any): import("react").SetStateAction<string> {
    throw new Error("Function not implemented.");
  }

  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "100%", marginTop: "7rem" }}
    >
      <StyledContainer>
        {/* <p style={{ fontFamily: 'Montserrat', fontSize: '3rem', fontWeight: '500', alignSelf: 'flex-start', color: '#fff', marginBottom: '1rem' }}>
          CUSTOM TOKEN
        </p> */}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <SectionWrapper className="col-lg-6 col-md-8 col-sm-12">
            <InputWrapper>
              <SectionInput>
                <div>
                  <LabelInput>TOKEN NAME</LabelInput>
                  <InputStyled
                    value={tokenName}
                    placeholder="Type token name"
                    onChange={(e) => setTokenName(e.target.value)}
                  />
                </div>
                <div>
                  <LabelInput>TOKEN SYMBOL</LabelInput>
                  <InputStyled
                    value={tokenSymbol}
                    placeholder="Type token symbol"
                    onChange={(e) => setTokenSymbol(e.target.value)}
                  />
                </div>
                <div>
                  <LabelInput>TOKEN DECIMALS</LabelInput>
                  <InputStyled
                    value={decimals}
                    placeholder="Type token decimals"
                    type="number"
                    onChange={(e) => setDecimals(Number(e.target.value))}
                  />
                </div>
                <div>
                  <LabelInput>TOTAL SUPPLY</LabelInput>
                  <InputStyled
                    value={totalSupply}
                    placeholder="Type total supply"
                    type="number"
                    onChange={(e) => setTotalSupply(Number(e.target.value))}
                  />
                </div>
                <div>
                  <LabelInput>FEE RECIPIENT</LabelInput>
                  <InputStyled
                    value={feeRecipient}
                    placeholder="address wallet"
                    type="key"
                    onChange={(e) => setFeeRecipient(e.target.value)}
                  />
                </div>
                <div>
                  <LabelInput>FEE BURN</LabelInput>
                  <InputStyled
                    value={feeBurn}
                    placeholder="fee burn"
                    type="number"
                    onChange={(e) => setFeeBurn(Number(e.target.value))}
                  />
                </div>
              </SectionInput>
              <SectionInputs>
                <div>
                  <LabelInput>FEE MARKETING</LabelInput>
                  <InputStyled
                    value={feeMarketing}
                    placeholder="fee marketing"
                    type="number"
                    onChange={(e) => setFeeMarketing(Number(e.target.value))}
                  />
                </div>
                <div>
                  <LabelInput>FEE LIQUIDITY</LabelInput>
                  <InputStyled
                    value={feeLiquidity}
                    placeholder="fee liquidity"
                    type="number"
                    onChange={(e) => setFeeLiquidity(Number(e.target.value))}
                  />
                </div>
                <div>
                  <LabelInput>LIQUIDITY TRIGER</LabelInput>
                  <InputStyled
                    value={liquidityTrigger}
                    placeholder="triger"
                    type="number"
                    onChange={(e) =>
                      setLiquidityTrigger(Number(e.target.value))
                    }
                  />
                </div>
                <div>
                  <LabelInput>FACTORY</LabelInput>
                  <InputStyled
                    placeholder="factory"
                    value={factory}
                    onChange={(e) => setFactory(e.target.value)}
                  />
                </div>
                <div>
                  <LabelInput>DEX TYPE</LabelInput>

                  <InputStyled
                    type="number"
                    placeholder="dex type"
                    value={dexType}
                    onChange={(e) => setDexType(Number(e.target.value))}
                  />
                </div>
                <div>
                  <LabelInput>WCSPR</LabelInput>

                  <InputStyled
                    placeholder=""
                    value={wcspr}
                    onChange={(e) => setWcspr(e.target.value)}
                  />
                </div>
                <div>
                  <LabelInput>INITIAL ADMIN</LabelInput>
                  <InputStyled
                    placeholder="initial admin"
                    value={initialAdmin}
                    onChange={(e) => setInitialAdmin(e.target.value)}
                  />
                </div>
              </SectionInputs>
            </InputWrapper>
            {!isConnected && (
              <AddButton onClick={() => onConnect()}>
                Connect to wallet
              </AddButton>
            )}
            {isConnected && (
              <AddButton
                onClick={() => onAddCustomToken()}
                disabled={
                  tokenName &&
                  tokenSymbol &&
                  decimals !== 0 &&
                  decimals > 0 &&
                  totalSupply !== 0 &&
                  totalSupply > 0
                    ? false
                    : true
                }
              >
                Add
              </AddButton>
            )}
          </SectionWrapper>
        </div>
      </StyledContainer>
    </div>
  );
}
