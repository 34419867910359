import BigNumber from "bignumber.js";

import React, { useContext, useEffect, useReducer, useState } from "react";
import { ConfigProviderContext, convertNumber } from "../../../contexts/ConfigContext";
import { CLValueParsers, CLValueBuilder, encodeBase16, type CLU256, type CLKeyParameters, CLByteArray } from "casper-js-sdk"
import { NODE_ADDRESS, SUPPORTED_NETWORKS, CONTRACT_FACTORY_HASH, PACKAGE_FACTORY_HASH } from "../../../constant"
import factoryJson from '../../../ABI/factory.json'
//import { blake2b } from 'blake2b';
import { blake2b } from 'blakejs'
import { APIClient, Client, Network, Client as CasperClient, convertBigNumberToUIString, numberWithCommas } from "../../../commons"
import {
  ActionContainerNSM,
  ArrowContainerNSM,
  BalanceInputContainerNSM,
  BalanceInputItem1NSM,
  BalanceInputItem2NSM,
  BalanceInputNSM,
  ButtonHalfMax,
  ButtonHalfMaxContainer,
  ButtonSpaceNSM,
  ContainerSwapActionsNSM,
  ExchangeRateBox,
  IconPlaceNSM,
  NewBalanceSpaceNSM,
  NewSwapButtonWidth100,
  NewSwapContainerNSM,
  NewTokenDetailActionsNSM,
  NewTokenDetailItems1NSM,
  NewTokenDetailItems2NSM,
  NewTokenDetailItems3NSM,
  NewTokenDetailItems4NSM,
  NewTokenDetailSelectNSM,
  SwapDetailsNSM,
  TokenSelectionNSM,
  TokenSelectNSM,
  FlechaIcon,
  TrashIcon,
  LiquidityButton,
} from "../../atoms";
import SwitchSwap from "../../atoms/SwitchSwap";
import { LPDetail } from "../../molecules";
import FloatMenu from "../FloatMenu";
import { useSearchParams } from "react-router-dom";
import { LiquidityItem } from "../../molecules/LiquidityItem";

import { convertAllFormatsToUIFixedString, Token } from "../../../commons";
import { BalanceInput } from "../../atoms/BalanceInputNSM";
import { ContainerLiquidityNSM } from "../../atoms/ContainerLiquidityNSM";
import { ContainerLiquidityPoolList } from "../../atoms/ContainerLiquidityPoolList";
import { UpdatableCircle } from "../../atoms/UpdatableCircle";
import { ProgressBarProviderContext } from "../../../contexts/ProgressBarContext";
import { LiquidityRemovingWithInputRangeModule } from "../LiquidityRemovingWithInputRangeModule";
import { LiquidityProviderContext } from "../../../contexts/LiquidityContext";
import { globalStore } from "../../../store/store";
import isCSPRValid from "../../../hooks/isCSPRValid";
import styled from "styled-components";
import { initialTokenState, RAW_TOKENS, TokenActions, TokenReducer } from "../../../reducers/TokenReducers";
import AddTokenModal from "../SwapModule/AddTokenModal";
import ReactLoading from 'react-loading';
import { device } from "../../../contexts/ThemeContext/themes";

const NETWORK_NAME = process.env.REACT_APP_IS_TESTNET
  ? Network.CASPER_TESTNET
  : Network.CASPER_MAINNET;

enum tokenType {
  tokenA = "tokenA",
  tokenB = "tokenB",
}
const WrapperRow = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: black;
`;
const WrapperRowInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
  gap: 5px;
  border-radius: 16px;
  padding: 0px 10px;
  background-color: #1B232E;
  opacity: 1;
`;
const TokenLogoCustom = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;

  span {
    font-size: 9px;
    color: #fff;
  }
`
const LiquidityButtonStyle = styled.button<any>`
  color: ${(props) =>
    props.enabled
      ? '#fff'
      : '#415269'};
  background-color: ${(props) =>
    props.enabled
      ? '#9A0D2F'
      : '#1B232E'};
  padding: .7rem 1.2rem;
  border: none;
  font-family: 'Montserrat';
  font-size: 14px;
  display: grid;
  place-items: center;
  &:hover {
    cursor: pointer;
  }
  &:active {
    color: ${(props) =>
      props.enabled
        ? props.theme.thirdBackgroundColor
        : props.theme.NewGrayColor};
    background-color: ${(props) =>
      props.enabled
        ? props.theme.secondBackgroundColor
        : props.theme.NewGreyColor};
  }

  @media ${device.desktop} {
    width: 8vw;
    height: 4.6vh;
  }
`;
const LiquidityNewModule = () => {
  const {
    tokenState,
    pairState,
    onConnectWallet,
    onSelectFirstToken,
    onSelectSecondToken,
    onSwitchTokens,
    tokens,
    firstTokenSelected,
    secondTokenSelected,
    isConnected,
    onIncreaseAllow,
    getPoolList,
    gasPriceSelectedForLiquidity,
    refreshAll,
    calculateUSDtokens,
    findReservesBySymbols,
  } = useContext(ConfigProviderContext);

  const {
    isRemovingPopupOpen,
    setRemovingPopup,
    onAddLiquidity,
    getLiquidityDetails,
  } = useContext(LiquidityProviderContext);
  const { progressBar } = useContext(ProgressBarProviderContext);

  const userPairData = Object.values(pairState);

  const [amountSwapTokenA, amountSwapTokenASetter] = useState<any>(0);
  const [amountSwapTokenB, amountSwapTokenBSetter] = useState<any>(0);
  const [excludedA, setExcludedA] = useState<string[]>([
    secondTokenSelected.symbolPair,
  ]);
  const [excludedB, setExcludedB] = useState<string[]>([
    firstTokenSelected.symbolPair,
  ]);
  const [feeToPay, feeToPaySetter] = useState<any>(0.03);
  const [exchangeRateA, exchangeRateASetter] = useState<any>(0);
  const [exchangeRateB, exchangeRateBSetter] = useState<any>(0);

  const [totalLiquidity, setTotalLiquidity] = useState("0");
  const [isOpenedRemoving, setOpenedRemoving] = useState(isRemovingPopupOpen);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentFReserve, setFirstReserve] = useState(0);
  const [currentSReserve, setSecondReserve] = useState(0);

  const [lastChanged, setLastChanged] = useState("");
  const [valueAUSD, setValueAUSD] = useState("0");
  const [valueBUSD, setValueBUSD] = useState("0");
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [isPairCreated, isPairCreatedSetter] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [gasFee, gasFeeSetter] = useState<number>(gasPriceSelectedForLiquidity);
  const [newToken, setNewToken] = useState<Token>()
  const [addModal, setAddModal] = useState<boolean>(false)
  const { slippageTolerance, updateSlippageTolerance } = globalStore();
  const {
    disableButton: disableButtonValid,
    setDisableButton,
    handleValidate,
    showNotification,
    dismissNotification,
  } = isCSPRValid();

  const [tokenState1, tokenDispatch] = useReducer(
    TokenReducer,
    initialTokenState
  );

  useEffect(() => {
    // console.log('tokens: ', tokens)
    // console.log('exclude: A', excludedA)
    // console.log('excluded B: ', excludedB)
    const t0 = searchParams.get("token0");
    const t1 = searchParams.get("token1");
    if (t0) {
      onSelectFirstToken(tokens[t0]);
      onSelectSecondToken(tokens[t1]);
    }

    if (isRemovingPopupOpen) {
      setOpenedRemoving(true);
      setRemovingPopup(false);
    }

    updateLiquidityDetail(
      firstTokenSelected,
      secondTokenSelected,
      amountSwapTokenA,
      lastChanged == "A" ? firstTokenSelected : secondTokenSelected
    );
  }, [isConnected, pairState]);

  useEffect(() => {
    if (!isConnected) {
      // TODO - Investigate why we have the amountSwapTokenA or amountSwapTokenB with NAN value instead of zeros
      resetAll();
    }
    progressBar(async () => {
      //lastChanged == 'A' ? await changeTokenA(amountSwapTokenA) : await changeTokenB(amountSwapTokenB)
      await refreshAll();
    });
  }, [isConnected]);

  useEffect(() => {
    const fn = async () => {
      let tSymbol = "";
      const includes: Record<string, boolean> = {};
      const pairs = Object.values(pairState);
      const tokens = tokenState.tokens;
      for (const pair of pairs) {
        if (
          pair.token0Symbol === firstTokenSelected.symbol ||
          pair.token0Symbol === firstTokenSelected.symbolPair
        ) {
          includes[pair.token1Symbol] = true;
          if (!tSymbol) {
            tSymbol = pair.token1Symbol;
          }
        }
        if (
          pair.token1Symbol === firstTokenSelected.symbol ||
          pair.token1Symbol === firstTokenSelected.symbolPair
        ) {
          includes[pair.token0Symbol] = true;
          if (!tSymbol) {
            tSymbol = pair.token0Symbol;
          }
        }
      }

      if (includes["WCSPR"]) {
        includes["CSPR"] = true;
      }

      if (tSymbol && !includes[secondTokenSelected.symbol]) {
        const symbol = tSymbol === "WCSPR" ? "CSPR" : tSymbol;
        selectAndCloseTokenA(firstTokenSelected);
        selectAndCloseTokenB(tokens[symbol]);
      } else {
        selectAndCloseTokenA(firstTokenSelected);
        selectAndCloseTokenB(secondTokenSelected);
      }
    };

    fn();
  }, []);

  const calculateUSDValues = (amountA, amountB) => {
    const [usdA, usdB] = calculateUSDtokens(
      firstTokenSelected.symbolPair,
      secondTokenSelected.symbolPair,
      amountA,
      amountB
    );

    setValueAUSD(isNaN(parseFloat(usdA)) ? "0.00" : usdA);
    setValueBUSD(isNaN(parseFloat(usdB)) ? "0.00" : usdB);
  };

  async function onConnect() {
    onConnectWallet();
  }

  function onSwitchTokensHandler() {
    onSwitchTokens();

    exchangeRateASetter(exchangeRateB);
    exchangeRateBSetter(exchangeRateA);
    amountSwapTokenASetter(amountSwapTokenB);
    amountSwapTokenBSetter(amountSwapTokenA);

    setFirstReserve(currentSReserve);
    setSecondReserve(currentFReserve);

    setValueAUSD(valueBUSD);
    setValueBUSD(valueAUSD);

    setExcludedA(excludedB);
    setExcludedB(excludedA);
  }

  const calculateTotalLP = (token0, token1) => {
    const filter = getPoolList().filter(
      (r) => r.token0Symbol === token0 && r.token1Symbol === token1
    );
    if (filter.length > 0) {
      const userLP = new BigNumber(filter[0].totalSupply).toFixed(
        filter[0].decimals
      );
      return userLP;
    }

    const filter2 = getPoolList().filter(
      (r) => r.token1Symbol === token0 && r.token0Symbol === token1
    );
    if (filter2.length > 0) {
      const userLP = new BigNumber(filter2[0].totalSupply).toFixed(
        filter2[0].decimals
      );
      return userLP;
    }
  };

  function resetAll() {
    amountSwapTokenASetter(0);
    amountSwapTokenBSetter(0);
  }

  async function updateLiquidityDetail(
    tokenA,
    tokenB,
    value = amountSwapTokenA,
    token = firstTokenSelected
  ) {
    const { reserve0, reserve1 } = findReservesBySymbols(
      tokenA,
      tokenB
    );

    if (reserve0.toString() == '0' && reserve1.toString() == '0') {
      return token == tokenA ? amountSwapTokenB : amountSwapTokenA
    }

    console.log('updateLiquidityDetail', reserve0.toString(), reserve1.toString())

    const getLiquidityDetailP = getLiquidityDetails(
      tokenA,
      tokenB,
      reserve0,
      reserve1,
      value,
      token,
      slippageTolerance,
      feeToPay
    );
    const ps = [getLiquidityDetailP];

    const [getLiquidityDetailResponse] = await Promise.all(ps);

    const {
      tokensToTransfer,
      exchangeRateA,
      exchangeRateB,
      firstReserve,
      secondReserve,
    } = getLiquidityDetailResponse;

    exchangeRateASetter(exchangeRateA);
    exchangeRateBSetter(exchangeRateB);
    if (token === tokenA) {
      setFirstReserve(firstReserve);
      setSecondReserve(secondReserve);
    } else {
      setFirstReserve(secondReserve);
      setSecondReserve(firstReserve);
    }

    calculateUSDValues(value, tokensToTransfer);
    return tokensToTransfer;
  }

  async function requestIncreaseAllowance(amount, contractHash) {
    await onIncreaseAllow(amount, contractHash);
    await updateLiquidityDetail(firstTokenSelected, secondTokenSelected);
  }

  async function changeTokenA(value: string) {
    let filteredValue = parseFloat(value);
    if (isNaN(filteredValue)) {
      filteredValue = 0;
    } else if (filteredValue < 0) {
      filteredValue = Math.abs(filteredValue);
    }

    setLastChanged("A");

    amountSwapTokenASetter(filteredValue);
    const minTokenToReceive = await updateLiquidityDetail(
      firstTokenSelected,
      secondTokenSelected,
      filteredValue,
      firstTokenSelected
    );
    if (minTokenToReceive != '0') {
      amountSwapTokenBSetter(minTokenToReceive);
    }

    const totalLP = calculateTotalLP(
      firstTokenSelected.symbolPair,
      secondTokenSelected.symbolPair
    );
    setTotalLiquidity(totalLP);
  }

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    handleValidate(
      parseFloat(e.target.value),
      parseFloat(firstTokenSelected.amount),
      gasFee || 0,
      firstTokenSelected.symbol,
      tokens["CSPR"].amount
    );
    changeTokenA(e.target.value);
  };

  async function changeTokenB(value: string) {
    let filteredValue = parseFloat(value);
    if (isNaN(filteredValue)) {
      filteredValue = 0;
    } else if (filteredValue < 0) {
      filteredValue = Math.abs(filteredValue);
    }

    setLastChanged("B");

    amountSwapTokenBSetter(filteredValue);
    const minTokenToReceive = await updateLiquidityDetail(
      firstTokenSelected,
      secondTokenSelected,
      filteredValue,
      secondTokenSelected
    );
    if (minTokenToReceive != '0') {
      amountSwapTokenASetter(minTokenToReceive);
    }

    const totalLP = calculateTotalLP(
      firstTokenSelected.symbolPair,
      secondTokenSelected.symbolPair
    );
    setTotalLiquidity(totalLP);
  }

  const handleChangeB = async (e) => {
    changeTokenB(e.target.value);
    const minTokenToReceive = await updateLiquidityDetail(
      firstTokenSelected,
      secondTokenSelected,
      parseFloat(e.target.value),
      secondTokenSelected
    );
    handleValidate(
      parseFloat(minTokenToReceive),
      parseFloat(firstTokenSelected.amount),
      gasFee || 0,
      firstTokenSelected.symbol,
      tokens["CSPR"].amount
    );
  };

  const handleChangeGasFee = (value) => {
    const gasFeeValue = value ? parseFloat(value) : 0;
    gasFeeSetter(value);
    handleValidate(
      currentValue,
      parseFloat(firstTokenSelected.amount),
      gasFeeValue,
      firstTokenSelected.symbol,
      tokens["CSPR"].amount
    );
  };

  const [searchModalA, searchModalASetter] = useState(false);
  async function selectAndCloseTokenA(token: Token): Promise<void> {
    if(checkTokenExist(token)) {
      if (token.symbol === secondTokenSelected.symbol) {
        return;
      }
  
      const includes: Record<string, boolean> = {};
      const pairs = Object.values(pairState);
      for (const pair of pairs) {
        if (
          pair.token0Symbol === token.symbol ||
          pair.token0Symbol === token.symbolPair
        ) {
          includes[pair.token1Symbol] = true;
        }
        if (
          pair.token1Symbol === token.symbol ||
          pair.token1Symbol === token.symbolPair
        ) {
          includes[pair.token0Symbol] = true;
        }
      }
  
      if (includes["WCSPR"]) {
        includes["CSPR"] = true;
      }
  
      const tokens = Object.values(tokenState.tokens);
      const excludes = tokens.reduce((acc: string[], v: Token): string[] => {
        if (!includes[v.symbol]) {
          acc.push(v.symbol);
        }
        return acc;
      }, []);
  
      // console.log('excludes', includes, excludes)
  
      onSelectFirstToken(token);
      setExcludedB(excludes);
      searchModalASetter(false);
  
      const minTokenToReceive = await updateLiquidityDetail(
        token,
        secondTokenSelected,
        amountSwapTokenA,
        token
      );
      amountSwapTokenBSetter(minTokenToReceive);
    } else {
      setNewToken(token)
      searchModalASetter(false)
      searchModalBSetter(false)
      setAddModal(true)
    }
    
  }

  const [searchModalB, searchModalBSetter] = useState(false);
  async function selectAndCloseTokenB(token: Token): Promise<void> {
    if (checkTokenExist(token)) {
      if (token.symbol === firstTokenSelected.symbol) {
        return;
      }

      const includes: Record<string, boolean> = {};
      const pairs = Object.values(pairState);
      for (const pair of pairs) {
        if (
          pair.token0Symbol === token.symbol ||
          pair.token0Symbol === token.symbolPair
        ) {
          includes[pair.token1Symbol] = true;
        }
        if (
          pair.token1Symbol === token.symbol ||
          pair.token1Symbol === token.symbolPair
        ) {
          includes[pair.token0Symbol] = true;
        }
      }

      if (includes["WCSPR"]) {
        includes["CSPR"] = true;
      }

      const tokens = Object.values(tokenState.tokens);
      const excludes = tokens.reduce((acc: string[], v: Token): string[] => {
        if (!includes[v.symbol]) {
          acc.push(v.symbol);
        }
        return acc;
      }, []);

      // console.log('excludes', includes, excludes)

      onSelectSecondToken(token);
      setExcludedA(excludes);
      searchModalBSetter(false);

      const minTokenToReceive = await updateLiquidityDetail(
        firstTokenSelected,
        token,
        amountSwapTokenB,
        token
      );
      amountSwapTokenASetter(minTokenToReceive);
    } else {
      setNewToken(token)
      searchModalASetter(false)
      searchModalBSetter(false)
      setAddModal(true)
    }

  }

  async function validateToken(amount, token) {
    if (token === tokenType.tokenA) {
      if (parseFloat(firstTokenSelected.amount) > gasFee) {
        amount = parseFloat(firstTokenSelected.amount) - gasFee;
        setCurrentValue(amount);
        dismissNotification();
        setDisableButton(false);
      } else {
        showNotification();
        setCurrentValue(amount);
      }
    } else if (token == tokenType.tokenB) {
      const minTokenToReceive = await updateLiquidityDetail(
        firstTokenSelected,
        secondTokenSelected,
        parseFloat(amount),
        secondTokenSelected
      );
      setCurrentValue(parseFloat(minTokenToReceive));
      if (
        parseFloat(minTokenToReceive) >
        parseFloat(firstTokenSelected.amount) - gasFee
      ) {
        showNotification();
      }
    }

    return amount;
  }

  async function makeHalf(amount, setter, token) {
    amount = await validateToken(amount, token);
    setter(amount / 2);
  }
  async function makeMax(amount, setter, token) {
    amount = await validateToken(amount, token);
    setter(amount);
  }

  async function onLiquidity() {
    // add loading for check pair
    setLoading(true)
    const bool = await pairCheck()
    setLoading(false)
    console.log('onLiquidity', amountSwapTokenA, amountSwapTokenB)
    console.log('created', bool)
    //console.log('is created', isPairCreated)
    await onAddLiquidity(
      amountSwapTokenA,
      amountSwapTokenB,
      slippageTolerance,
      bool ? 32 : 500
    );
    resetAll();
  }

  const disableButton = (amount0, amount1) => {
    if (isNaN(amount0)) {
      return true;
    }
    if (isNaN(amount1)) {
      return true;
    }
    if (!isConnected) {
      return true;
    }
    if (
      parseFloat(amount0) <= 0 ||
      parseFloat(amount0) > parseFloat(firstTokenSelected.amount.toString())
    ) {
      return true;
    }
    if (
      parseFloat(amount1) <= 0 ||
      parseFloat(amount1) > parseFloat(secondTokenSelected.amount.toString())
    ) {
      return true;
    }
  };

  const amountA = isNaN(amountSwapTokenA) ? 0 : amountSwapTokenA;
  const amountB = isNaN(amountSwapTokenB) ? 0 : amountSwapTokenB;
  const freeAllowanceA = new BigNumber(firstTokenSelected.allowance || 0)
    .minus(new BigNumber(amountA || 0))
    .toNumber();

  const isApprovedA =
    firstTokenSelected.symbol === "CSPR" ||
    (firstTokenSelected.symbol !== "CSPR" && freeAllowanceA >= 0);

  const freeAllowanceB = new BigNumber(secondTokenSelected.allowance || 0)
    .minus(new BigNumber(amountB || 0))
    .toNumber();

  const isApprovedB =
    secondTokenSelected.symbol === "CSPR" ||
    (secondTokenSelected.symbol !== "CSPR" && freeAllowanceB >= 0);

  const userPairDataNonZero = userPairData.filter(
    (v) => parseFloat(v.balance) > 0
  );

  const refreshPrices = async () => {
    await refreshAll();
    await changeTokenA(amountSwapTokenA);
  };

  const checkTokenExist = (token: Token) => {
    console.log('token in check token exist: ', token)
    const _filteredTokens = Object.entries(tokens).map((v, k) => v[1])
    const getItemFromStoreage = JSON.parse(localStorage.getItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`));
    const allToken = getItemFromStoreage ? _filteredTokens.concat(getItemFromStoreage) : _filteredTokens
    console.log('all token: ', allToken)
    let check = false
    allToken?.forEach(t => {
      if (t.name === token.name) {
        check = true
      }
    })
    return check
  }

  const pairCheck = async (): Promise<boolean> => {
    const token0 = CLValueParsers.toBytes(
      CLValueBuilder.key(
        new CLByteArray(
          Uint8Array.from(
            Buffer.from(firstTokenSelected.packageHash == '' ? RAW_TOKENS.WCSPR.packageHash.substring(5) : firstTokenSelected.packageHash.substring(5), 'hex')
          )
        ),
      )
    ).unwrap()
    console.log('firstTokenSelected: ', firstTokenSelected)
    console.log('secondTokenSelected: ', secondTokenSelected)

    console.log('token 0: ', encodeBase16(token0))
    const token1 = CLValueParsers.toBytes(
      CLValueBuilder.key(
        new CLByteArray(
          Uint8Array.from(
            Buffer.from(secondTokenSelected.packageHash == '' ? RAW_TOKENS.WCSPR.packageHash.substring(5) : secondTokenSelected.packageHash.substring(5), 'hex')
          )
        ),
      )
    ).unwrap()
    console.log('token 1: ', encodeBase16(token1))
    const finalBytes = new Uint8Array(token0.length + token1.length);
    finalBytes.set(token0);
    finalBytes.set(token1, token0.length);

    const blaked = blake2b(finalBytes, null, 32);

    const dictKey = encodeBase16(blaked);
    console.log('dictKey: ', dictKey)

    // let allowances = new BigNumber(0);

    // const contractClient = await Contract.createInstance(CONTRACT_FACTORY_HASH, NODE_ADDRESS, SUPPORTED_NETWORKS.networkKey, factoryJson)

    // console.log('contract client: ',contractClient)


    try {
      const casperClient = new CasperClient(NETWORK_NAME, NODE_ADDRESS);
      const stateRootHash = await casperClient.getStateRootHash()

      const apiClient = new APIClient(casperClient);
      const contractFactoryHash = 'hash-' + CONTRACT_FACTORY_HASH
      const pair = await apiClient.getDictionaryItem(contractFactoryHash, 'pairs', dictKey, stateRootHash)

      return true
    } catch (error) {
      if (error.toString().includes('ValueNotFound(')) {
        isPairCreatedSetter(false)
        return false
      }
      console.log("check pair ", error)
      isPairCreatedSetter(false)
      return false
    }
    // try {
    //   allowances = (
    //     (await contractClient.queryContractDictionary(
    //       'pairs',
    //       dictKey
    //     )) as CLU256
    //   ).value();
    // } catch (error) {
    //   if (
    //     error instanceof Error &&
    //     error.toString().startsWith('Error: state query failed: ValueNotFound')
    //   ) {
    //     console.warn(`Not found allowances`);
    //   } else throw error;
    // }
    // return allowances;
  }

  const AddToken = (token: Token) => {
    console.log('token add: ', token)
    const getItemFromStoreage = JSON.parse(localStorage.getItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`));
    if (getItemFromStoreage) {
      const getItemFromStorageCopy = [...getItemFromStoreage]
      getItemFromStorageCopy.push(token)
      localStorage.setItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`, JSON.stringify(getItemFromStorageCopy))
    } else {
      const listToken = []
      listToken.push(token)
      localStorage.setItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`, JSON.stringify(listToken))
    }

    const tokenUpdate: Record<string, Token> = {}
    tokenUpdate[token.symbol] = token

    tokenDispatch({
      type: TokenActions.UPDATE_TOKENS,
      payload: {
        tokens: tokenUpdate
      }
    })
    const _listTokens = tokens
    _listTokens[`${token.symbol}`] = token
    //setListTokens(_listTokens)
    setAddModal(false)
  }

  const handleMax = () => {
    console.log('amount', firstTokenSelected.amount)
    changeTokenA(firstTokenSelected.amount)
  } 

  return (
    <ContainerLiquidityNSM>
      <ContainerSwapActionsNSM>
        <NewSwapContainerNSM>
          <TokenSelectionNSM>
            <NewTokenDetailActionsNSM>
              <ActionContainerNSM>
                {/* <ButtonHalfMaxContainer>
                  <ButtonHalfMax
                    onClick={() => {
                      makeHalf(
                        firstTokenSelected.amount,
                        changeTokenA,
                        tokenType.tokenA
                      );
                    }}
                  >
                    Half
                  </ButtonHalfMax>
                  <ButtonHalfMax
                    onClick={() => {
                      makeMax(
                        firstTokenSelected.amount,
                        changeTokenA,
                        tokenType.tokenA
                      );
                    }}
                  >
                    Max
                  </ButtonHalfMax>
                </ButtonHalfMaxContainer> */}
                <BalanceInputContainerNSM>
                  <BalanceInputItem1NSM>
                    <BalanceInputNSM
                      min={0}
                      onChange={handleChange}
                      type="number"
                      name=""
                      id=""
                      value={amountSwapTokenA}
                    />
                    <WrapperRowInput onClick={() => searchModalASetter(true)}>
                    {firstTokenSelected?.logoURI ? (
                        <NewTokenDetailItems2NSM
                        src={firstTokenSelected.logoURI}
                      />
                      ) : (
                        <TokenLogoCustom>
                          <span>{firstTokenSelected.symbol}</span>
                        </TokenLogoCustom>
                      )}
                      <NewTokenDetailItems3NSM
                        handleClick={() => searchModalASetter(true)}
                      >
                        {firstTokenSelected.symbol}
                      </NewTokenDetailItems3NSM>
                      <NewTokenDetailItems4NSM>
                        <ArrowContainerNSM>
                          <FlechaIcon />
                        </ArrowContainerNSM>
                      </NewTokenDetailItems4NSM>
                    </WrapperRowInput>
                  </BalanceInputItem1NSM>
                  <WrapperRow>
                    <BalanceInputItem2NSM>
                      <p>$ {valueAUSD}</p>
                    </BalanceInputItem2NSM>
                    <NewBalanceSpaceNSM>
                          Balance:{" "}
                          {firstTokenSelected.amount
                            ? numberWithCommas(convertAllFormatsToUIFixedString(
                                firstTokenSelected.amount,
                                new BigNumber(firstTokenSelected.amount).lt(
                                  1
                                )
                                  ? firstTokenSelected.decimals
                                  : 3
                              ))
                            : "--"}{' '}
                          <span style={{color: '#3483FF', cursor: 'pointer', fontWeight: '600'}} onClick={() => handleMax()}>
                            MAX
                          </span>
                        </NewBalanceSpaceNSM>
                  </WrapperRow>
                </BalanceInputContainerNSM>
              </ActionContainerNSM>
            </NewTokenDetailActionsNSM>
          </TokenSelectionNSM>
        </NewSwapContainerNSM>
        <IconPlaceNSM>
          <SwitchSwap onClick={onSwitchTokensHandler} />

          {/* <UpdatableCircle strokeWidth={12} handler={refreshPrices} /> */}
        </IconPlaceNSM>
        {/*TODO: we need create another component with this background <NewSwapContainerNSM style={{backgroundColor: "white"}}>*/}
        <NewSwapContainerNSM>
          <TokenSelectionNSM>
            <NewTokenDetailActionsNSM>
              <ActionContainerNSM>
                {/* <ButtonHalfMaxContainer>
                  <ButtonHalfMax
                    onClick={() => {
                      makeHalf(
                        secondTokenSelected.amount,
                        changeTokenB,
                        tokenType.tokenB
                      );
                    }}
                  >
                    Half
                  </ButtonHalfMax>
                  <ButtonHalfMax
                    onClick={() => {
                      makeMax(
                        secondTokenSelected.amount,
                        changeTokenB,
                        tokenType.tokenB
                      );
                    }}
                  >
                    Max
                  </ButtonHalfMax>
                </ButtonHalfMaxContainer> */}
                <BalanceInputContainerNSM>
                  <BalanceInputItem1NSM>
                    <BalanceInput
                      min={0}
                      onChange={handleChangeB}
                      type="number"
                      name=""
                      id=""
                      value={amountSwapTokenB}
                    />
                    <WrapperRowInput onClick={() => searchModalBSetter(true)}>
                      {secondTokenSelected?.logoURI ? (
                        <NewTokenDetailItems2NSM
                        src={secondTokenSelected.logoURI}
                      />
                      ) : (
                        <TokenLogoCustom>
                          <span>{secondTokenSelected.symbol}</span>
                        </TokenLogoCustom>
                      )}
                      
                      <NewTokenDetailItems3NSM>
                        {secondTokenSelected.symbol}
                      </NewTokenDetailItems3NSM>
                      <NewTokenDetailItems4NSM>
                        <ArrowContainerNSM>
                          <FlechaIcon />
                        </ArrowContainerNSM>
                      </NewTokenDetailItems4NSM>
                    </WrapperRowInput>
                  </BalanceInputItem1NSM>
                  <WrapperRow>
                    <BalanceInputItem2NSM>
                      <p>$ {valueBUSD}</p>
                    </BalanceInputItem2NSM>
                    <NewBalanceSpaceNSM>
                          Balance:{" "}
                          {secondTokenSelected.amount
                            ? numberWithCommas(convertAllFormatsToUIFixedString(
                                secondTokenSelected.amount,
                                new BigNumber(secondTokenSelected.amount).lt(
                                  1
                                )
                                  ? secondTokenSelected.decimals
                                  : 3
                              ))
                            : "--"}
                        </NewBalanceSpaceNSM>
                  </WrapperRow>
                </BalanceInputContainerNSM>
              </ActionContainerNSM>
            </NewTokenDetailActionsNSM>
          </TokenSelectionNSM>
        </NewSwapContainerNSM>
        <SwapDetailsNSM>
          <ExchangeRateBox
            tokenASymbol={firstTokenSelected.symbol}
            tokenBSymbol={secondTokenSelected.symbol}
            exchangeRateA={exchangeRateA}
            exchangeRateB={exchangeRateB}
          />
        </SwapDetailsNSM>
        {amountSwapTokenA > 0 && (
          <LPDetail
            firstSymbolToken={firstTokenSelected.symbol}
            secondSymbolToken={secondTokenSelected.symbol}
            firstTokenDecimals={firstTokenSelected.decimals}
            secondTokenDecimals={secondTokenSelected.decimals}
            secondTokenAmount={amountSwapTokenB}
            liquidity={parseFloat(totalLiquidity)}
            firstReserve={currentFReserve / 10 ** firstTokenSelected.decimals}
            secondReserve={currentSReserve / 10 ** secondTokenSelected.decimals}
            gasFee={gasFee}
            gasFeeSetter={handleChangeGasFee}
            gasFeeEnabled={true}
            slippage={slippageTolerance}
            slippageEnabled={true}
            slippageSetter={updateSlippageTolerance}
          />
        )}

        <ButtonSpaceNSM>
          {!isConnected && (
            <NewSwapButtonWidth100
              content="Connect to Wallet"
              handler={async () => {
                onConnect();
              }}
            />
          )}
          {/* {!isApprovedA && isConnected && (
            <NewSwapButtonWidth100
              disabled={
                disableButton(amountSwapTokenA, amountSwapTokenB) ||
                disableButtonValid
              }
              content={`Approve ${-freeAllowanceA} ${
                firstTokenSelected.symbol
              }`}
              handler={async () => {
                await requestIncreaseAllowance(
                  -freeAllowanceA,
                  firstTokenSelected.contractHash
                );
              }}
            />
          )}
          {!isApprovedB && isConnected && (
            <NewSwapButtonWidth100
              disabled={
                disableButton(amountSwapTokenA, amountSwapTokenB) ||
                disableButtonValid
              }
              content={`Approve ${-freeAllowanceB} ${
                secondTokenSelected.symbol
              }`}
              handler={async () => {
                await requestIncreaseAllowance(
                  -freeAllowanceB,
                  secondTokenSelected.contractHash
                );
              }}
            />
          )} */}
          {isConnected && (
            <NewSwapButtonWidth100
              disabled={
                disableButton(amountSwapTokenA, amountSwapTokenB) ||
                disableButtonValid
              }
              content={
                loading ?
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <ReactLoading type='spin' color='#fff' width={20} height={20}/>
                </div>
                : "Add liquidity"
              }
              handler={async () => {
                await onLiquidity();
              }}
            />
          )}
        </ButtonSpaceNSM>
      </ContainerSwapActionsNSM>
      {isConnected && userPairDataNonZero.length > 0 && (
        <ContainerLiquidityPoolList>
          {
            // Loop over the table rows
            userPairDataNonZero.map((row) => {
              const openPopup =
                isOpenedRemoving &&
                row.token0Symbol === firstTokenSelected.symbolPair &&
                row.token1Symbol === secondTokenSelected.symbolPair;
              return (
                // Apply the row props
                <LiquidityItem
                  key={`${row.token0Symbol}-${row.token1Symbol}`}
                  fullExpanded={openPopup}
                  firstIcon={row.token0Icon}
                  firstSymbol={row.token0Symbol}
                  firstLiquidity={
                    parseFloat(row.reserve0)
                  }
                  secondIcon={row.token1Icon}
                  secondSymbol={row.token1Symbol}
                  secondLiquidity={
                    parseFloat(row.reserve1)
                  }
                  liquidity={row.balance}
                  perLiquidity={new BigNumber(row.balance)
                    .div(row.totalSupply)
                    .times(100)
                    .toFixed(2)}
                >
                  <LiquidityRemovingWithInputRangeModule
                    isConnected={true}
                    openedPopup={openPopup}
                    firstName={row.token0Name}
                    firstHash={row.contract0}
                    firstSymbol={row.token0Symbol}
                    firstLiquidity={row.reserve0}
                    firstDecimals={row.token0Decimals}
                    firstPrice={row.token0Price}
                    secondName={row.token1Name}
                    secondHash={row.contract1}
                    secondSymbol={row.token1Symbol}
                    secondLiquidity={row.reserve1}
                    secondDecimals={row.token1Decimals}
                    secondPrice={row.token1Price}
                    contractHash={row.contractHash}
                    packageHash={row.packageHash}
                    liquidity={row.balance}
                    allowance={row.allowance}
                    firstIcon={row.token0Icon}
                    secondIcon={row.token1Icon}
                    decimals={row.decimals}
                  >
                    <LiquidityButtonStyle enabled={true}>
                      Remove
                    </LiquidityButtonStyle>
                  </LiquidityRemovingWithInputRangeModule>
                </LiquidityItem>
              );
            })
          }
        </ContainerLiquidityPoolList>
      )}
      {searchModalB && (
        <FloatMenu
          show={searchModalB}
          excludedSymbols={[secondTokenSelected.symbol]}
          tokens={tokens}
          onSelectToken={selectAndCloseTokenB}
          onClick={() => {
            searchModalBSetter(false);
          }}
        />
      )}
      {searchModalA && (
        <FloatMenu
          show={searchModalA}
          excludedSymbols={[firstTokenSelected.symbol]}
          tokens={tokens}
          onSelectToken={selectAndCloseTokenA}
          onClick={() => {
            searchModalASetter(false);
          }}
        />
      )}
      {addModal && (
        <AddTokenModal 
          token={newToken}
          onAddToken={() => AddToken(newToken)}
          onCancel={() => setAddModal(false)} 
        />
      )}
    </ContainerLiquidityNSM>
  );
};

export default LiquidityNewModule;
function tokenDispatch(arg0: { type: any; payload: { tokens: Record<string, Token>; }; }) {
  throw new Error("Function not implemented.");
}

