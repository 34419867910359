import styled from "styled-components";

export const WalletSelectionStyled = styled.div<any>`
  display: flex;
  justify-content: normal;
  align-items: center;
  padding: 0.5rem;
  gap: 10px;
  background-color: transparent;
  border: 1px solid #2A3340;
  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.isSelected &&
    `
    color: ${props.theme.PrimaryColor};
    background: ${props.theme.NewPurpleColor};
    `}
`;
