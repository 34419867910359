import React from 'react'
import styled from 'styled-components'
const SearchInputStyle = styled.input`
    all:unset;
    width: 100%;
    color: #fff;
    margin-left: 10px;
    font-family: Montserrat;
    
    ::placeholder {
      color: #2A3340;
    }
`
export const SearchInputFM = ({placeholder,onChange}) => {
  return (
    <SearchInputStyle type="text" name="" id="" placeholder={placeholder} onChange={onChange}/>
  )
}
