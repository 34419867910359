import { useNavigate } from 'react-router-dom'
import NewLayout from '../../../layout/NewLayout'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components/macro'

import { HomeTemplate } from '../../templates/HomeTemplate'
import { usUS } from '../../../i18n'
import SwapAndBuyImg from '../../../assets/image/SwapAndBuy.png'
import Swap from '../../../assets/image/SwapToken.png'
import Trade from '../../../assets/image/Trade.png'
import BuyCrypto from '../../../assets/image/Buy.png'
import Earn from '../../../assets/image/Earn.png'
import BuildApp from '../../../assets/image/BuildApp.png'

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0rem;
  gap: 1.5rem;
`

const TextLarge = styled.p`
  font-size: 59px;
  font-Weight: 600;
  color: #AD203E;
  max-width: 700px;
  text-align: center;
`

const TextMd = styled.p`
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;
  color: #161F2F;
`

const InfoWrapper = styled.div`
  width: 100%;
  margin: 2rem 0.5rem;

  @media (min-width: 768px) {
    width: 95%;
  }

  @media (min-width: 992px) {
    width: 85%;
  }

  @media (min-width: 1400px) {
    width: 80%;
  }
`

const Divider = styled.div`
  height: 2px;
  width: 100%;
  margin-top: 3rem;
  background-color: #4592AE;
`

const BoxWrapper = styled.div`
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 2rem;
`

const BoxTitle = styled.p`
  font-size: 26px;
  font-weight: 500;
  line-height: 31.69px;
  color: #000000;
`

const BoxContent = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #525252;
  margin: 3rem 0 1.5rem;
`

const BoxLink = styled.a`
  font-size: 18px;
  font-weight: 600;
  color: #4592AE;
  text-decoration: none;
`

const BoxWrapper1 = styled.div`
  background: #D4EBF4;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 2rem;
`

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4592AE;
  border: none;
  border-radius: 12px;
  padding: 0.7rem 2rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #4592AE;
    color: #fff;
  }

  &:disabled {
    opacity: 0.6;
  }
`
const ButtonPrimary = styled(StyledButton)`
  background: #4592AE;
  border: 1px solid #fff;
  transition: all 0.3s ease;

  span {
    color: #fff;
  }

  &:hover {
    background: #4592AE;
  }
`

export function Home() {
  const router = useNavigate()
  const { mainButton } = usUS

  return (
      <>
        <SectionWrapper>
          <div style={{ cursor: 'pointer'}} onClick={() => router('/swap')}>
            <img src={SwapAndBuyImg}/>
          </div>
          <TextLarge>Trade crypto with confidence</TextLarge>
          <TextMd>Buy, sell, and explore tokens</TextMd>
          <StyledButton onClick={() => router('/swap')}>Get Started</StyledButton>
        </SectionWrapper>
        <div className='d-flex justify-content-center' style={{backgroundColor: '#161F2F'}}>
          <InfoWrapper>
            <Row>
              <Col lg={4} md={12}>
                <BoxWrapper1>
                  <div className="d-flex justify-content-between align-items-start">
                    <BoxTitle>Buy crypto</BoxTitle>
                    <img src={BuyCrypto}/>
                  </div>
                  <BoxContent>Buy crypto with your credit card or bank account at the best rates.</BoxContent>
                  {/* <BoxLink href='#'>Buy now</BoxLink> */}
                </BoxWrapper1>
              </Col>
              <Col lg={4} md={12}>
                <BoxWrapper1>
                  <div className="d-flex justify-content-between align-items-start">
                    <BoxTitle>Earn</BoxTitle>
                    <img src={Earn} />
                  </div>
                  <BoxContent>Provide liquidity to pools on ___ and earn fees on swaps.</BoxContent>
                </BoxWrapper1>
              </Col>
              <Col lg={4} md={12}>
                <BoxWrapper1>
                  <div className="d-flex justify-content-between align-items-start">
                    <BoxTitle>Swap tokens</BoxTitle>
                    <img src={Swap} width={45} height={45}/>
                  </div>
                  <BoxContent>Build apps and tools on the largest DeFi protocol on Casper.</BoxContent>
                  {/* <BoxLink href='#'>Developer docs</BoxLink> */}
                </BoxWrapper1>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <BoxWrapper style={{backgroundColor: '#4592AE'}}>
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <div>
                      <BoxTitle style={{color: '#fff'}}>Powered by the Uniswap Protocol</BoxTitle>
                      <BoxContent style={{color: '#fff', margin: '1rem 0 0'}}>The leading decentralized crypto trading protocol, governed by a global community.</BoxContent>
                    </div>
                    {/* <ButtonPrimary>Learn More</ButtonPrimary> */}
                  </div>
                </BoxWrapper>
              </Col>
            </Row>
            <Divider />
          </InfoWrapper>
        </div>
      </>
  )
}
