import React from "react";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { CardContainer } from "../../atoms";
import { PoolModule } from "../../organisms";

import { useNavigate } from "react-router-dom";

import {
  WrappedPool,
  WrappedPoolTitle,
  TitleBox,
  CreatePoolButton,
  WrappedHeaderPool,
  HeaderPool,
  HeaderPoolText,
  TitleBoxWrapper,
} from "./styles";
import {
  ConfigProviderContext,
  convertNumber,
} from "../../../contexts/ConfigContext";
import { POCSearch3 } from "../../POCSearch3";

export const Pools = () => {
  const navigate = useNavigate();
  const {
    poolColumns,
    getPoolList,
    tableInstance,
    setCurrentQuery,
    getTVLandVolume,
    currentQuery,
  } = React.useContext(ConfigProviderContext);

  useEffect(() => {
    console.log('pool list', getPoolList())
  }, [])

  const TVLAndVolume = getTVLandVolume();

  return (
    <Container fluid>
      <WrappedPool>
        {/* <WrappedHeaderPool>
          <HeaderPool>Liquidity Pools</HeaderPool>
          <HeaderPoolText>Earn yield trading by providing liquidity</HeaderPoolText>
        </WrappedHeaderPool> */}
        <CardContainer
          gridRow="2"
          gridColumn="1/11"
          cardTitle="Liquidity Pools"
          width="85%"
        >
          <PoolModule columns={poolColumns} data={getPoolList()} />
        </CardContainer>
      </WrappedPool>
    </Container>
  )
}