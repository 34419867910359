import store from "store2";
import { log } from "../utils";

import {
  CasperServiceByJsonRPC,
  CLPublicKey,
  DeployUtil,
  //Signer,
} from "casper-js-sdk";

import { Wallet } from "./Wallet";

import { Network, WalletName } from "./types";
import { NODE_ADDRESS } from "../../constant";

export const CASPERDASH_PUBKEY_KEY = "dash-pubk";

export enum CasperDashEvents {
  CONNECT = "casperdash:connected",
  DISCONNECT = "casperdash:disconnected",
}

/**
 * Casper Dash Wallet
 */
//@ts-ignore
export class CasperDashWallet implements Wallet {
  private _connectPromise?: Promise<string>;
  private _connectEventHandler: any;
  private _disconnectEventHandler: any;
  private _previousConnectReject: any;
  private _publicKey?: CLPublicKey;
  private _isConnected = false;
  private Signer;

  constructor(private _network: Network) {
    const pubKeyHex = store.get(CASPERDASH_PUBKEY_KEY);
    this.Signer = window.casperDashHelper;
    if (pubKeyHex) {
      try {
        this._publicKey = CLPublicKey.fromHex(pubKeyHex);
      } catch (e) {
        log.warn(
          `Casper Dash - constructor warning, could not decode cached hex: ${pubKeyHex}`
        );
      }
    }
  }

  // is the wallet connected?
  get isConnected(): boolean {
    return this._isConnected;
  }
  get signer(): any {
    return this.Signer;
  }
  // what network is the wallet connected to?
  get network(): Network {
    return this._network;
  }

  // (getter) name for identifying the wallet
  get name(): WalletName {
    return WalletName.CASPER_DASH;
  }

  // (getter) public key for connected wallet
  get publicKey(): CLPublicKey | undefined {
    return this._publicKey;
  }

  // (getter) account hash string for UI
  get publicKeyHex(): string {
    return this._publicKey?.toHex() ?? "";
  }

  // (getter) account hash for connected wallet
  get accountHash(): Uint8Array | undefined {
    return this._publicKey?.toAccountHash();
  }

  // (getter) account hash string for UI
  get accountHashString(): string {
    return this._publicKey?.toAccountHashStr() ?? "";
  }

  /**
   * Async try and connect to the current wallet
   *
   * @returns the the public key on success or throw error
   */
  async connect(): Promise<string> {
    if (this.isConnected) {
      console.log("connecting casper dash");
      return;
    }

    // If connecting just return the connect promise
    if (this._connectPromise) {
      return this._connectPromise;
    }

    try {
      // check if we're connected
      console.log("this.Signer", this.Signer);
      const signerIsConnected = await this.Signer.isConnected();
      console.log("signerIsConnected", signerIsConnected);
      // if it is connected then set connect to true

      // if (signerIsConnected) {
      //     this.Signer.disconnectFromSite();
      //   //  return;
      // }
      console.log("here");
      // finally try and connect
      this.Signer.requestConnection();

      this._connectPromise = new Promise((resolve, reject) => {
        // reject any previous promises if they exist
        if (this._previousConnectReject) {
          this._previousConnectReject();
          this._previousConnectReject = undefined;
        }

        // set the new promise reject
        this._previousConnectReject = reject;

        // if there is a connect event handler then stop listening to it
        if (this._connectEventHandler) {
          window.removeEventListener(
            CasperDashEvents.CONNECT,
            this._connectEventHandler
          );
        }

        // if there is a disconnect event handler then stop listening to it
        if (this._disconnectEventHandler) {
          window.removeEventListener(
            CasperDashEvents.DISCONNECT,
            this._disconnectEventHandler
          );
        }

        // create a new connect event handler
        this._connectEventHandler = async (msg) => {
          try {
            // get the active key
            console.log("_connectEventHandler");
            const key = await this.getActiveKey();
            this._isConnected = true;
            log.info("Casper Dash: Connected");
            this._connectEventHandler = undefined;
            this._connectPromise = undefined;

            resolve(key);
          } catch (err) {
            log.error(`Casper Dash - connect error: ${err}`);
          }
        };

        // create a new disconnect event handler
        this._disconnectEventHandler = (msg) => {
          this._isConnected = false;
          log.info("Casper Dash: Disconnected");
          this._disconnectEventHandler = undefined;
        };

        // start litening to connect
        window.addEventListener(
          CasperDashEvents.CONNECT,
          this._connectEventHandler,
          { once: true }
        );

        // start listening to disconnect
        window.addEventListener(
          CasperDashEvents.DISCONNECT,
          this._disconnectEventHandler,
          { once: true }
        );
      });
      

      // return the connect promise
      return this._connectPromise;
    } catch (err) {
      log.error(`Casper Dash - connect error: ${err}`);
      this._connectPromise = undefined;

      // rethrow error
      throw err;
    }
  }

  /**
   * Async try and read the active key
   *
   * @returns the the public key hex on success or throw error
   */
  async getActiveKey(): Promise<string> {
    // fetch the key
    const key = await this.Signer.getActivePublicKey();
    // console.log(
    //   "keyt",
    //   key,
    //   this.Signer,
    //   await window.casperDashHelper.getActivePublicKey()
    // );
    // store key
    if (key) {
      store.set(CASPERDASH_PUBKEY_KEY, key);
      this._publicKey = CLPublicKey.fromHex(key);
      return key;
    }
    return null;
    // convert key to CLPublicKey type
  }

  /**
   * Async try and disconnect from the current wallet
   *
   * @returns a promise for pass/fail
   */
  async disconnect(): Promise<void> {
    if (!this.isConnected) {
      return;
    }

    try {
      return this.Signer.disconnectFromSite();
    } catch (err) {
      log.error(
        `Casper Dash - disconnect error, probably disconnecting from a disconnected Casper Dash: ${err}`
      );

      // rethrow error
      throw err;
    }
  }

  /**
   * Sign a deploy
   *
   * @params deploy Deploy to sign
   *
   * @returns a signed deploy
   */
  async sign(deploy: DeployUtil.Deploy): Promise<DeployUtil.Deploy> {
    try {
      // Convert the deploy to a raw json
      const deployJSON = DeployUtil.deployToJson(deploy);

      // Sign the deploy with the signer
      const signedDeployJSON = await this.Signer.sign(
        deployJSON,
        this.publicKeyHex,
        this.publicKeyHex
      );

      // Convert the signed deploy json to a deploy
      return DeployUtil.deployFromJson(signedDeployJSON).unwrap();
    } catch (err) {
      log.error(`Casper Dash - signAndDeploy error: ${err}`);
      throw err;
    }
  }

  /**
   * Deploy a signed deploy
   *
   * @params deploy Signed deploy to deploy
   *
   * @returns a deploy hash
   */
  async deploy(signedDeploy: DeployUtil.Deploy): Promise<string> {
    try {
      const casperService = new CasperServiceByJsonRPC(NODE_ADDRESS);
      return (await casperService.deploy(signedDeploy)).deploy_hash;
    } catch (err) {
      log.error(`Casper Dash - signAndDeploy error: ${err}`);
      throw err;
    }
  }
}
