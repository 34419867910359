import styled from "styled-components";


export const ButtonStyleMobile = styled.div<any>`
    position: absolute;
    top: 25%;
    right: 2rem;
    align-items: center;
    color: #fff;
    background: #0F1723;
    width: 36px;
    height: 36px;
    border: 1px solid #fff;
    border-radius: 6px;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    &:hover{
        cursor: pointer;
    }
`
