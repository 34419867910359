import React from "react";
import flechaIcon from "../../../assets/newIcons/flecha.svg";

import styled from "styled-components";

const FlechaStyled = styled.img`
  cursor: pointer;
  // &:hover {
  //   background-color: rgba(150, 150, 150, 0.5);
  // }
`;

export const FlechaIcon = () => {
  return <FlechaStyled src={flechaIcon} width="15" height="15" />;
};
