import { Token } from '../../../commons/api'
import styled from 'styled-components';

import React from 'react'
import { CEP18Client } from 'casper-cep18-js-client';
import { SUPPORTED_NETWORKS } from '../../../constant';

import { ContainerFM } from '../../atoms';
import axios from 'axios';
import { device } from '../../../contexts/ThemeContext/themes';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`
const ModalTitle = styled.span`
  text-align: center;
  padding-top: 1rem;
  font-size: 20px;
`
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15rem;
`
const SectionButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`
const AddButton = styled.button`
  width: 100%;
  border-radius: 24px;
  background-color: #4592AE;
  padding: 10px 0px;
  color: #fff;
  border: 2px solid #4592AE;
`
const CancelButton = styled.button`
  width: 100%;
  border: 2px solid #4592AE;
  border-radius: 24px;
  background-color: #fff;
  color: #4592AE;
  padding: 10px 0px;
`
const ContainerCenter = styled.div`
    box-sizing: border-box;
    width: 28.2rem;
    /*padding: 2rem;*/
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 20rem;
    z-index: 2;

  @media ${device.mobileS} {
    width: 350px;  
  }
  
  @media ${device.mobileL} {
    width: 450px;
  }
  
  @media ${device.tablet} {
    width: 28.2rem;
  }
`

export interface FloatMenuProps {
  token: Token,
  onAddToken: (token: Token) => void,
  onCancel: () => void,
}

export const AddTokenModal = ({
  token,
  onAddToken,
  onCancel
}: FloatMenuProps) => {
  

  return (
    <ContainerFM>
      <ContainerCenter>
        <ModalTitle>Add new token</ModalTitle>
        <SectionWrapper>
          <SectionContent>
            <p>Name: {token.name}</p>
            <p>Symbol: {token.symbol}</p>
            <p>Decimals: {token.decimals}</p>
            <p>
              Contract hash:{' '} 
              <a href={`${SUPPORTED_NETWORKS.blockExplorerUrl}/contract/${token.contractHash}`}>
                {token.contractHash.substring(0, 10)}...{token.contractHash.substring(token.contractHash.length - 4)}
              </a>
            </p>
            <p>
              Package hash: 
              <a href={`${SUPPORTED_NETWORKS.blockExplorerUrl}/contract-package/${token.packageHash}`}>
                {token.packageHash.substring(0, 10)}...{token.packageHash.substring(token.contractHash.length - 4)}
              </a>
            </p>
            <SectionButton>
              <AddButton onClick={() => onAddToken(token)}>Add</AddButton>
              <CancelButton onClick={onCancel}>Cancel</CancelButton>
            </SectionButton>
          </SectionContent>
        </SectionWrapper>
      </ContainerCenter>
    </ContainerFM>
  )
}

export default AddTokenModal