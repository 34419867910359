import BigNumber from "bignumber.js";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  NODE_ADDRESS,
  NotificationType,
  SUPPORTED_NETWORKS,
  GET_ALL_PAIRS_API,
} from "../../constant";

import {
  initialConfigState,
  ConfigReducer,
  ConfigActions,
} from "../../reducers";
import {
  initialPairsState,
  PairsReducer,
  PairActions,
  PairData,
  PairState,
} from "../../reducers/PairsReducer";
import {
  initialTokenState,
  TokenReducer,
  TokenActions,
  TokenAction,
  TokenState,
  TOKENS
} from "../../reducers/TokenReducers";
import axios from "axios";

const NETWORK_NAME = process.env.REACT_APP_IS_TESTNET
  ? Network.CASPER_TESTNET
  : Network.CASPER_MAINNET;
//const NETWORK_NAME = Network.CASPER_TESTNET;

import {
  APIClient,
  Client as CasperClient,
  CasperSignerWallet,
  CasperWallet,
  TorusWallet,
  Network,
  Token,
  Wallet,
  convertBigNumberToUIString,
  convertUIStringToBigNumber,
  log,
  WalletName,
  CasperDashWallet,
  addTransactions,
  sleep
} from "../../commons";

import { signAndDeployAllowance } from "../../commons/deploys";
import { ConfigState } from "../../reducers/ConfigReducers";
import { Row, useAsyncDebounce } from "react-table";
import { notificationStore } from "../../store/store";
import { ERROR_BLOCKCHAIN } from "../../constant/errors";
import { getPath } from "../../commons/calculations";
import { TableInstance } from "../../components/organisms/PoolModule";
import { getPairData } from "../../commons/api/ApolloQueries";
import store from "store2";
import {
  Toast,
  ToastDimiss,
  ToastError,
  ToastLoading,
  ToastPromise,
} from "../../constant/toast";
import { toast } from "react-toastify";
import {
  CLByteArray,
  CLKeyBytesParser,
  CLValueBuilder,
  CLValueBytesParsers,
} from "casper-js-sdk";
import { blake2b } from "blakejs";
import { buffer } from "stream/consumers";
import { RAW_TOKENS } from "../../reducers/TokenReducers";

type MaybeWallet = Wallet | undefined;

interface TVLAndVolume {
  tvl: string;
  totalVolume: string;
}
declare global {
  interface Window {
    casperDashHelper: any;
  }
}
export enum CasperWalletEventTypes {
  Connected = "casper-wallet:connected",
  Disconnected = "casper-wallet:disconnected",
  TabChanged = "casper-wallet:tabChanged",
  ActiveKeyChanged = "casper-wallet:activeKeyChanged",
  Locked = "casper-wallet:locked",
  Unlocked = "casper-wallet:unlocked",
}

export interface ConfigContext {
  onConnectWallet?: (name?: WalletName, ignoreError?: boolean) => Promise<void>;
  onDisconnectWallet?: () => Promise<void>;
  configState?: ConfigState;
  tokenState?: TokenState;
  onSelectFirstToken?: (token: string | Token) => void;
  onSelectSecondToken?: (token: string | Token) => void;
  onSwitchTokens?: () => void;
  tokens?: Record<string, Token>;
  firstTokenSelected?: Token;
  secondTokenSelected?: Token;
  isConnected?: boolean;
  slippageToleranceSelected?: number;
  onIncreaseAllow?: (
    amount: number | string,
    contractHash: string,
    poolName?: string
  ) => Promise<boolean>;
  pairState?: PairState;
  confirmModal: boolean;
  linkExplorer: string;
  progressModal: boolean;
  setShowConnectionPopup: (show: boolean) => void;
  showConnectionPopup: boolean;

  // To Delete
  poolColumns?: any[];
  columns?: any[];
  getPoolList?: () => PairData[];
  getTVLandVolume: () => TVLAndVolume;
  isStaked?: boolean;
  setStaked?: (v: boolean) => void;
  filter?: (onlyStaked: boolean, row: Row<PairData>) => any;
  gasPriceSelectedForSwapping?: number;
  gasPriceSelectedForLiquidity?: number;
  refreshAll?: () => Promise<void>;
  setLinkExplorer?: (link: string) => void;
  setProgressModal?: (visible: boolean) => void;
  setConfirmModal?: (visible: boolean) => void;
  calculateUSDtokens: (
    t0: string,
    t1: string,
    amount0: string | number,
    amount1: string | number
  ) => string[];
  tableInstance?: any;
  setTableInstance?: (t) => void;
  isMobile?: boolean;
  getPairAndTokenState?: () => [PairState, any];
  findReservesBySymbols?: (
    tokenA: { packageHash: string; decimals: number },
    tokenB: { packageHash: string; decimals: number }
  ) => PairReserves | undefined;
  currentQuery: any;
  setCurrentQuery: (v) => void;
  filterDataReload: (v) => any;
  mapExpandedRows: any[];
  setMapExpandedRows: (l) => void;
  changeRowPriority: (r, p) => void;
}

export interface PairReserves {
  reserve0: BigNumber.Value;
  reserve1: BigNumber.Value;
  token0Decimals: number;
  token1Decimals: number;
}

export const ConfigProviderContext = createContext<ConfigContext>({} as any);

export const casperClient = new CasperClient(NETWORK_NAME, NODE_ADDRESS);

export const apiClient = new APIClient(casperClient);

const formatter = Intl.NumberFormat("en", { notation: "compact" });

export const convertNumber = (number: number) => {
  return formatter.format(number);
};
export const tokenPrices: Record<string, string> = {};

/**
 * Return type for GetStatus
 */
export type StatusResponseType = {
  // network token balance of the account
  balance: BigNumber;
  // uref of the main purse
  mainPurse: string;
};

/**
 * Get the balance and main purse of the wallet
 *
 * @param wallet Wallet whose account is being used
 * @returns the balance and make purse uref
 */
export async function getStatus(wallet: Wallet): Promise<StatusResponseType> {
  const [balance, mainPurse] = await Promise.all([
    casperClient.getBalance(wallet),
    casperClient.getMainPurse(wallet),
  ]);

  return { balance, mainPurse };
}

export const ConfigContextWithReducer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(ConfigReducer, initialConfigState);
  const [tokenState, tokenDispatch] = useReducer(
    TokenReducer,
    initialTokenState
  );
  const [pairState, pairDispatch] = useReducer(PairsReducer, initialPairsState);

  const { tokens } = tokenState;
  const [progressModal, setProgressModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const columns = getColumns();
  const poolColumns = React.useMemo(() => columns, []);
  const [isStaked, setStaked] = useState(false);
  const [linkExplorer, setLinkExplorer] = useState("");
  const { updateNotification, dismissNotification } = notificationStore();
  const [tableInstance, setTableInstance] = useState<any>({});
  const [currentQuery, setCurrentQuery] = useState("");
  const [mapExpandedRows, setMapExpandedRows] = useState([]);

  const [showConnectionPopup, setShowConnectionPopup] = useState(false);
  const [requestConnectWallet, setRequestConnectWallet] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetchPair().then(() => {
      loadPairs();
    });
    addTokenInLocalStorage();
  }, []);

  const fetchPair = async () => {
    const pairRespond = await axios.get(`${GET_ALL_PAIRS_API}`);

    if (pairRespond.data) {
      pairDispatch({
        type: PairActions.ADD_PAIRS,
        payload: {
          rawPairs: pairRespond.data.data,
        },
      });
    }
  };

  const addTokenInLocalStorage = () => {
    const getItemFromStoreage = JSON.parse(
      localStorage.getItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`)
    );
    // console.log("getItemFromStoreage: ", getItemFromStoreage);
    const listTokens: Record<string, Token> = {};
    getItemFromStoreage?.forEach((token: Token) => {
      listTokens[token.symbol] = token;
    });
    // console.log("listTokens from local: ", listTokens);

    tokenDispatch({
      type: TokenActions.UPDATE_TOKENS,
      payload: {
        tokens: listTokens,
      },
    });
  };

  const orderedPairState: Record<string, PairTotalReserves> = {};
  Object.values(pairState).map((pl) => {
    orderedPairState[pl.orderedName] = pl;
  });

  let debounceConnect = false;

  /**
   * return value for connect()
   */
  type ConnectReturn = {
    // wallet
    wallet?: Wallet;
    // balance of wallet
    balance?: BigNumber;
    // main purse of wallet's address
    mainPurse?: string;
    // wallet accountHashString
    walletAddress: string;
    // was the connection successful?
    isConnected: boolean;
  };

  /**
   * Connect to the currently selected wallet
   *
   * @param name name of wallet to connect
   *
   * @returns wallet, balance, mainPurse, and walletAddress
   */
  async function connect(
    name: WalletName = WalletName.NONE
  ): Promise<ConnectReturn> {
    if (debounceConnect) {
      return {
        wallet: state.wallet,
        mainPurse: state.mainPurse,
        walletAddress: state.wallet?.accountHashString ?? "",
        balance: convertUIStringToBigNumber(tokenState.tokens.CSPR.amount, 9),
        isConnected: state.wallet?.isConnected ?? false,
      };
    }

    debounceConnect = true;
    let w: MaybeWallet;
    switch (name) {
      case WalletName.CASPER_SIGNER:
        try {
          if (state.wallet?.isConnected) {
            await state.wallet.disconnect();
          }
          w = new CasperSignerWallet(NETWORK_NAME);
          if (!w.signer) {
            throw new Error("This wallet is not installed.");
          }
          await w.connect();
        } catch (e) {
          debounceConnect = false;
          throw e;
        }

        if (!w?.publicKey) {
          debounceConnect = false;
          throw new Error("casper signer error");
        }
        break;
      case WalletName.CASPER_DASH:
        try {
          if (state.wallet?.isConnected) {
            await state.wallet.disconnect();
          }
          w = new CasperDashWallet(NETWORK_NAME);
          if (!w.signer) {
            throw new Error("This wallet is not installed.");
          }
          await w.connect();
          await w.getActiveKey();
        } catch (e) {
          debounceConnect = false;
          throw e;
        }

        if (!w?.publicKey) {
          debounceConnect = false;
          throw new Error("casper dash error");
        }
        break;
      case WalletName.CASPER_WALLET:
        try {
          if (state.wallet?.isConnected) {
            await state.wallet.disconnect();
          }

          w = new CasperWallet(NETWORK_NAME);
          if (!w.signer) {
            throw new Error("This wallet is not installed.");
          }
          await w.connect();
        } catch (e) {
          debounceConnect = false;
          throw e;
        }

        if (!w?.publicKey) {
          debounceConnect = false;
          throw new Error("casper wallet error");
        }
        break;
      case WalletName.TORUS:
        try {
          if (state.wallet?.isConnected) {
            await state.wallet.disconnect();
          }

          w = new TorusWallet(NETWORK_NAME);
          await w.connect();
        } catch (e) {
          debounceConnect = false;
          throw e;
        }

        if (!w?.publicKey) {
          debounceConnect = false;
          throw new Error("torus wallet error");
        }
        break;
      default:
        setShowConnectionPopup(true);
        return {
          mainPurse: "",
          walletAddress: "",
          balance: convertUIStringToBigNumber(tokenState.tokens.CSPR.amount, 9),
          isConnected: false,
        };
    }

    try {
      //const { balance, mainPurse } = await getStatus(w);
      debounceConnect = false;

      return {
        wallet: w,
        walletAddress: w.accountHashString,
        isConnected: w.isConnected,
      };
      // return {
      //   wallet: w,
      //   balance: new BigNumber(0),
      //   mainPurse: "ds",
      //   walletAddress: w.accountHashString,
      //   isConnected: w.isConnected,
      // };
    } catch {
      updateNotification({
        type: NotificationType.Error,
        title: "No main purse detected",
        subtitle: "Add CSPR to the wallet before proceeding.",
        show: true,
        chargerBar: false,
      });

      debounceConnect = false;

      return {
        wallet: w,
        balance: new BigNumber(0),
        mainPurse,
        walletAddress: w.accountHashString,
        isConnected: w.isConnected,
      };
    }
  }

  async function updateBalances(
    wallet: Wallet,
    tokens: Record<string, Token>,
    tokenDispatch: React.Dispatch<TokenAction>,
    isConnected: boolean
  ): Promise<void> {
    if (!isConnected) {
      return;
    }

    try {
      //console.log('tokenState', tokenState)
      // console.log("tokens in update balance", tokens);
      const ps = Object.keys(tokens).map((x) => {
        const token = tokens[x];

        //console.log('token', x, token)
        if (tokens[x].contractHash) {
          return Promise.all([
            apiClient
              .getERC20Allowance(wallet, token.contractHash)
              .then((response) => {
                //console.log('allowance', token, response)
                tokenDispatch({
                  type: TokenActions.LOAD_ALLOWANCE,
                  payload: {
                    name: x,
                    allowance: convertBigNumberToUIString(
                      new BigNumber(response),
                      token.decimals
                    ),
                  },
                });
              }),
            apiClient
              .getERC20Balance(wallet, token.contractHash)
              .then((response) => {
                // console.log(
                //   "balance",
                //   token.symbol,
                //   response,
                //   token.decimals,
                //   token.packageHash
                // );
                const b = convertBigNumberToUIString(
                  new BigNumber(response),
                  token.decimals
                );

                tokenDispatch({
                  type: TokenActions.LOAD_BALANCE,
                  payload: {
                    name: x,
                    amount: b,
                  },
                });
              }),
          ]);
        } else {
          return casperClient.getBalance(wallet).then((balance) => {
            // console.log(
            //   "cspr balance",
            //   convertBigNumberToUIString(new BigNumber(balance.toString()))
            // );
            tokenDispatch({
              type: TokenActions.LOAD_BALANCE,
              payload: {
                name: "CSPR",
                amount: convertBigNumberToUIString(
                  new BigNumber(balance.toString())
                ),
              },
            });
          });
        }
      });

      await Promise.all(ps);
    } catch (err) {
      log.error(`updateBalances error: ${err}`);
    }
  }

  async function refresh(wallet?: Wallet) {
    await loadPairs();
    if (wallet) {
      await Promise.all([
        updateBalances(wallet, tokens, tokenDispatch, wallet?.isConnected),
        loadPairsUserData(wallet, wallet?.isConnected),
      ]);
    } else {
      // await clearPairsUserData();
    }
  }

  const clearPairsUserData = async () => {
    Object.keys(tokens).map((x) => {
      if (tokens[x].contractHash) {
        tokenDispatch({
          type: TokenActions.LOAD_ALLOWANCE,
          payload: {
            name: x,
            allowance: convertBigNumberToUIString(
              new BigNumber(0),
              tokens[x].decimals
            ),
          },
        });

        tokenDispatch({
          type: TokenActions.LOAD_BALANCE,
          payload: {
            name: x,
            amount: convertBigNumberToUIString(
              new BigNumber(0),
              tokens[x].decimals
            ),
          },
        });
      } else {
        tokenDispatch({
          type: TokenActions.LOAD_BALANCE,
          payload: {
            name: "CSPR",
            amount: convertBigNumberToUIString(
              new BigNumber(0),
              tokens[x].decimals
            ),
          },
        });
      }
    });

    const pairList = Object.keys(pairState).map((x) => pairState[x]);
    for (const pair of pairList) {
      pairDispatch({
        type: PairActions.ADD_ALLOWANCE_TO_PAIR,
        payload: {
          name: pair.name,
          allowance: convertBigNumberToUIString(new BigNumber(0), 18),
        },
      });

      pairDispatch({
        type: PairActions.ADD_BALANCE_TO_PAIR,
        payload: {
          name: pair.name,
          balance: convertBigNumberToUIString(new BigNumber(0), 18),
        },
      });
    }
  };

  async function onConnectWallet(
    name: WalletName = WalletName.NONE,
    ignoreError = false
  ): Promise<void> {
    if (state.wallet?.isConnected) {
      return;
    }

    if (debounceConnect) {
      return;
    }

    try {
      const ret = await connect(name);
      if (!ret.isConnected) {
        return;
      }
      Toast("Connected");
      casperClient.getMainPurse(ret.wallet).then((mainPurse) => {
        dispatch({
          type: ConfigActions.SELECT_MAIN_PURSE,
          payload: { mainPurse: mainPurse },
        });
      });
      dispatch({
        type: ConfigActions.CONNECT_WALLET,
        payload: { wallet: ret.wallet },
      });

      await refresh(ret.wallet);
      // updateNotification({
      //   type: NotificationType.Success,
      //   title: "Connected",
      //   subtitle: "",
      //   show: true,
      //   timeToClose: 10,
      //   chargerBar: true,
      // });
    } catch (err) {
      log.error(`onConnectWallet error: ${err}`);
      dismissNotification();
      if (ignoreError) {
        return;
      }

      if (err.message.includes("make sure you have the Signer installed")) {
        updateNotification({
          type: NotificationType.Error,
          title: "This wallet is not installed.",
          subtitle: "",
          show: true,
          chargerBar: true,
        });
        return;
      }

      if (err.message === "main purse does not exist") {
        updateNotification({
          type: NotificationType.Error,
          title: "Main purse does not exist, send CSPR to your wallet first",
          subtitle: "",
          show: true,
          chargerBar: true,
        });
        return;
      }
      ToastError("Ooops we have an error");
    }
  }

  const { isConnected, slippageToleranceSelected, mainPurse } = state;

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    const fn = async () => {
      refresh();
      /*const data = await apiClient.getTokenList();
      const tokens = tokensToObject(data.tokens);
      //console.log('TOKENS', tokens)
      tokenDispatch({
        type: TokenActions.UPDATE_TOKENS,
        payload: { tokens } as any,
      });*/
    };

    fn().catch((e) => log.error(`UPDATE_TOKENS error": ${e}`));
  }, []);

  useEffect(() => {
    window.addEventListener("signer:connected", (msg) => {
      console.log("signer:connected", msg);
    });
    window.addEventListener("signer:disconnected", (msg) => {
      console.log("signer:disconnected", msg);
      //onDisconnectWallet()
    });
    window.addEventListener("signer:tabUpdated", (msg) => {
      console.log("signer:tabUpdated", msg);
      //onConnectConfig()
    });
    window.addEventListener("signer:activeKeyChanged", async (msg) => {
      console.log("signer:activeKeyChanged", msg);
      setRequestConnectWallet(Math.random() * 1 ** 9);
    });
    window.addEventListener("signer:locked", (msg) => {
      console.log("signer:locked", msg);
    });
    window.addEventListener("signer:unlocked", (msg) => {
      console.log("signer:unlocked", msg);
      //onConnectConfig()
    });

    window.addEventListener("signer:initialState", (msg) => {
      console.log("signer:initialState", msg);
      //connect()
    });
  }, []);

  useEffect(() => {
    const fn = async () => {
      //console.log('wat', state)
      if (state?.wallet) {
        // console.log("update", state);
        await state.wallet.getActiveKey();
        dispatch({
          type: ConfigActions.CONNECT_WALLET,
          payload: { wallet: state.wallet },
        });
        refresh(state.wallet);
      }
    };

    fn();
  }, [requestConnectWallet]);

  function getColumns() {
    return [
      {
        id: 1,
        Header: "Pool",
        accessor: "name",
        Cell: (tableProps: any) => (
          <img
            src={tableProps.row.original.tokeIcon}
            width={25}
            alt="Token Icon"
          />
        ),
      },
      {
        id: 2,
        Header: "Liquidity",
        accessor: "totalSupply",
      },
      {
        id: 3,
        Header: "Volume 7D",
        accessor: "volume7d",
      },
      {
        id: 4,
        Header: "Fees 7d",
        accessor: "fees24h",
      },
      // {
      //   id: 5,
      //   Header: "APR 7D",
      //   accessor: "oneYFees",
      // },
    ];
  }

  const getTVLandVolume = (): TVLAndVolume => {
    const pairs = Object.values(pairState);

    const tvl = pairs
      .reduce((acc, pl) => {
        return (acc += parseFloat(pl.totalLiquidityUSD));
      }, 0)
      .toFixed(2);

    const totalVolume = pairs
      .reduce((acc, pl) => {
        return (acc += parseFloat(pl.volume7d));
      }, 0)
      .toFixed(2);

    const data = {
      tvl,
      totalVolume,
    };

    return data;
  };

  const getPoolList = (): PairData[] => {
    return Object.entries(pairState).map(([k, v]) => {
      return v;
    });
  };

  const filter = (onlyStaked: boolean, row: Row<PairData>): any => {
    if (onlyStaked) {
      return parseFloat(row.original.balance) > 0;
    }

    return row;
  };

  const filterDataReload = (row: Row<PairData>): any => {
    if (currentQuery.trim().length == 0) return true;

    const data = row.original;
    const query = currentQuery.toUpperCase();

    if (
      data.name.includes(query) ||
      data.totalSupply.includes(query) ||
      data.volume7d.includes(query) ||
      data.volume1d.includes(query)
    ) {
      return true;
    }
    return false;
  };

  interface PairTotalReserves {
    totalReserve0: BigNumber.Value;
    totalReserve1: BigNumber.Value;
    token0Decimals: number;
    token1Decimals: number;
  }

  async function loadPairs(): Promise<void> {
    try {
      // console.log("load pairs");
      const pairs = Object.values(pairState);
      const tokenListToUpdateToTokenState = {};
      // console.log("state.wallet", state.wallet);
      pairs.forEach(async (pair) => {
        if (!tokenState.tokens[pair.token0Symbol]) {
          // const _amount = await apiClient.getERC20Balance(state.wallet, pair.contractHash0)
          // const b = convertBigNumberToUIString(
          //   new BigNumber(_amount),
          //   pair.token0Decimals
          // );
          // console.log('b', b)

          tokenListToUpdateToTokenState[pair.token0Symbol] = {
            contractHash: pair.contractHash0,
            decimals: pair.token0Decimals,
            name: pair.token0Name,
            packageHash: pair.contract0,
            symbol: pair.token0Symbol,
            amount: "0",
          };
          tokenDispatch({
            type: TokenActions.UPDATE_TOKENS,
            payload: {
              tokens: tokenListToUpdateToTokenState,
            },
          });
        }

        if (!tokenState.tokens[pair.token1Symbol]) {
          // const _amount = await apiClient.getERC20Balance(state.wallet, pair.contractHash0)
          // const b = convertBigNumberToUIString(
          //   new BigNumber(_amount),
          //   pair.token0Decimals
          // );
          tokenListToUpdateToTokenState[pair.token1Symbol] = {
            contractHash: pair.contractHash1,
            decimals: pair.token1Decimals,
            name: pair.token1Name,
            packageHash: pair.contract1,
            symbol: pair.token1Symbol,
            amount: "0",
          };
          tokenDispatch({
            type: TokenActions.UPDATE_TOKENS,
            payload: {
              tokens: tokenListToUpdateToTokenState,
            },
          });
        }
      });
      // console.log(
      //   "tokenListToUpdateToTokenState",
      //   tokenListToUpdateToTokenState
      // );
      // tokenDispatch({
      //   type: TokenActions.UPDATE_TOKENS,
      //   payload: {
      //     tokens: tokenListToUpdateToTokenState,
      //   },
      // });
      // console.log("tokens after add from pairs: ", tokens);

      //await updateBalances(state.wallet, tokens, tokenDispatch, state.wallet?.isConnected)
      const pairTotalReserves: Record<string, PairTotalReserves> = {};

      const infoResultMap: Record<string, any> = {};
      // console.log("loaded pairs");
      // try {
      //   const infoResults = await getPairData(
      //     pairs.map((pl) => pl.packageHash.substr(5))
      //   );
      //   infoResults.map((pl) => (infoResultMap[`hash-${pl.id}`] = pl));
      // } catch (e) {
      //   console.log(`graphql error: ${e}`);
      // }
      // console.log("cmt getPairData", pairs);
      const results = await Promise.all(
        pairs.map(async (pl) => {
          const pairChecked = store.get(pl.name);
          changeRowPriority(pl.name, pairChecked);

          // const pairDataResponse = await apiClient.getPairData(pl.contractHash);
          // // console.log("pairDataResponse 0", pl, pairDataResponse);
          // // console.log(
          // //   "pairDataResponse 1",
          // //   tokenState.tokens[pl.token1Symbol],
          // //   tokenListToUpdateToTokenState
          // // );
          // const token0Decimals = pl.token0Decimals;
          // const token1Decimals = pl.token1Decimals;
          // const reserve0 = convertBigNumberToUIString(
          //   new BigNumber(pairDataResponse.reserve0),
          //   token0Decimals
          // );
          // const reserve1 = convertBigNumberToUIString(
          //   new BigNumber(pairDataResponse.reserve1),
          //   token1Decimals
          // );

          const infoResult = infoResultMap[pl.packageHash] ?? {
            oneWeekVoluemUSD: 0,
            oneDayVoluemUSD: 0,
            reserveUSD: 0,
          };

          return {
            name: pl.name,
            orderedName: pl.orderedName,
            totalReserve0: pl.totalReserve0,
            token0Decimals: pl.token0Decimals,
            token1Decimals: pl.token1Decimals,
            totalReserve1: pl.totalReserve1,
            volume7d: pl.volume7d,
            volume1d: pl.volume1d,
            totalSupply: pl.totalSupply,
            totalLiquidityUSD: pl.totalLiquidityUSD,
          };
        })
      );
      for (const pl of results) {
        // console.log("pl", pl);
        pairDispatch({
          type: PairActions.LOAD_PAIR,
          payload: {
            name: pl.name,
            volume7d: pl.volume7d ?? "0",
            volume1d: pl.volume1d ?? "0",
            totalReserve0: pl.totalReserve0,
            totalReserve1: pl.totalReserve1,
            totalSupply: pl.totalSupply,
            totalLiquidityUSD: pl.totalLiquidityUSD ?? "0",
          },
        });

        pairTotalReserves[pl.orderedName] = {
          totalReserve0: pl.totalReserve0,
          totalReserve1: pl.totalReserve1,
          token0Decimals: pl.token0Decimals,
          token1Decimals: pl.token1Decimals,
        };
      }

      await loadPairsUSD(pairTotalReserves);
      // console.log("done loadPairsUSD");
    } catch (err) {
      log.error("loadPairs", err.message);
    }
  }

  async function loadPairsUSD(
    pairTotalReserves: Record<string, PairTotalReserves>
  ): Promise<void> {
    try {
      const tokens = Object.values(tokenState.tokens);

      for (const t of tokens) {
        const priceUSD = findUSDRateBySymbol(t, pairTotalReserves).toString();
        // console.log("loading token price for", t.symbol, priceUSD);

        tokenDispatch({
          type: TokenActions.LOAD_PRICE_USD,
          payload: {
            name: t.symbol,
            priceUSD,
          },
        });
        tokenPrices[t.symbol] = priceUSD;
      }

      const pairs = Object.values(pairState);

      for (const p of pairs) {
        pairDispatch({
          type: PairActions.LOAD_PAIR_USD,
          payload: {
            name: p.name,
            token0Price: tokenPrices[p.token0Symbol],
            token1Price: tokenPrices[p.token1Symbol],
          },
        });
      }
    } catch (err) {
      log.error("loadPairsUSD", err.message);
    }
  }

  async function loadPairsUserData(
    wallet: Wallet,
    isConnected = false
  ): Promise<void> {
    if (!isConnected) {
      return;
    }
    try {
      const ps = [];
      const pairList = Object.keys(pairState).map((x) => pairState[x]);
      for (const pair of pairList) {
        ps.push(
          apiClient
            .getERC20Allowance(wallet, pair.contractHash)
            .then((response) => {
              pairDispatch({
                type: PairActions.ADD_ALLOWANCE_TO_PAIR,
                payload: {
                  name: pair.name,
                  allowance: convertBigNumberToUIString(
                    new BigNumber(response),
                    18
                  ),
                },
              });
            })
        );
        ps.push(
          apiClient
            .getERC20Balance(wallet, pair.contractHash)
            .then((response) => {
              pairDispatch({
                type: PairActions.ADD_BALANCE_TO_PAIR,
                payload: {
                  name: pair.name,
                  balance: convertBigNumberToUIString(
                    new BigNumber(response),
                    18
                  ),
                },
              });
            })
        );
      }

      await Promise.all(ps);
    } catch (err) {
      log.error("fillPairs", err.message);
    }
  }

  function onSelectFirstToken(token: string | Token): void {
    if (typeof token === "string") {
      tokenDispatch({ type: TokenActions.SELECT_FIRST_TOKEN, payload: token });
    } else {
      tokenDispatch({
        type: TokenActions.SELECT_FIRST_TOKEN,
        payload: token.symbol,
      });
    }
  }

  function onSelectSecondToken(token: string | Token): void {
    if (typeof token === "string") {
      tokenDispatch({ type: TokenActions.SELECT_SECOND_TOKEN, payload: token });
    } else {
      tokenDispatch({
        type: TokenActions.SELECT_SECOND_TOKEN,
        payload: token.symbol,
      });
    }
  }

  function onSwitchTokens(): void {
    tokenDispatch({ type: TokenActions.SWITCH_TOKENS });
  }

  async function onIncreaseAllow(
    amount: number | string,
    contractHash: string,
    poolName: string
  ): Promise<boolean> {
    ToastLoading("Increasing allowance.");
    try {
      let deployHash, deployResult;
      try {
        [deployHash, deployResult] = await signAndDeployAllowance(
          casperClient,
          state.wallet,
          contractHash,
          convertUIStringToBigNumber(amount)
        );
      } catch (data) {
        ToastDimiss();
        ToastError(
          ERROR_BLOCKCHAIN[`${data}`]
            ? ERROR_BLOCKCHAIN[`${data}`].message
            : `${data}`
        );
        return;
      }
      ToastDimiss();
      //setProgressModal(true);
      setLinkExplorer(
        SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`
      );

      // await ToastPromise(casperClient.waitForDeployExecution(deployHash), {
      //   pending: "Wait for deploy transactions",
      //   error: {
      //     render({ data }) {
      //       return ERROR_BLOCKCHAIN[`${data}`]
      //         ? ERROR_BLOCKCHAIN[`${data}`].message
      //         : `${data}`;
      //     },
      //   },
      // });

      toast.loading(
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`,
              "_blank"
            )
          }
        >
          Wait for deploying transaction
        </div>,
        {
          position: "bottom-right",
          autoClose: 60000,
        }
      );

      try {
        const [_deploy, _raw] = await casperClient.waitForDeployExecution(
          deployHash
        );

        if (_deploy) {
          ToastDimiss();
          sleep(500);
          Toast(
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  SUPPORTED_NETWORKS.blockExplorerUrl + `/deploy/${deployHash}`,
                  "_blank"
                )
              }
            >
              <p color="#fff">View transaction in explorer</p>
            </div>
          );
          addTransactions(
            `Approve ${amount} ${poolName}`,
            deployHash,
            true,
            state.wallet.publicKeyHex
          );
        }
      } catch (error) {
        addTransactions(
          `Approve ${amount} ${poolName}`,
          deployHash,
          false,
          state.wallet.publicKeyHex
        );
        ToastDimiss();
        ToastError(
          ERROR_BLOCKCHAIN[`${error}`]
            ? ERROR_BLOCKCHAIN[`${error}`].message
            : `${error}`
        );
        console.log("approve error", error);
      }

      //setProgressModal(false);
      //setConfirmModal(true);
      refresh(state.wallet);
      return true;
    } catch (err) {
      //setProgressModal(false);
      refresh(state.wallet);
      return false;
    }
  }

  async function onDisconnectWallet(): Promise<void> {
    try {
      if (state.wallet) {
        dispatch({ type: ConfigActions.DISCONNECT_WALLET, payload: {} }),
          await state.wallet.disconnect();
        ToastDimiss();
      }
    } catch (error) {
      ToastError("Error disconnecting wallet");
    }
  }

  const { setGlobalFilter } = tableInstance as any as TableInstance<PairData>;
  const changeData = useAsyncDebounce((value) => {
    if (setGlobalFilter != undefined) {
      setGlobalFilter(value || "");
    }
  }, 100);

  const refreshAll = async (): Promise<void> => {
    await refresh(state.wallet);
    changeData(currentQuery);
  };

  function calculateUSDtokens(
    token0: string,
    token1: string,
    amount0: string | number,
    amount1: string | number
  ): string[] {
    const filter = getPoolList().filter(
      (r) => r.token0Symbol === token0 && r.token1Symbol === token1
    );
    // console.log("filter", filter);
    if (filter.length > 0) {
      return [
        new BigNumber(amount0).times(filter[0].token0Price).toFixed(4),
        new BigNumber(amount1).times(filter[0].token1Price).toFixed(4),
      ];
    }

    const filter2 = getPoolList().filter(
      (r) => r.token1Symbol === token0 && r.token0Symbol === token1
    );
    // console.log("filter2", filter2);
    if (filter2.length > 0) {
      return [
        new BigNumber(amount0).times(filter2[0].token1Price).toFixed(4),
        new BigNumber(amount1).times(filter2[0].token0Price).toFixed(4),
      ];
    }
    const prices = [
      tokenPrices[token0] ?? "0.00",
      tokenPrices[token1] ?? "0.00",
    ];
    return [
      new BigNumber(amount0).times(prices[0]).toFixed(4),
      new BigNumber(amount1).times(prices[1]).toFixed(4),
    ];
  }

  const getPairAndTokenState = (): [PairState, any] => {
    return [pairState, tokenState.tokens];
  };

  /**
   * findReservesBySymbols search for pair data by the symbol pair
   *
   * @param tokenASymbol first token symbol string
   * @param tokenBSymbol second token symbol string
   *
   * @returns pair reserve data
   */
  function findReservesBySymbols(
    tokenAPackageHash: { packageHash: string; decimals: number },
    tokenBPackageHash: { packageHash: string; decimals: number },
    overrideReserves: Record<string, PairTotalReserves> = {}
  ): PairReserves | undefined {
    let tA = tokenAPackageHash;
    let tB = tokenBPackageHash;
    if (tA.packageHash === "") {
      tA = { packageHash: RAW_TOKENS["WCSPR"].packageHash, decimals: 9 };
    }
    if (tB.packageHash === "") {
      tB = { packageHash: RAW_TOKENS["WCSPR"].packageHash, decimals: 9 };
    }

    let lookUp = `${tA.packageHash}-${tB.packageHash}`;

    // do a simple look up
    let pairData = overrideReserves[lookUp] ?? orderedPairState[lookUp];
    if (pairData) {
      // console.log("a", pairData);
      // console.log("===== 1");
      return {
        reserve0: convertUIStringToBigNumber(
          pairData.totalReserve0,
          pairData.token0Decimals
        ),
        reserve1: convertUIStringToBigNumber(
          pairData.totalReserve1,
          pairData.token1Decimals
        ),
        token0Decimals: pairData.token0Decimals,
        token1Decimals: pairData.token1Decimals,
      };
    }

    // do different simple look up
    lookUp = `${tB.packageHash}-${tA.packageHash}`;
    pairData = overrideReserves[lookUp] ?? orderedPairState[lookUp];

    if (pairData) {
      // console.log("b", pairData);
      // console.log("===== 2");
      return {
        reserve0: convertUIStringToBigNumber(
          pairData.totalReserve1,
          pairData.token1Decimals
        ),
        reserve1: convertUIStringToBigNumber(
          pairData.totalReserve0,
          pairData.token0Decimals
        ),
        token0Decimals: pairData.token1Decimals,
        token1Decimals: pairData.token0Decimals,
      };
    }

    // use pathfinder for multi-pool
    const path = getPath(
      tA.packageHash,
      tB.packageHash,
      Object.values(tokenState.tokens),
      Object.values(pairState)
    );

    if (!path || !path.length) {
      // console.log('findReservesBySymbols: path not found', Object.values(tokenState.tokens), Object.values(pairState))
      // updateNotification({
      //   type: NotificationType.Error,
      //   title: `Path between ${tA}-${tB} not found`,
      //   subtitle: "",
      //   show: true,
      //   timeToClose: 10,
      //   chargerBar: true,
      // });
      // throw new Error("path not found");
      return {
        reserve0: new BigNumber(0),
        reserve1: new BigNumber(0),
        token0Decimals: tA.decimals,
        token1Decimals: tB.decimals,
      };
    }
    // console.log("path", path);
    // console.log("===== 3");

    let reserve0 = new BigNumber(1);
    let reserve1 = new BigNumber(1);
    const token0Decimals = tA.decimals;
    const token1Decimals = tB.decimals;
    for (let i = 1; i < path.length; i++) {
      const pair = overrideReserves[path[i].label.name] ?? path[i].label;
      if (path[i - 1].id == pair.contract1) {
        // let decimals = pair.token1Decimals;
        reserve0 = reserve0.times(pair.totalReserve1);
        // .div(new BigNumber(10).pow(decimals));
        // decimals = pair.token0Decimals;
        reserve1 = reserve1.times(pair.totalReserve0);
        // .div(new BigNumber(10).pow(decimals));
      } else {
        // let decimals = pair.token0Decimals;
        reserve0 = reserve0.times(pair.totalReserve0);
        // .div(new BigNumber(10).pow(decimals));
        // decimals = pair.token1Decimals;
        reserve1 = reserve1.times(pair.totalReserve1);
        // .div(new BigNumber(10).pow(decimals));
      }
    }

    return {
      reserve0: reserve0.multipliedBy(new BigNumber(10).pow(token0Decimals)),
      reserve1: reserve1.multipliedBy(new BigNumber(10).pow(token1Decimals)),
      token0Decimals,
      token1Decimals,
    };
  }

  /**
   * findReservesBySymbols search for pair data by the symbol pair
   *
   * @param tokenSymbol token symbol string
   *
   * @returns usd conversion rate
   */
  const findUSDRateBySymbol = (
    token: { packageHash: string; decimals: number },
    pairTotalReserves: Record<string, PairTotalReserves>
  ): BigNumber => {
    let t = token;
    if (t.packageHash === "") {
      t = RAW_TOKENS["WCSPR"];
    }
    // console.log("findUSDRateBySymbol", pairTotalReserves);
    if (t.packageHash === RAW_TOKENS["USDC"].packageHash) {
      const ratesUSDC = findReservesBySymbols(
        t,
        RAW_TOKENS["USDT"],
        pairTotalReserves
      );
      // console.log("findUSDRateBySymbol ratesUSDC", ratesUSDC);
      const ret = new BigNumber(ratesUSDC.reserve0)
        .div(new BigNumber(10).pow(ratesUSDC.token0Decimals))
        .div(
          new BigNumber(ratesUSDC.reserve1).div(
            new BigNumber(10).pow(ratesUSDC.token1Decimals)
          )
        );
      // console.log(
      //   "findUSDRateBySymbol ratesUSDC",
      //   ratesUSDC.reserve0.toString(),
      //   ratesUSDC.reserve1.toString(),
      //   ret.toString()
      // );
      return ret;
    }

    if (t.packageHash === RAW_TOKENS["USDT"].packageHash) {
      const ratesUSDT = findReservesBySymbols(
        t,
        RAW_TOKENS["USDC"],
        pairTotalReserves
      );

      const ret = new BigNumber(ratesUSDT.reserve0)
        .div(new BigNumber(10).pow(ratesUSDT.token0Decimals))
        .div(
          new BigNumber(ratesUSDT.reserve1).div(
            new BigNumber(10).pow(ratesUSDT.token1Decimals)
          )
        );
      // console.log(
      //   "findUSDRateBySymbol ratesUSDT",
      //   ratesUSDT.reserve0.toString(),
      //   ratesUSDT.reserve1.toString(),
      //   ret.toString()
      // );
      return ret;
    }
    // console.log("beforee findReservesBySymbols", t, RAW_TOKENS["USDC"]);
    const ratesUSDC = findReservesBySymbols(
      t,
      RAW_TOKENS["USDC"],
      pairTotalReserves
    );
    // console.log(
    //   "findUSDRateBySymbol ratesUSDC",
    //   t,
    //   ratesUSDC.reserve0.toString(),
    //   ratesUSDC.reserve1.toString()
    // );
    const ratesUSDT = findReservesBySymbols(
      t,
      RAW_TOKENS["USDT"],
      pairTotalReserves
    );

    let priceWithUsdc;
    if (!BigNumber(ratesUSDC.reserve0).isZero()) {
      priceWithUsdc = new BigNumber(ratesUSDC.reserve1)
        .div(new BigNumber(10).pow(ratesUSDC.token1Decimals))
        .div(
          new BigNumber(ratesUSDC.reserve0).div(
            new BigNumber(10).pow(ratesUSDC.token0Decimals)
          )
        );
    }
    let priceWithUsdt;
    if (!BigNumber(ratesUSDT.reserve0).isZero()) {
      priceWithUsdt = new BigNumber(ratesUSDT.reserve1)
        .div(new BigNumber(10).pow(ratesUSDT.token1Decimals))
        .div(
          new BigNumber(ratesUSDT.reserve0).div(
            new BigNumber(10).pow(ratesUSDT.token0Decimals)
          )
        );
    }
    if (!priceWithUsdc || priceWithUsdc.isZero()) {
      return priceWithUsdt;
    }

    if (!priceWithUsdt || priceWithUsdt.isZero()) {
      return priceWithUsdc;
    }

    if (priceWithUsdc.div(priceWithUsdt).gt(2)) {
      return priceWithUsdc;
    }

    if (priceWithUsdt.div(priceWithUsdc).gt(2)) {
      return priceWithUsdt;
    }

    const ret = priceWithUsdc.plus(priceWithUsdt).div(2);
    return ret;
  };

  const changeRowPriority = (name, priority) => {
    store.set(name, priority);
    pairDispatch({
      type: PairActions.CHANGE_PRIORITY,
      payload: {
        name: name,
        checked: priority,
      },
    });
  };

  return (
    <ConfigProviderContext.Provider
      value={{
        onConnectWallet,
        onDisconnectWallet,
        configState: state,
        tokenState,
        onSelectFirstToken,
        onSelectSecondToken,
        onSwitchTokens,
        tokens,
        firstTokenSelected: tokenState.tokens[tokenState.firstTokenSelected],
        secondTokenSelected: tokenState.tokens[tokenState.secondTokenSelected],
        isConnected,
        slippageToleranceSelected,
        onIncreaseAllow,
        pairState,
        poolColumns,
        columns,
        getPoolList,
        getTVLandVolume,
        isStaked,
        setStaked,
        filter,
        gasPriceSelectedForSwapping: state.gasPriceSelectedForSwapping,
        gasPriceSelectedForLiquidity: state.gasPriceSelectedForLiquidity,
        refreshAll,
        setLinkExplorer,
        setProgressModal,
        setConfirmModal,
        calculateUSDtokens,
        tableInstance,
        setTableInstance,
        isMobile,
        getPairAndTokenState,
        findReservesBySymbols,
        currentQuery,
        setCurrentQuery,
        filterDataReload,
        mapExpandedRows,
        setMapExpandedRows,
        changeRowPriority,
        confirmModal,
        linkExplorer,
        progressModal,
        setShowConnectionPopup,
        showConnectionPopup,
      }}
    >
      {children}
    </ConfigProviderContext.Provider>
  );
};
