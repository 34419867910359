import React from "react";
import styled from "styled-components";
import Background from '../../../assets/background.svg'
const MainSpaceStyled = styled.main`
  background: url(${Background});
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
`;
export const MainSpaceCC = ({ children }) => {
  return <MainSpaceStyled>
    {children}
  </MainSpaceStyled>;
};
