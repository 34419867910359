import BigNumber from "bignumber.js";

import React, { useContext, useState, useEffect, useReducer } from "react";
import { Tab } from 'react-bootstrap'
import { ConfigProviderContext, convertNumber } from "../../../contexts/ConfigContext";
import {
  ActionContainerNSM,
  ArrowContainerNSM,
  BalanceInputContainerNSM,
  BalanceInputItem1NSM,
  BalanceInputItem2NSM,
  BalanceInputNSM,
  ButtonHalfMax,
  ButtonHalfMaxContainer,
  ButtonSpaceNSM,
  ContainerInnerNSM,
  ContainerSwapActionsNSM,
  ExchangeRateBox,
  FlechaIcon,
  IconPlaceNSM,
  NewBalanceSpaceNSM,
  NewSwapButtonWidth100,
  NewSwapContainerNSM,
  NewTokenDetailActionsNSM,
  NewTokenDetailItems1NSM,
  NewTokenDetailItems2NSM,
  NewTokenDetailItems3NSM,
  NewTokenDetailItems4NSM,
  NewTokenDetailSelectNSM,
  SwapDetailsNSM,
  TokenSelectionNSM,
  TokenSelectNSM,
} from "../../atoms";
import StyledTab from "../../atoms/Tab";
import { initialTokenState, TokenReducer, TokenActions } from "../../../reducers/TokenReducers"

import { SettingMenu, SwapDetail, SwapStatistics } from "../../molecules";
import FloatMenu from "../FloatMenu";
import { useSearchParams } from "react-router-dom";

import {
  convertAllFormatsToUIFixedString,
  formatNaN,
  numberWithCommas,
  Token,
} from "../../../commons";
import AddTokenModal from "./AddTokenModal";
import { UpdatableCircle } from "../../atoms/UpdatableCircle";
import { ProgressBarProviderContext } from "../../../contexts/ProgressBarContext";
import styled from "styled-components";
import { SwapProviderContext } from "../../../contexts/SwapContext";
import { globalStore } from "../../../store/store";
import isCSPRValid from "../../../hooks/isCSPRValid";
import { SUPPORTED_NETWORKS, SWAP_FEE } from "../../../constant";
import { AiFillSetting } from 'react-icons/ai'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { FiRefreshCw } from "react-icons/fi";
import SwitchSwap from "../../atoms/SwitchSwap";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
`;
const WrapperRow = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: black;
`;
const WrapperRowInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
  gap: 5px;
  border-radius: 16px;
  padding: 0px 10px;
  background-color: #1B232E;
  opacity: 1;
`;
const SwapVertButton = styled.div`
  width: 35px;
  height: 35px;
  position: absolute;
  top: 42%;
  left: 48%;
  background-color: #4592AE;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #fff;
`
const TokenLogoCustom = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;

  span {
    font-size: 9px;
    color: #fff;
  }
`
enum tokenType {
  tokenA = "tokenA",
  tokenB = "tokenB",
}

const SwapNewModule = () => {
  const {
    onConnectWallet,
    onSelectFirstToken,
    onSelectSecondToken,
    onSwitchTokens,
    tokens,
    firstTokenSelected,
    secondTokenSelected,
    isConnected,
    onIncreaseAllow,
    pairState,
    gasPriceSelectedForSwapping,
    refreshAll,
    calculateUSDtokens,
    getPairAndTokenState,
    findReservesBySymbols,
    configState
  } = useContext(ConfigProviderContext);
  const { onConfirmSwapConfig, getSwapDetails } =
    useContext(SwapProviderContext);
  const { progressBar } = useContext(ProgressBarProviderContext);

  const [gasFee, gasFeeSetter] = useState<number>(gasPriceSelectedForSwapping);
  const [amountSwapTokenA, amountSwapTokenASetter] = useState<number>(0);
  const [amountSwapTokenB, amountSwapTokenBSetter] = useState<number>(0);
  const [priceImpact, priceImpactSetter] = useState<number | string>(0);
  const [feeToPay, feeToPaySetter] = useState<number>(Number(SWAP_FEE));
  const [exchangeRateA, exchangeRateASetter] = useState<number>(0);
  const [exchangeRateB, exchangeRateBSetter] = useState<number>(0);
  const [defaultPriceImpactLabel, defaultPriceImpactLabelSetter] =
    useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [isSwitchToken, setIsSwitchToken] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  const [newToken, setNewToken] = useState<Token>()

  const [tokenState, tokenDispatch] = useReducer(
    TokenReducer,
    initialTokenState
  );

  const {
    disableButton,
    setDisableButton,
    handleValidate,
    showNotification,
    dismissNotification,
  } = isCSPRValid();

  const [lastChanged, setLastChanged] = useState("");
  const [valueAUSD, setValueAUSD] = useState("0.00");
  const [valueBUSD, setValueBUSD] = useState("0.00");

  const { slippageTolerance, updateSlippageTolerance } = globalStore((state) => {
    return { slippageTolerance: state.slippageTolerance, updateSlippageTolerance: state.updateSlippageTolerance }
  });
  const [priceA, setPriceA] = useState("0.00");
  const [priceB, setPriceB] = useState("0.00");

  const [amountSwapTokenAUI, amountSwapTokenAUISetter] = useState<string>("0");
  const [amountSwapTokenBUI, amountSwapTokenBUISetter] = useState<string>("0");
  const [listTokens, setListTokens] = useState<Record<string, Token>>(tokens)
  useEffect(() => {
    //isConnected && refreshPrices()
    const t0 = searchParams.get("token0");
    const t1 = searchParams.get("token1");
    if (t0) {
      onSelectFirstToken(tokens[t0]);
      onSelectSecondToken(tokens[t1]);
    }
    updateSwapDetail(
      firstTokenSelected,
      secondTokenSelected,
      amountSwapTokenA,
      lastChanged == "A" ? firstTokenSelected : secondTokenSelected
    );
  }, [isConnected, pairState]);

  useEffect(() => {
    const [pairState, tokenState] = getPairAndTokenState();
    console.log("pairState: ");
    console.log("tokenState: ");
    progressBar(async () => {
      lastChanged == "A"
        ? await changeTokenA(amountSwapTokenA)
        : await changeTokenB(amountSwapTokenB);
      await refreshAll();
    });
  }, [amountSwapTokenA, amountSwapTokenB, isConnected]);

  useEffect(() => {
    console.log("tokens", tokens);
  }, []);

  async function onConnect() {
    onConnectWallet();
  }

  function onSwitchTokensHandler() {
    setIsSwitchToken(!isSwitchToken);
    onSwitchTokens();

    if (lastChanged == "A") {
      changeTokenB(amountSwapTokenA.toString());
      setLastChanged("B");
    } else if (lastChanged == "B") {
      changeTokenA(amountSwapTokenB.toString());
      setLastChanged("A");
    }
  }

  function resetAll() {
    amountSwapTokenASetter(0);
    amountSwapTokenBSetter(0);
  }

  async function onConfirmSwap() {
    const [pairState, tokenState] = getPairAndTokenState();
    await onConfirmSwapConfig(
      amountSwapTokenA,
      amountSwapTokenB,
      slippageTolerance,
      gasFee,
      pairState,
      tokenState
    );
    resetAll();
  }

  async function updateSwapDetail(
    tokenA: Token,
    tokenB: Token,
    value = amountSwapTokenA,
    token = firstTokenSelected
  ) {
    const { reserve0, reserve1 } = findReservesBySymbols(tokenA, tokenB);
    const getSwapDetailResponse = await getSwapDetails(
      tokenA,
      tokenB,
      reserve0,
      reserve1,
      value,
      token,
      slippageTolerance,
      feeToPay
    );
    const { tokensToTransfer, priceImpact, exchangeRateA, exchangeRateB } =
      getSwapDetailResponse;
    // console.log(
    //   "exchangeRateA",
    //   getSwapDetailResponse,
    //   reserve0.toString(),
    //   reserve1.toString(),
    //   value.toString()
    // );
    priceImpactSetter(priceImpact);
    exchangeRateASetter(exchangeRateA);
    exchangeRateBSetter(exchangeRateB);

    defaultPriceImpactLabelSetter(
      parseFloat(priceImpact as any) > 1
        ? "Price Impact Warning"
        : "Low Price Impact"
    );

    calculateUSDValues(value, tokensToTransfer);
    return tokensToTransfer;
  }

  async function requestIncreaseAllowance(amount, contractHash) {
    console.log("requestIncreaseAllowance");
    await onIncreaseAllow(amount, contractHash);
    await updateSwapDetail(
      firstTokenSelected,
      secondTokenSelected,
      amount,
      firstTokenSelected
    );
  }

  async function changeTokenA(value: string | number) {
    let filteredValue = formatNaN(value);
    if (filteredValue < 0) {
      filteredValue = Math.abs(filteredValue);
    }

    setLastChanged("A");
    amountSwapTokenAUISetter(value.toString());
    amountSwapTokenASetter(filteredValue);

    const minTokenToReceive = await updateSwapDetail(
      firstTokenSelected,
      secondTokenSelected,
      filteredValue,
      firstTokenSelected
    );
    amountSwapTokenBUISetter(
      parseFloat(minTokenToReceive) == 0 ? "0" : minTokenToReceive
    );
    amountSwapTokenBSetter(formatNaN(minTokenToReceive));
  }

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    handleValidate(
      parseFloat(e.target.value),
      parseFloat(firstTokenSelected.amount),
      gasFee || 0,
      firstTokenSelected.symbol,
      tokens["CSPR"].amount
    );
    changeTokenA(e.target.value);
  };

  async function changeTokenB(value) {
    let filteredValue = parseFloat(value);
    if (isNaN(filteredValue)) {
      filteredValue = 0;
    } else if (filteredValue < 0) {
      filteredValue = Math.abs(filteredValue);
    }

    setLastChanged("B");
    amountSwapTokenBUISetter(value);
    amountSwapTokenBSetter(filteredValue);

    const minTokenToReceive = await updateSwapDetail(
      firstTokenSelected,
      secondTokenSelected,
      filteredValue,
      secondTokenSelected
    );
    amountSwapTokenAUISetter(
      parseFloat(minTokenToReceive) == 0 ? "0" : minTokenToReceive
    );
    amountSwapTokenASetter(formatNaN(minTokenToReceive));
  }

  const handleChangeB = async (e) => {
    changeTokenB(e.target.value);
    const minTokenToReceive = await updateSwapDetail(
      firstTokenSelected,
      secondTokenSelected,
      parseFloat(e.target.value),
      secondTokenSelected
    );
    handleValidate(
      parseFloat(minTokenToReceive),
      parseFloat(firstTokenSelected.amount),
      gasFee || 0,
      firstTokenSelected.symbol,
      tokens["CSPR"].amount
    );
  };

  const handleChangeGasFee = (value) => {
    const gasFeeValue = value ? parseFloat(value) : 0;
    gasFeeSetter(value);
    handleValidate(
      currentValue,
      parseFloat(firstTokenSelected.amount),
      gasFeeValue,
      firstTokenSelected.symbol,
      tokens["CSPR"].amount
    );
  };

  const [searchModalA, searchModalASetter] = useState(false);
  async function selectAndCloseTokenA(token: Token): Promise<void> {
    // console.log("token selected: ", token);
    if (checkTokenExist(token)) {
      if (token.symbol === secondTokenSelected.symbol) {
        return;
      }
      onSelectFirstToken(token);
      searchModalASetter(false);
      const minTokenToReceive = await updateSwapDetail(
        token,
        secondTokenSelected,
        amountSwapTokenA,
        token
      );
      amountSwapTokenBUISetter(
        parseFloat(minTokenToReceive) == 0 ? "0" : minTokenToReceive
      );
      amountSwapTokenBSetter(formatNaN(minTokenToReceive));
    } else {
      setNewToken(token);
      searchModalASetter(false);
      searchModalBSetter(false);
      setAddModal(true);
    }
  }

  const [searchModalB, searchModalBSetter] = useState(false);
  async function selectAndCloseTokenB(token: Token): Promise<void> {
    // console.log("token selected: ", token);
    if (checkTokenExist(token)) {
      if (token.symbol === firstTokenSelected.symbol) {
        return;
      }
      onSelectSecondToken(token);
      searchModalBSetter(false);
      const minTokenToReceive = await updateSwapDetail(
        firstTokenSelected,
        token,
        amountSwapTokenB,
        token
      );
      minTokenToReceive;
      amountSwapTokenAUISetter(
        parseFloat(minTokenToReceive) == 0 ? "0" : minTokenToReceive
      );
      amountSwapTokenASetter(formatNaN(minTokenToReceive));
    } else {
      setNewToken(token);
      searchModalASetter(false);
      searchModalBSetter(false);
      setAddModal(true);
    }
  }

  async function validateToken(amount, token) {
    if (token === tokenType.tokenA) {
      if (parseFloat(firstTokenSelected.amount) > gasFee) {
        amount = parseFloat(firstTokenSelected.amount) - gasFee;
        setCurrentValue(amount);
        dismissNotification();
        setDisableButton(false);
      } else {
        showNotification();
        setCurrentValue(amount);
      }
    } else if (token == tokenType.tokenB) {
      const minTokenToReceive = await updateSwapDetail(
        firstTokenSelected,
        secondTokenSelected,
        parseFloat(amount),
        secondTokenSelected
      );
      setCurrentValue(parseFloat(minTokenToReceive));
      if (
        parseFloat(minTokenToReceive) >
        parseFloat(firstTokenSelected.amount) - gasFee
      ) {
        showNotification();
      }
    }
    return amount;
  }

  async function makeHalf(amount, setter, token) {
    amount = await validateToken(amount, token);
    setter(amount / 2);
  }
  async function makeMax(amount, setter, token) {
    amount = await validateToken(amount, token);
    setter(amount);
  }

  // const freeAllowance = new BigNumber(firstTokenSelected.allowance || 0)
  //   .minus(new BigNumber(amountSwapTokenA))
  //   .toNumber();

  const isApproved = true;

  const refreshPrices = async () => {
    // console.log("refreshPrices", amountSwapTokenA);
    await refreshAll();
    await changeTokenA(amountSwapTokenA);
  };

  const calculateUSDValues = (
    amountA: string | number,
    amountB: string | number
  ) => {
    const [usdA, usdB] = calculateUSDtokens(
      firstTokenSelected.symbolPair,
      secondTokenSelected.symbolPair,
      amountA,
      amountB
    );
    // console.log(
    //   "calculateUSDValues",
    //   amountA,
    //   amountB,
    //   usdA,
    //   usdB,
    //   firstTokenSelected.symbolPair
    // );
    const _usdA = isNaN(parseFloat(usdA)) ? "0.00" : usdA;
    const _usdB = isNaN(parseFloat(usdB)) ? "0.00" : usdB;

    setValueAUSD(_usdA);
    setValueBUSD(_usdB);

    setPriceA((parseFloat(_usdA) * formatNaN(exchangeRateA)).toFixed(2));
    setPriceB((parseFloat(_usdB) * formatNaN(exchangeRateB)).toFixed(2));
  };

  const checkTokenExist = (token: Token) => {
    // console.log("token in check token exist: ", token);
    const _filteredTokens = Object.entries(tokens).map((v, k) => v[1]);
    const getItemFromStoreage = JSON.parse(
      localStorage.getItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`)
    );
    const allToken = getItemFromStoreage
      ? _filteredTokens.concat(getItemFromStoreage)
      : _filteredTokens;
    console.log("all token: ", allToken);
    let check = false;
    allToken?.forEach((t) => {
      if (t.packageHash === token.packageHash) {
        check = true;
      }
    });
    return check;
  };

  const AddToken = (token: Token) => {
    console.log("token add: ", token);
    const getItemFromStoreage = JSON.parse(
      localStorage.getItem(`${SUPPORTED_NETWORKS.networkKey}-list-token`)
    );
    if (getItemFromStoreage) {
      const getItemFromStorageCopy = [...getItemFromStoreage];
      getItemFromStorageCopy.push(token);
      localStorage.setItem(
        `${SUPPORTED_NETWORKS.networkKey}-list-token`,
        JSON.stringify(getItemFromStorageCopy)
      );
    } else {
      const listToken = [];
      listToken.push(token);
      localStorage.setItem(
        `${SUPPORTED_NETWORKS.networkKey}-list-token`,
        JSON.stringify(listToken)
      );
    }

    const tokenUpdate: Record<string, Token> = {};
    tokenUpdate[token.symbol] = token;

    tokenDispatch({
      type: TokenActions.UPDATE_TOKENS,
      payload: {
        tokens: tokenUpdate,
      },
    });

    //searchModalA ? onSelectFirstToken(token) : onSelectSecondToken(token)
    // const _listTokens = tokens;
    // _listTokens[`${token.symbol}`] = token;
    // setListTokens(_listTokens);
    setAddModal(false);
  };

  const handleMax = () => {
    console.log("amount", firstTokenSelected.amount);
    changeTokenA(firstTokenSelected.amount);
  };

  return (
    <Wrapper>
      <p style={{ fontFamily: 'Montserrat', fontSize: '3rem', fontWeight: '500', color: '#fff', alignSelf: 'flex-start', marginBottom: '1rem'}}>
        SWAP
      </p>
      <ContainerInnerNSM>
        <ContainerSwapActionsNSM>
          {/* <SettingMenu></SettingMenu> */}
          {/* <div className="d-flex justify-content-between">
            <StyledTab defaultActiveKey="swap">
              <Tab eventKey="swap" title="Swap">
                <></>
              </Tab>
            </StyledTab>
            <AiFillSetting size={24} color='#4592AE'/>
          </div> */}

          <NewSwapContainerNSM>
            <TokenSelectionNSM>
              <NewTokenDetailActionsNSM>
                <ActionContainerNSM>
                  {/* <ButtonHalfMaxContainer>
                      <ButtonHalfMax
                        onClick={() => {
                          makeHalf(
                            firstTokenSelected.amount,
                            changeTokenA,
                            tokenType.tokenA
                          );
                        }}
                      >
                        Half
                      </ButtonHalfMax>
                      <ButtonHalfMax
                        onClick={() => {
                          makeMax(
                            firstTokenSelected.amount,
                            changeTokenA,
                            tokenType.tokenA
                          );
                        }}
                      >
                        Max
                      </ButtonHalfMax>
                    </ButtonHalfMaxContainer> */}
                  <BalanceInputContainerNSM>
                    <BalanceInputItem1NSM>
                      <BalanceInputNSM
                        min={0}
                        onChange={handleChange}
                        type="number"
                        name=""
                        id=""
                        value={amountSwapTokenAUI}
                      // pattern="^[0-9]*[.,]?[0-9]*$"
                      // inputmode="decimal"
                      />
                      <WrapperRowInput
                        onClick={() => searchModalASetter(true)}
                      >
                        {firstTokenSelected?.logoURI ? (
                          <NewTokenDetailItems2NSM
                            src={firstTokenSelected.logoURI}
                          />
                        ) : (
                          <TokenLogoCustom>
                            <span>{firstTokenSelected.symbol}</span>
                          </TokenLogoCustom>
                        )}
                        <NewTokenDetailItems3NSM>
                          {firstTokenSelected.symbol}
                        </NewTokenDetailItems3NSM>
                        <NewTokenDetailItems4NSM>
                          <ArrowContainerNSM>
                            {/* <FlechaIcon /> */}
                          </ArrowContainerNSM>
                        </NewTokenDetailItems4NSM>
                      </WrapperRowInput>
                    </BalanceInputItem1NSM>
                    <WrapperRow>
                      <BalanceInputItem2NSM>
                        <p>$ {valueAUSD}</p>
                      </BalanceInputItem2NSM>
                      <NewBalanceSpaceNSM>
                        Balance:{" "}
                        {firstTokenSelected.amount
                          ? numberWithCommas(
                            convertAllFormatsToUIFixedString(
                              firstTokenSelected.amount,
                              new BigNumber(firstTokenSelected.amount).lt(1)
                                ? firstTokenSelected.decimals
                                : 3
                            )
                          )
                          : "--"}{" "}
                        <span
                          style={{
                            color: "#3483FF",
                            cursor: "pointer",
                            fontWeight: "600",
                          }}
                          onClick={() => handleMax()}
                        >
                          MAX
                        </span>
                      </NewBalanceSpaceNSM>
                    </WrapperRow>
                  </BalanceInputContainerNSM>
                </ActionContainerNSM>
              </NewTokenDetailActionsNSM>
            </TokenSelectionNSM>
          </NewSwapContainerNSM>
          {/* <IconPlaceNSM>
              <SwitchSwap onClick={onSwitchTokensHandler} />
              {/* <UpdatableCircle strokeWidth={12} handler={refreshPrices} /> }
            </IconPlaceNSM>   */}
          <IconPlaceNSM>
            <SwitchSwap onClick={onSwitchTokensHandler} />

            {/* <UpdatableCircle strokeWidth={12} handler={refreshPrices} /> */}
          </IconPlaceNSM>
          <NewSwapContainerNSM>
            <TokenSelectionNSM>
              <NewTokenDetailActionsNSM>
                <ActionContainerNSM>
                  {/* <ButtonHalfMaxContainer>
                      <ButtonHalfMax
                        onClick={() => {
                          makeHalf(
                            secondTokenSelected.amount,
                            changeTokenB,
                            tokenType.tokenB
                          );
                        }}
                      >
                        Half
                      </ButtonHalfMax>
                      <ButtonHalfMax
                        onClick={() => {
                          makeMax(
                            secondTokenSelected.amount,
                            changeTokenB,
                            tokenType.tokenB
                          );
                        }}
                      >
                        Max
                      </ButtonHalfMax>
                    </ButtonHalfMaxContainer> */}
                  <BalanceInputContainerNSM>
                    <BalanceInputItem1NSM>
                      <BalanceInputNSM
                        min={0}
                        onChange={handleChangeB}
                        type="number"
                        name=""
                        id=""
                        value={amountSwapTokenBUI}
                      // pattern="^[0-9]*[.,]?[0-9]*$"
                      />

                      <WrapperRowInput
                        onClick={() => searchModalBSetter(true)}
                      >
                        {secondTokenSelected?.logoURI ? (
                          <NewTokenDetailItems2NSM
                            src={secondTokenSelected.logoURI}
                          />
                        ) : (
                          <TokenLogoCustom>
                            <span>{secondTokenSelected.symbol}</span>
                          </TokenLogoCustom>
                        )}
                        <NewTokenDetailItems3NSM>
                          {secondTokenSelected.symbol}
                        </NewTokenDetailItems3NSM>
                        <NewTokenDetailItems4NSM>
                          <ArrowContainerNSM>
                            <FlechaIcon />
                          </ArrowContainerNSM>
                        </NewTokenDetailItems4NSM>
                      </WrapperRowInput>
                    </BalanceInputItem1NSM>
                    <WrapperRow>
                      <BalanceInputItem2NSM>
                        <p>$ {valueBUSD}</p>
                      </BalanceInputItem2NSM>
                      <NewBalanceSpaceNSM>
                        Balance:{" "}
                        {secondTokenSelected.amount
                          ? numberWithCommas(
                            convertAllFormatsToUIFixedString(
                              secondTokenSelected.amount,
                              new BigNumber(secondTokenSelected.amount).lt(
                                1
                              )
                                ? secondTokenSelected.decimals
                                : 3
                            )
                          )
                          : "--"}
                      </NewBalanceSpaceNSM>
                    </WrapperRow>
                  </BalanceInputContainerNSM>
                </ActionContainerNSM>
              </NewTokenDetailActionsNSM>
            </TokenSelectionNSM>
          </NewSwapContainerNSM>
          <SwapDetailsNSM>
            <ExchangeRateBox
              tokenASymbol={firstTokenSelected.symbol}
              tokenBSymbol={secondTokenSelected.symbol}
              exchangeRateA={exchangeRateA}
              exchangeRateB={exchangeRateB}
            />
            <UpdatableCircle strokeWidth={8} handler={refreshPrices} />
          </SwapDetailsNSM>

          {amountSwapTokenB > 0 && (
            <SwapDetail
              firstSymbolToken={firstTokenSelected.symbol}
              firstTokenAmount={amountSwapTokenA}
              firstTokenDecimals={firstTokenSelected.decimals}
              secondSymbolToken={secondTokenSelected.symbol}
              secondTokenAmount={amountSwapTokenB}
              secondTokenDecimals={secondTokenSelected.decimals}
              priceImpactMessage={defaultPriceImpactLabel}
              priceImpact={priceImpact}
              gasFee={gasFee}
              gasFeeSetter={handleChangeGasFee}
              gasFeeEnabled={true}
              slippage={slippageTolerance}
              slippageEnabled={true}
              slippageSetter={updateSlippageTolerance}
              fullExpanded={false}
            />
          )}

          <ButtonSpaceNSM>
            {!isConnected && (
              <NewSwapButtonWidth100
                content="Connect to Wallet"
                handler={async () => {
                  onConnect();
                }}
              />
            )}
            {/* {!isApproved && isConnected && (
              <NewSwapButtonWidth100
                content={`Approve ${-freeAllowance} ${
                  firstTokenSelected.symbol
                }`}
                disabled={disableButton}
                handler={async () => {
                  await requestIncreaseAllowance(
                    -freeAllowance,
                    firstTokenSelected.contractHash
                  );
                }}
              />
            )} */}
            {isApproved && isConnected && (
              <NewSwapButtonWidth100
                content={
                  amountSwapTokenA > parseFloat(firstTokenSelected.amount)
                    ? "Insufficient balance"
                    : "Swap"
                }
                disabled={
                  amountSwapTokenA <= 0 ||
                  amountSwapTokenB <= 0 ||
                  amountSwapTokenA > parseFloat(firstTokenSelected.amount)
                }
                handler={async () => {
                  await onConfirmSwap();
                }}
              />
            )}
          </ButtonSpaceNSM>
        </ContainerSwapActionsNSM>
      </ContainerInnerNSM>
      {searchModalA && (
        <FloatMenu
          show={searchModalA}
          excludedSymbols={[secondTokenSelected.symbol]}
          tokens={tokens}
          onSelectToken={selectAndCloseTokenA}
          onClick={() => {
            searchModalASetter(false);
          }}
        />
      )}
      {searchModalB && (
        <FloatMenu
          show={searchModalB}
          excludedSymbols={[firstTokenSelected.symbol]}
          tokens={tokens}
          onSelectToken={selectAndCloseTokenB}
          onClick={() => {
            searchModalBSetter(false);
          }}
        />
      )}
      {addModal && (
        <AddTokenModal
          token={newToken}
          onAddToken={() => AddToken(newToken)}
          onCancel={() => setAddModal(false)}
        />
      )}
      {/* <SwapStatistics
        token0Price={priceA}
        token1Price={priceB}
        token0Per={0}
        token1Per={0}
      /> */}
    </Wrapper>
  );
};

export default SwapNewModule;
