import React from "react";
import styled from "styled-components";
import { device } from "../../../contexts/ThemeContext/themes";

const ContainerSwapActions = styled.section`
  background-color: #0F1723;
  justify-self: end;
  box-sizing: border-box;
  width: 462px;

  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 1.5rem;

  @media ${device.mobileS} {
    width: auto;
  }
  @media ${device.tablet} {
    width: 462px;
  }
`;
export const ContainerSwapActionsNSM = ({ children }) => {
  return <ContainerSwapActions>{children}</ContainerSwapActions>;
};
