import { Token } from '../../../commons/api'
import styled from 'styled-components';
import React, { useEffect } from 'react'
import { CEP18Client } from 'casper-cep18-js-client';
import { NODE_ADDRESS, SUPPORTED_NETWORKS, MIDDLEWARE_API } from '../../../constant';
import cstIcon from '../../../assets/swapIcons/casperswap.png'

import { AiOutlineSearch, AiFillCloseCircle } from "react-icons/ai";
import { ContainerCenterFM, ContainerFM, FavoritesTokensFM, HeaderFM, IconContainerFM, LeTokenFM, LeTokenImageFM, LeTokenTitleFM, PopularContainerFM, PopularTokenFM, SearchAndFavoritesFM, SearchFM, SearchIconFM, SearchInputFM, SelectTokenImageFM, SpacerFM, SpacerWithTokenFM, TokenListContainerFM, TokenShortFM, UnderlineFM } from '../../atoms';
import axios from 'axios';
import { utils } from 'casper-js-client-helper';
import ReactLoading from 'react-loading';
import { numberWithCommas } from '../../../commons';
import { Modal } from 'react-bootstrap';

const LogoTokenCustom = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;

  span {
    font-size: 10px;
    color: #fff;
  }

`
const TokenListContainerStyled = styled.div<any>`
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow-y: auto;
    height: 332px;
    gap: 10px;
    padding: 1rem;
`
export interface FloatMenuProps {
  tokens: Record<string, Token>,
  onSelectToken: (x: Token) => Promise<void>,
  onClick: () => void,
  excludedSymbols?: string[],
  show: boolean,
}

const FloatMenu = ({
  tokens,
  onSelectToken,
  onClick,
  excludedSymbols = [],
  show,
}: FloatMenuProps) => {
  const _filteredTokens = Object.entries(tokens).map((v, k) => v[1])
  //console.log('list token: ', _filteredTokens)
  // if (excludedSymbols.length > 0) {
  //   excludedSymbols.map((symbol) => {
  //     _filteredTokens = _filteredTokens.filter((token) => {
  //       // CSPR <=> WCSPR cases
  //       if (symbol === 'CSPR' && token.symbol == 'WCSPR') {
  //         return
  //       }

  //       if (symbol === 'WCSPR' && token.symbol == 'CSPR') {
  //         return
  //       }

  //       // main case
  //       if (symbol !== token.symbol) {
  //         return token
  //       }
  //     })

  //   })
  // }

  const [filteredTokens, setFilteredTokens] = React.useState(_filteredTokens)
  const [filter, setFilter] = React.useState('')
  const [userType, setUserType] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tokenFind, setTokenFind] = React.useState<Token[]>()

  const handleChange = async (e) => {
    setUserType(e.target.value)
    console.log('user type', e.target.value)
    if (e.target.value !== '') {
      //console.log('come1')
      setLoading(true)
      if (e.target.value.length < 4) {
        //search by name
        const filterByName = _filteredTokens.filter(
          (token) => 
          token.name.toLowerCase().search(e.target.value.toLowerCase()) != -1 || 
          token.symbol.toLowerCase().search(e.target.value.toLowerCase()) != -1
        )
        setLoading(false)
        filterByName ? setTokenFind(filterByName) : setTokenFind([])
      } else {
        //search by contract hash, package hash
        const inputUser = e.target.value.trim().includes('hash') ? e.target.value.trim() : 'hash-' + e.target.value.trim()

          try {
            const cep18 = new CEP18Client(NODE_ADDRESS, SUPPORTED_NETWORKS.networkKey)
            const stateRootHash = await utils.getStateRootHash(NODE_ADDRESS)
            const respond = await axios.get(`${MIDDLEWARE_API}?state_root_hash=${stateRootHash}&key=${inputUser}`)
            let _contractHash
            let _packageHash
    
            if (respond.data.result.stored_value.ContractPackage) {
              _packageHash = inputUser
              _contractHash = respond.data.result.stored_value.ContractPackage.versions[0].contract_hash.replace('contract', 'hash')
            } else {
              _contractHash = inputUser
              _packageHash = respond.data.result.stored_value.Contract.contract_package_hash.replace('contract-package-wasm', 'hash-')
            }
            console.log('contractHash: ', _contractHash)
            console.log('packageHash: ', _packageHash)
    
            await cep18.setContractHash(_contractHash, _packageHash)
          
            const tokenName = await cep18.name()
            const tokenSymbol = await cep18.symbol()
            const tokenDecimals = await cep18.decimals()
    
            console.log('token name: ', tokenName)
            console.log('token symbol: ', tokenSymbol)
            console.log('token decimals: ', tokenDecimals.toNumber())
        
            const token: Token[] = [{
              name : tokenName,
              chainId: 1,
              symbol : tokenSymbol,
              symbolPair: tokenSymbol,
              decimals: tokenDecimals.toNumber(),
              amount: "0",
              allowance: "0",
              logoURI: "",
              contractHash: _contractHash,
              packageHash: _packageHash
            }]
            
            //setFilteredTokens(token)
            setTokenFind(token)
            setLoading(false)
            return token
          } catch {
            setTokenFind([])
            setLoading(false)
          }
        
      }
    } else {
      //console.log('come2')
      setTokenFind(_filteredTokens)
    }
    
  }

  // async function useFilter(e) {
  //   setUserType(true)
  //   const inputUser = e.target.value.trim().includes('hash') ? e.target.value.trim() : 'hash-' + e.target.value.trim()
  //   console.log('inputUser: ', inputUser)

  //   if (inputUser.length === 0) {
  //     setUserType(false)
  //     return setFilteredTokens(_filteredTokens)
  //   }

  //   const filter = new RegExp(inputUser)
  //   const filtered = _filteredTokens.filter((token) => {
  //     // symbol
  //     if (filter.test(token.symbol)) {
  //       setTokenFind([token])
  //       return token
  //     }

  //     // contract hash
  //     if (inputUser === token.contractHash) {
  //       setTokenFind([token])
  //       return token
  //     }

  //     // package hash
  //     if (inputUser === token.packageHash) {
  //       setTokenFind([token])
  //       return token
  //     }
  //   })
  //   //filter added
  //   console.log('======  1')
  //   setFilteredTokens(filtered)

  //   if (filtered) {
  //     setLoading(true)
  //     try {
  //       const cep18 = new CEP18Client(NODE_ADDRESS, SUPPORTED_NETWORKS.networkKey)
  //       const stateRootHash = await utils.getStateRootHash(NODE_ADDRESS)
  //       const respond = await axios.get(`${MIDDLEWARE_API}?state_root_hash=${stateRootHash}&key=${inputUser}`)
  //       let _contractHash
  //       let _packageHash

  //       if (respond.data.result.stored_value.ContractPackage) {
  //         _packageHash = inputUser
  //         _contractHash = respond.data.result.stored_value.ContractPackage.versions[0].contract_hash.replace('contract', 'hash')
  //       } else {
  //         _contractHash = inputUser
  //         _packageHash = respond.data.result.stored_value.Contract.contract_package_hash.replace('contract-package-wasm', 'hash-')
  //       }
  //       console.log('contractHash: ', _contractHash)
  //       console.log('packageHash: ', _packageHash)

  //       await cep18.setContractHash(_contractHash, _packageHash)
      
  //       const tokenName = await cep18.name()
  //       const tokenSymbol = await cep18.symbol()
  //       const tokenDecimals = await cep18.decimals()

  //       console.log('token name: ', tokenName)
  //       console.log('token symbol: ', tokenSymbol)
  //       console.log('token decimals: ', tokenDecimals.toNumber())
    
  //       const token: Token[] = [{
  //         name : tokenName,
  //         chainId: 1,
  //         symbol : tokenSymbol,
  //         symbolPair: tokenSymbol,
  //         decimals: tokenDecimals.toNumber(),
  //         amount: "0",
  //         allowance: "0",
  //         logoURI: "",
  //         contractHash: _contractHash,
  //         packageHash: _packageHash
  //       }]
        
  //       //setFilteredTokens(token)
  //       setTokenFind(token)
  //       setLoading(false)
  //       return token
  //     } catch {
  //       setLoading(false)
  //     }
  //   }
  // }

  return (
    <Modal show={show} onHide={onClick} centered>
      <ContainerCenterFM>
        <HeaderFM>
          <div style={{ color: '#fff', fontFamily: 'Montserrat'}}>Select Token</div>
          <IconContainerFM onClick={onClick}><AiFillCloseCircle color='#fff' size={20}/></IconContainerFM>
        </HeaderFM>
        <SearchAndFavoritesFM>
          <SearchFM>
            <SearchIconFM>
              <AiOutlineSearch color='#fff'/>
            </SearchIconFM>
            <SearchInputFM placeholder="Search name or token address" onChange={(e) => { handleChange(e) }} />
          </SearchFM>
          {/* <PopularContainerFM>
            <PopularTokenFM>Popular Tokens</PopularTokenFM>
          </PopularContainerFM>
          <FavoritesTokensFM>
            {filteredTokens.slice(0,4).map((t) => {
              return (
                <LeTokenFM key={t.symbol} onClick={() => { onSelectToken(t) }}>
                  {t.logoURI ? (
                    <LeTokenImageFM src={t.logoURI} />
                  ) : (
                    <LogoTokenCustom>
                      <span>{t.symbol}</span>
                    </LogoTokenCustom>
                  )}
                  <LeTokenTitleFM>{t.symbol}</LeTokenTitleFM>
                </LeTokenFM>
              )
            })}
          </FavoritesTokensFM> */}
        </SearchAndFavoritesFM>
        {/* <UnderlineFM /> */}
        <TokenListContainerStyled>
          {/* TODO: remove inline css*/}
          <SpacerFM>
            <div></div>
            <div style={{ color: '#fff', fontFamily: 'Montserrat'}}>Balance</div>
          </SpacerFM>
          {userType ? (
            <>
              {loading ? (
                <div className='d-flex justify-content-center'>
                  <ReactLoading type='spin' color='#4592AE' width={25} height={25}/>
                </div>
              ) : (
                <div>
                  {tokenFind ? (
                    tokenFind.map((t) => {
                      return (
                        <SpacerWithTokenFM key={t.name} onClick={() => { onSelectToken(t) }}>
                          <TokenShortFM >
                            {t.logoURI ? (
                              <SelectTokenImageFM src={t.logoURI} />
                            ) : (
                              <LogoTokenCustom style={{width: '35px', height: '35px'}}>
                                <span>{t.symbol}</span>
                              </LogoTokenCustom>
                            )}
                            <div>
                              <div style={{ fontSize: "1rem", paddingLeft: "5px", fontWeight: '600', fontFamily: 'Montserrat', color: '#fff' }}>
                                {t.symbol}
                              </div>
                              <div style={{ fontSize: "0.875rem", paddingLeft: "5px", fontFamily: 'Montserrat', color: '#fff'}}>
                                {t.name}
                              </div>
                            </div>
                          </TokenShortFM>
                          <div style={{ fontFamily: 'Montserrat', color: '#fff' }}>{t.amount}</div>
                        </SpacerWithTokenFM>
                      )
                    })
                  ) : (
                    <p style={{textAlign: 'center', color: '#fff', fontFamily: 'Montserrat'}}>Not found</p>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {filteredTokens.map((t) => {
                return (
                  <SpacerWithTokenFM key={t.name} onClick={() => { onSelectToken(t) }}>
                    <TokenShortFM >
                      {t.logoURI ? (
                        <SelectTokenImageFM src={t.logoURI} />
                      ) : (
                        <LogoTokenCustom style={{width: '35px', height: '35px'}}>
                          <span>{t.symbol}</span>
                        </LogoTokenCustom>
                      )}
                      <div>
                        <div style={{ fontSize: "1rem", paddingLeft: "5px", fontWeight: '600', fontFamily: 'Montserrat', color: '#fff' }}>
                          {t.symbol}
                        </div>
                        <div style={{ fontSize: "0.875rem", paddingLeft: "5px", fontFamily: 'Montserrat', color: '#fff' }}>
                          {t.name}
                        </div>
                      </div>
                    </TokenShortFM>
                    <div style={{ fontFamily: 'Montserrat', color: '#fff' }}>{numberWithCommas(Number(t.amount).toFixed(0))}</div>
                  </SpacerWithTokenFM>
                )
              })}
            </>
          )}
        </TokenListContainerStyled>
      </ContainerCenterFM>
    </Modal>
  )
}

export default FloatMenu