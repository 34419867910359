import styled from 'styled-components';
import { device } from '../../../contexts/ThemeContext/themes';

export const LiquidityButtonStyle = styled.button<any>`
  color: ${(props) =>
    props.enabled
      ? '#fff'
      : '#415269'};
  background-color: ${(props) =>
    props.enabled
      ? '#9A0D2F'
      : '#1B232E'};
  padding: .7rem 1.2rem;
  border: none;
  font-family: 'Montserrat';
  font-size: 14px;
  display: grid;
  place-items: center;
  &:hover {
    cursor: pointer;
  }
  &:active {
    color: ${(props) =>
      props.enabled
        ? props.theme.thirdBackgroundColor
        : props.theme.NewGrayColor};
    background-color: ${(props) =>
      props.enabled
        ? props.theme.secondBackgroundColor
        : props.theme.NewGreyColor};
  }

  @media ${device.desktop} {
    width: 8vw;
    height: 4.6vh;
  }
`;
