import React from "react";
import styled from "styled-components";

const NewSwapContainer = styled.section`
  background-color: white;
  box-sizing: border-box;
  justify-self: center;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  background-color: #1B232E;
  color: rgb(119, 128, 160);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
export const NewSwapContainerNSM = ({ children }) => {
  return <NewSwapContainer>{children}</NewSwapContainer>;
};
