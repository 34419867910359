import React from "react";
import styled from "styled-components";
const NewTokenDetailItems2Styled = styled.img`
  cursor: pointer;
`;

interface NewTokenDetailItems2NSMProps {
  src: string;
  handleClick?: () => void;
  width?: number;
  height?: number;
}

export const NewTokenDetailItems2NSM = ({
  src,
  handleClick,
  width = 20,
  height = 20,
}: NewTokenDetailItems2NSMProps) => {
  return (
    <NewTokenDetailItems2Styled
      src={src}
      width={width}
      height={height}
      onClick={handleClick}
    />
  );
};
