//import "module-alias/register";
//import dotenv from "dotenv";
//dotenv.config();

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "@fontsource/epilogue";
import { Home, Swap, Pools, Tokens } from "./components/pages";
import TokenFactory from "./components/pages/TokenFactory";
import { NewLiquidity } from "./components/pages/Liquidity";
import { BigContext } from "./contexts";

import { MainSpaceCC, NavBarCD } from "./components/atoms";
import { ReactComponent as WordMarkIcon } from "./assets/newIcons/casperswap-wordmark.svg";
import { useContext } from "react";
import { ConfigProviderContext } from "./contexts/ConfigContext";
import NewLayout from "./layout/NewLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'

export default function App() {
  return (
    <BigContext>
      <BrowserRouter>
        <NewLayout>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="swap" element={<Swap />} />
            <Route path="liquidity" element={<NewLiquidity />} />
            <Route path="pools" element={<Pools />} />
            <Route path="factory" element={<TokenFactory />} />
          </Routes>
        </NewLayout>
      </BrowserRouter>
      <ToastContainer position="top-center" autoClose={3000} />
    </BigContext>
  );
}
